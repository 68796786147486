import {
  DASHBOARD_DAILYUPDATE_F,
  DASHBOARD_DAILYUPDATE_S,
  DASHBOARD_DAYGRAPH_S,
  DASHBOARD_F,
  DASHBOARD_S,
  DASHBOARD_SIX_MONTH_F,
  DASHBOARD_SIX_MONTH_S,
  DASHBOARD_YEARGRAPH_F,
  DASHBOARD_YEARGRAPH_S,
} from "../constants/types";

const initialState = {
  dashbord: {},
  years: {},
  week: [],
  daiilyupdate: [],
  sixmonthdata: []
};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case DASHBOARD_S:
      return {
        ...state,
        dashbord: action.payload.data,
      };
    case DASHBOARD_F:
      return {
        ...state,
        dashbord: action.payload.message,
      };
    case DASHBOARD_YEARGRAPH_S:
      return {
        ...state,
        years: action.payload.data,
      };
    case DASHBOARD_YEARGRAPH_F:
      return {
        ...state,
        years: action.payload.message,
      };
    case DASHBOARD_DAYGRAPH_S:
      return {
        ...state,
        week: action.payload.data,
      };
    // case DASHBOARD_DAYGRAPH_F:
    //     return {
    //         ...state, week: action.payload.message
    //     }
    case DASHBOARD_DAILYUPDATE_S:
      return {
        ...state,
        daiilyupdate: action.payload.data,
      };
    case DASHBOARD_DAILYUPDATE_F:
      return {
        ...state,
        daiilyupdate: action.payload.message,
      };
    case DASHBOARD_SIX_MONTH_S:
      return {
        ...state,
        sixmonthdata: action.payload.data,
      };
    case DASHBOARD_SIX_MONTH_F:
      return {
        ...state,
        sixmonthdata: action.payload.message,
      };

    default:
      return state;
  }
};
export default DashboardReducer;
