import React from 'react'

const Play = () => {
  return (
    <>
      <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M30 60C46.5685 60 60 46.5685 60 30C60 13.4315 46.5685 0 30 0C13.4315 0 0 13.4315 0 30C0 46.5685 13.4315 60 30 60ZM21.7828 14.3244L48.4265 29.1265C49.1123 29.5075 49.1123 30.4938 48.4265 30.8748L21.7828 45.6769C20.9829 46.1212 20 45.5429 20 44.6279V15.3734C20 14.4584 20.9829 13.8801 21.7828 14.3244Z" fill="white"/>
      </svg>



    </>
  )
}

export default Play
