/* eslint-disable react-hooks/exhaustive-deps */
import InfiniteScroll from "react-infinite-scroll-component";
import React, { useEffect, useState } from "react";
import Send from "../../components/svg/send";
import ChatUserList from "./ChatSideBar";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import {
  connectUser,
  getChatHistory,
  getChatUserList,
  resetChat,
  resetChatHistory,
  sendMessage,
  socket,
} from "../../utils/socket";
import { scrolltoBottomFun, toAbsoluteUrl } from "../../utils";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_ACTIVE_USER,
  CHAT_LIMIT_CHANGE,
  USER_LIST_LIMIT_CHANGE,
} from "../../constants/types";

import "./chat.scss";
import { useLocation } from "react-router-dom";
import { createChatRoomAction } from "../../actions/chat";

// To start scroll from bottom for chat list
scrolltoBottomFun("chatListId");

// Fetch user data from localstorage

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  let userId = useSelector((state) => state.auth?.user?.data?._id);

  const chatReducer = useSelector((state) => state.chat);
  const mediaURL = useSelector((state) => state.metaReducer.mediaURL);
  const activeUser = useSelector((state) => state.chat?.activeUser);

  const [messageText, setmessageText] = useState("");
  const [searchText, setsearchText] = useState("");
  const [chatViewMobile, togglechatViewMobile] = useState(true);
  const [chatRoom, setChatRoom] = useState();
  useEffect(() => {
    if (!chatReducer.isConnected) connectUser(userId);
    else getChatUserList({ page: 1, limit: 10, userId: userId });

    return () => {
      resetChat();
    };
  }, [chatReducer.isConnected]);

  useEffect(() => {
    return () => {
      socket.removeAllListeners();
    };
  }, []);
  useEffect(() => {
    if (userId && location?.state?.sendToId) {
      let payload = {
        userTo: location?.state?.sendToId,
        userFrom: userId,
        userFromType: 2,
      };
      dispatch(createChatRoomAction(payload)).then((res) => {
        if (res.new === false) {
          setChatRoom(res.data[0]);
        } else {
          setChatRoom(res.data);
        }
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
          search: location?.state?.name,
        });
        setmessageText("");
        getChatHistory({
          userId: userId,
          roomId: res.new === false ? res.data[0]._id : res.data._id,
          page: 1,
          limit: 20,
        });
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      });
    }
  }, [location?.state?.sendToId]);
  useEffect(() => {
    socket.on("server-receive-message", (res) => {
      if (res?.roomId === activeUser?.roomId) {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
        getChatHistory({
          userId: userId,
          roomId: activeUser?.roomId,
          page: 1,
          limit: 10,
        });
        scrolltoBottomFun("chatListId");
      } else {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      }
    });
    return () => {
      socket.removeListener("server-receive-message");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    //heare we remove activeuser because chat conflict with another
  }, [activeUser]);

  useEffect(() => {
    if (chatReducer.activeUser.roomId) {
      resetChatHistory();
      setmessageText("");
      getChatHistory({
        userId: userId,
        roomId: activeUser?.roomId,
        page: chatReducer.page,
        limit: chatReducer.limit,
      });
      setTimeout(() => {
        getChatUserList({
          page: 1,
          limit: chatReducer.userListlimit,
          userId: userId,
        });
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chatReducer.activeUser]);

  useEffect(() => {
    //condition comment bec last text not event triger
    // if (searchText)
    getChatUserList({
      page: 1,
      limit: 11,
      userId: userId,
      search: searchText,
    });
  }, [searchText]);

  const onSendMessage = (e) => {
    e.preventDefault();

    if (messageText?.trim().length) {
      sendMessage(
        { userId: userId, roomId: activeUser?.roomId, message: messageText },
        () => {
          setmessageText("");
          setTimeout(() => {
            getChatHistory({
              userId: userId,
              roomId: activeUser?.roomId,
              page: 1,
              limit: 10,
            });
            scrolltoBottomFun("chatListId");
          }, 200);
        }
      );
    }
  };

  const onUserClick = (data) => {
    setChatRoom();
    togglechatViewMobile(false);
    dispatch({ type: CHAT_ACTIVE_USER, payload: data });
  };

  const fetchUserList = (arg) => {
    getChatUserList({
      userId: userId,
      page: 1,
      limit: +chatReducer.userListlimit + 10,
    });
    dispatch({
      type: USER_LIST_LIMIT_CHANGE,
      payload: +chatReducer.userListlimit + 10,
    });
  };

  const fetchChatList = (arg) => {
    getChatHistory({
      userId: userId,
      roomId: activeUser?.roomId,
      page: chatReducer.page,
      limit: +chatReducer.limit + 15,
    });
    dispatch({
      type: CHAT_LIMIT_CHANGE,
      payload: +chatReducer.limit + 15,
    });
  };

  return (
    <>
      <div className="page-heading">
        <h2>Message</h2>
      </div>
      <div className="chat-wrap">
        {/* Chat User Search */}
        <div
          className={`chat-list-wrap ${chatViewMobile ? "active" : "inactive"}`}
        >
          <div className="chat-top-box">
            <h4>Chats</h4>
          </div>
          <div className="search-box">
            <input
              type="text"
              placeholder="Search"
              className="search-input"
              value={searchText}
              onChange={(e) => {
                setsearchText(e.target.value);
              }}
            />
          </div>

          {/* Chat User Search */}

          {/* Chat Side bar */}
          <div className="scroll-style scroll-wrapper" id="userListId">
            <ul className="chat-listing">
              <InfiniteScroll
                dataLength={chatReducer?.chatUserList?.length}
                next={fetchUserList}
                scrollableTarget={"userListId"}
                hasMore={true}
              >
                <div>
                  {chatReducer?.chatUserList?.length ? (
                    chatReducer?.chatUserList?.map((val, i) => {
                      if (val?.roomId === chatRoom?._id) {
                        onUserClick(val);
                      }
                      return (
                        <ChatUserList
                          key={val?._id}
                          mediaURL={mediaURL}
                          chatReducer={chatReducer}
                          data={val}
                          onUserClick={onUserClick}
                        />
                      );
                    })
                  ) : (
                    <li>
                      <h4>No Chat Available</h4>
                    </li>
                  )}
                </div>
              </InfiniteScroll>
            </ul>
          </div>
        </div>
        {/* Chat Side bar */}

        {/* Chat Header */}
        {activeUser?.roomId ? (
          <div
            className={`chat-body-wrap ${
              chatViewMobile ? "inactive" : "active"
            }`}
          >
            <div className="chat-body-header">
              <ChatHeader
                onClickBack={() => togglechatViewMobile(true)}
                mediaURL={mediaURL}
                activeUser={activeUser}
              />
            </div>
            {/* Chat Header */}

            {/* Chat Body */}
            <div className="chat-body-main">
              <div
                className="scroll-style reverse-scroll-wrapper"
                id="chatListId"
              >
                <InfiniteScroll
                  dataLength={chatReducer?.chatList?.length}
                  next={fetchChatList}
                  style={{ display: "flex", flexDirection: "column-reverse" }}
                  inverse={true}
                  scrollableTarget={"chatListId"}
                  hasMore={true}
                >
                  <div className="chat-body ">
                    {chatReducer?.chatList.map((val, i) => {
                      return (
                        <ChatBody
                          {...val}
                          key={val?._id}
                          mediaURL={mediaURL}
                          userId={userId}
                        />
                      );
                    })}
                  </div>
                </InfiniteScroll>
              </div>
            </div>

            <div className="chat-footer">
              <form>
                <input
                  type="text"
                  className="input-box"
                  placeholder="Type your Message "
                  value={messageText}
                  onChange={(e) => {
                    setmessageText(e.target.value);
                  }}
                />
                <div className="chat-action">
                  <button
                    type="submit"
                    onClick={onSendMessage}
                    className="send-btn"
                  >
                    <Send />
                  </button>
                </div>
              </form>
            </div>
          </div>
        ) : (
          // when no user selected UI
          <div className="chat-placeholder">
            <div>
              <RenderImage filePath={toAbsoluteUrl("/images/logo.svg")} />
            </div>
          </div>
        )}
        {/* Chat Body */}
      </div>
    </>
  );
};

export const RenderImage = ({ filePath, className }) => {
  return (
    <figure>
      <img
        className={className}
        src={filePath}
        alt="user"
        onError={(e) => (e.target.src = toAbsoluteUrl("/images/userLogo.png"))}
      />
    </figure>
  );
};

export default Chat;
