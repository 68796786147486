import {
  API_COMMUNITY_TRANSCTION_EXCELL_DOWNLOAD,
  API_COMMUNITY_TRANSCTION_LIST,
  API_STRATEGIC_TRANSCATION_LIST,
  API_STRATEGIC_TRANSCATION_LIST_EXCEL,
  API_TRANSCATION_DETAIL,
  API_TRANSCATION_DETAIL_PDF,
  API_TRANSCATION_LIST,
  API_TRANSCATION_LIST_EXCEL,
} from "../constants/api";
import {
  API,
  COMMUNITY_TRANSCTION_LIST_F,
  COMMUNITY_TRANSCTION_LIST_S,
  STRATEGIC_TRANSACTION_LIST_F,
  STRATEGIC_TRANSACTION_LIST_S,
  TRANSACTION_DETAIL_F,
  TRANSACTION_DETAIL_S,
  TRANSACTION_LIST_F,
  TRANSACTION_LIST_S,
} from "../constants/types";

export const transactionList = (data) => ({
  type: API,
  payload: {
    url: API_TRANSCATION_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: TRANSACTION_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: TRANSACTION_LIST_F,
      payload: data,
    }),
  },
});

export const transactionListExportExcel = (id, data) => ({
  type: API,
  payload: {
    url: API_TRANSCATION_LIST_EXCEL + id,
    method: "POST",
    data: data,
  },
});
export const transactiondetailExcel = (data) => ({
  type: API,
  payload: {
    url: API_TRANSCATION_DETAIL_PDF,
    method: "POST",
    data: data,
  },
});
export const communitytransactionListAction = (data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_TRANSCTION_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_TRANSCTION_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_TRANSCTION_LIST_F,
      payload: data,
    }),
  },
});

export const strategictransactionList = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_TRANSCATION_LIST,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_TRANSACTION_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_TRANSACTION_LIST_F,
      payload: data,
    }),
  },
});

export const transactionDetail = (id) => ({
  type: API,
  payload: {
    url: API_TRANSCATION_DETAIL + id,
    method: "GET",
    // data: data,
    success: (data) => ({
      type: TRANSACTION_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: TRANSACTION_DETAIL_F,
      payload: data,
    }),
  },
});
export const strategictransactionListExportExcel = (id, data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_TRANSCATION_LIST_EXCEL + id,
    method: "POST",
    data: data,
    // success: (data) => ({
    //     type: TRANSACTION_LIST_S,
    //     payload: data
    // }),
    // error: (data) => ({
    //     type: TRANSACTION_LIST_F,
    //     payload: data
    // })
  },
});

export const communityTransactionExcel = (id, data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_TRANSCTION_EXCELL_DOWNLOAD + id,
    method: "POST",
    data: data,
  },
});
