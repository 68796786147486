/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";

import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import ImgUpload from "../../../../components/common/img-upload-home";
import {
  RenderCityField,
  renderField,
  renderNumberField,
  RenderSelectField,
  rendertextarea,
} from "../../../../components/forms";
import Tookit from "../../../../components/svg/tookit";
import { strategicEditValidation as validate } from "../../../../components/validations/signIn";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../../../components/layout/Breadcrumb";
import "../strategicprofile.scss";
import {
  strategicUserUpdate,
  strategicUserView,
} from "../../../../actions/strategic";
import {
  addTOCity,
  addTOCountry,
  addTOState,
  doUploadImage,
} from "../../../../actions/registration";
import { toast } from "react-toastify";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const list = [
  {
    name: "Giving Partner Profile",
    link: "/strategic_profile",
    isactive: false,
  },
  {
    name: "Giving Profile Edit",
    link: "/strategic_profile/edit",
    isactive: true,
  },
];

const SProfileEdit = ({ setSteps, step }) => {
  //ALL The Hooks

  const Change = useSelector((state) => state.auth?.user?.data);
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const metaData = useSelector((state) => state.metaReducer);
  const { stateList } = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  //signgale image
  const [strategicLogo, setStrategicLogo] = useState();
  const [oldStrategicLogo, setOldStrategicLogo] = useState([]);
  const [logo, setLogo] = useState();
  const [logoerror, setLogoerror] = useState("");

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedState, setselectedState] = useState();

  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",
    phone_code: "1",
  });

  const formRef = useRef();
  useEffect(() => {
    if (formRef.current) {
      formRef.current.mutators.setValue("state", {
        label: strategicdetail?.address?.state
          ? strategicdetail?.address?.state
          : "State",
        value: strategicdetail?.address?.state,
      });
      formRef.current.mutators.setValue("city", {
        label: strategicdetail?.address?.city
          ? strategicdetail?.address?.city
          : "City",
        value: strategicdetail?.address?.city,
      });
    }
  }, [strategicdetail]);

  useEffect(() => {
    fetchCountry();
  }, []);

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
  }, [selectedCountry]);

  useEffect(() => {
    fetchCity({ stateId: selectedState });
  }, [selectedState]);
  useEffect(() => {
    if (strategicdetail?.address?.state) {
      let initialState = [];
      if (stateList?.length) {
        initialState = stateList.filter(
          (val) => val.label === strategicdetail?.address?.state
        );
        if (initialState?.length) {
          setselectedState(initialState[0]);
          fetchCity({ stateId: initialState[0]?.value });
        }
      }
    }
  }, [strategicdetail]);

  useEffect(() => {
    setOldStrategicLogo(strategicdetail?.logo);
    setLogo(strategicdetail?.logo);
  }, [strategicdetail]);

  useEffect(() => {
    if (logo) {
      setLogoerror("");
    }
  }, [logo]);

  useEffect(() => {
    dispatch(strategicUserView(Change?._id));
  }, []);

  //ALL The Function
  const prev = () => {
    navigate("/strategic_profile", { replace: true });
  };

  const fetchCountry = (id) => {
    dispatch(addTOCountry()).then((res) => {
      const data = res.data;
      let countryArr = [];
      data.map((val, ind) => {
        return countryArr.push({
          value: val.name,
          label: val.name,
          id: val.id,
        });
      });
      setCountry(countryArr);
    });
  };

  const fetchState = (data) => {
    dispatch(addTOState(data)).then((res) => {
      const data = res.data;
      let stateArr = [];
      data.map((val, ind) => {
        return stateArr.push({ value: val.name, label: val.name, id: val.id });
      });
      setState(stateArr);
    });
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data)).then((res) => {
      const data = res.data;
      let cityArr = [];
      data.map((val, ind) => {
        return cityArr.push({ value: val.name, label: val.name });
      });
      setCity(cityArr);
    });
  };

  const editGivingPartnerDetail = (data) => {
    dispatch(strategicUserUpdate(data, Change?._id))
      .then((res) => {
        dispatch(strategicUserView(Change?._id));
        toast.success(res.message);
        navigate("/strategic_profile");
      })
      .catch((res) => toast.error(res.message));
  };

  //From
  const onSubmit = (value) => {
    if (!strategicLogo && !oldStrategicLogo) {
      setLogoerror("Please Upload Logo");
      return;
    }

    let data = {
      strategicPartnerId: Change?._id,
      tagline: value.tagline,
      website: value.website,
      userName: value.StrategicUserName,
      name: value.StrategicName,
      ownerName: value.StrategicPersonName,
      emailAddress: value.StrategicEmailAddress,
      description: value.StrategicDescription,
      brifDescription: value.StrategicBriefDescription,
      phoneNumber: `${value.PhoneNumber?.replace(/[^\d]/g, "")}`,

      logo: logo,

      address: {
        streetName: value.strategicAddress,
        streetName2: value.strategicAddress2,
        city: value.city.label,
        state: value.state.label,
        country: value.country.label,
        zipcode: value.pincode,
        latitude: "",
        longitude: "",
      },
    };

    if (strategicLogo) {
      const payload = { image: strategicLogo, moduleName: "strategicLogo" };
      dispatch(doUploadImage(payload)).then((res) => {
        data = {
          ...data,
          logo: res?.data?.name ? res.data.name : oldStrategicLogo,
        };
        editGivingPartnerDetail(data);
      });
    } else {
      editGivingPartnerDetail(data);
    }
  };

  return (
    <>
      <div className="strategic-profile-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Giving Partner Profile Edit</h2>
        </div>
        <div className="section-inner rating-box">
          <div className="section-steps">
            <div className="d-flex flex-wrap w-100">
              <div className="col-12 col-boxs">
                <Form
                  mutators={{
                    // expect (field, value) args from the mutator
                    setValue: ([field, value], state, { changeValue }) => {
                      changeValue(state, field, () => value);
                    },
                  }}
                  onSubmit={onSubmit}
                  validate={validate}
                  initialValues={{
                    StrategicUserName: strategicdetail?.userName || "",
                    StrategicName: strategicdetail?.name || "",
                    StrategicPersonName: strategicdetail?.ownerName || "",
                    StrategicEmailAddress: strategicdetail?.emailAddress || "",
                    StrategicBriefDescription:
                      strategicdetail?.brifDescription || "",
                    PhoneNumber: strategicdetail?.phoneNumber || "",
                    strategicAddress:
                      strategicdetail?.address?.streetName || "",
                    StrategicDescription: strategicdetail?.description || "",
                    pincode: strategicdetail?.address?.zipcode || "",
                    country: selectedCountry,
                    tagline: strategicdetail?.tagline || "",
                    website: strategicdetail?.website || "",
                    strategicAddress2: strategicdetail?.address?.streetName2,
                  }}
                >
                  {({ handleSubmit, form, values }) => {
                    formRef.current = form;
                    return (
                      <form onSubmit={handleSubmit} className="step-form-box">
                        <div className="d-flex flex-wrap w-100">
                          <div className="col-md-6  col-12 col-box">
                            <ul className="input-list">
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Business Name{" "}
                                </label>
                                <Field
                                  name="StrategicName"
                                  type="text"
                                  placeholder="Giving Partner Business Name"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Description{" "}
                                  <span className="tooltip-information-wrap" data-tooltip-id="description">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="description"
                                    place="top"
                                    variant="success"
                                    content="This is a more robust description of the Giving Partner and there is no character limit, however three to four paragraphs of reasonable length should be sufficient.  You don’t necessarily need to describe in great detail what your organization does since the members presumably know; this is a good place to state the way your organization cares about its members and the way they can count on your organization to support them."
                                  />
                                </label>
                                <Field
                                  name="StrategicDescription"
                                  type="text"
                                  placeholder="Giving Partner Description"
                                  component={rendertextarea}
                                  className="form-grp"
                                  inputclass="input-box text-area-box "
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Brief
                                  Description{" "}<span className="tooltip-information-wrap" data-tooltip-id="briefDescription">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="briefDescription"
                                    place="top"
                                    variant="success"
                                    content="Similar to the above, this a less comprehensive description of the Giving Partner and should be less than 150 characters in length."
                                  />
                                </label>
                                <Field
                                  name="StrategicBriefDescription"
                                  type="text"
                                  placeholder="Giving Partner Brief Description"
                                  component={rendertextarea}
                                  className="form-grp"
                                  inputclass="input-box text-area-box "
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  Giving Partner Tagline{" "}
                                  <span className="tooltip-information-wrap" data-tooltip-id="tagLine">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="tagLine"
                                    place="top"
                                    variant="success"
                                    content="This information is optional.  The tagline is generally a short, easily remembered phrase that your organization uses in advertisements or internally to describe its intention in being a Giving Partner."
                                  />
                                </label>
                                <Field
                                  name="tagline"
                                  type="text"
                                  placeholder="Giving Partner Tagline"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  Giving Partner Website{" "}
                                  <span className="tooltip-information-wrap" data-tooltip-id="website">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="website"
                                    place="top"
                                    variant="success"
                                    content="This information is optional.  This will generally be the link to its organizational website or to one that illustrates how the Giving Partner cares about its members and the efforts made to support them."
                                  />
                                </label>
                                <Field
                                  name="website"
                                  type="text"
                                  placeholder="Giving Partner Website"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span>
                                  Giving Partner Logo Image <span className="tooltip-information-wrap" data-tooltip-id="logo">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="logo"
                                    place="top"
                                    variant="success"
                                    content="You must attach any logo image to your Giving Partner’s profile to presented anywhere your organization is displayed of the Rosi platform.  Please be mindful as to the format of your logo and that a square image will generally present better than a rectangle or circular/oval image."
                                  />{" "}
                                </label>
                                <ImgUpload
                                  multiple={false}
                                  setImg={setLogo}
                                  setImages={setStrategicLogo}
                                  images={strategicLogo}
                                  oldImages={oldStrategicLogo}
                                  oldLogo={
                                    strategicdetail?.logo &&
                                    metaData?.mediaURL +
                                    "strategicLogo/" +
                                    strategicdetail?.logo
                                  }
                                  moduleName="strategicLogo"
                                  callUploadAPI={false}
                                />
                                <span className="text-danger">{logoerror}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-md-6  col-12 col-box">
                            <ul className="input-list">
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Username{" "}
                                </label>
                                <Field
                                  name="StrategicUserName"
                                  type="text"
                                  placeholder="Giving Partner Username"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Representative
                                  Name{" "}
                                </label>
                                <Field
                                  name="StrategicPersonName"
                                  type="text"
                                  placeholder="Giving Partner Representative Name"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Email Address{" "}
                                </label>
                                <Field
                                  name="StrategicEmailAddress"
                                  type="text"
                                  placeholder="Giving Partner Email Address"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                  readOnly={true}
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  <span>*</span> Giving Partner or
                                  Representative Phone Number <Tookit />
                                </label>

                                <Field
                                  name="PhoneNumber"
                                  placeholder="Giving Partner or Representative Phone Number"
                                  component={renderNumberField}
                                  formatStyle={"(###) ###-####"}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                              </li>
                              <li>
                                <label className="label-text">
                                  {" "}
                                  <span>*</span> Giving Partner Address{" "}
                                  <span className="tooltip-information-wrap" data-tooltip-id="address">
                                    <Tookit />
                                  </span>
                                  <ReactTooltip
                                    id="address"
                                    place="top"
                                    variant="success"
                                    content="This is where you receive US Postal Service mail."
                                  />
                                </label>
                                <Field
                                  name="strategicAddress"
                                  type="text"
                                  placeholder="Giving Partner Address Line 1"
                                  component={renderField}
                                  className="form-grp addressGap"
                                  inputclass="input-box"
                                />
                                <Field
                                  name="strategicAddress2"
                                  type="text"
                                  placeholder="Giving Partner Additional Address Line 2"
                                  component={renderField}
                                  className="form-grp"
                                  inputclass="input-box"
                                />
                                <div className="inner-form-list">
                                  <Field
                                    name="state"
                                    type="text"
                                    placeholder="State"
                                    mutator={form.mutators.setValue}
                                    isStateField={true}
                                    setselectedState={setselectedState}
                                    component={RenderSelectField}
                                    className="form-grp col-grp"
                                    options={state}
                                    isMulti={false}
                                  />

                                  <Field
                                    name="city"
                                    placeholder="City"
                                    component={RenderCityField}
                                    className="form-grp col-grp"
                                    options={city}
                                    isMulti={false}
                                  />
                                  <Field
                                    name="pincode"
                                    type="number"
                                    placeholder="Zip code"
                                    component={renderField}
                                    className="form-grp col-grp"
                                    inputclass="input-box"
                                  />
                                  <Field
                                    name="country"
                                    placeholder="Country"
                                    isCountryField={true}
                                    setselectedCountry={setselectedCountry}
                                    component={RenderSelectField}
                                    mutator={form.mutators.setValue}
                                    className="form-grp col-grp"
                                    options={country}
                                    isMulti={false}
                                    disabled={true}
                                  />
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="d-flex flex-wrap align-items-center justify-content-end w-100">
                            <div className="button-box">
                              <button
                                type="button"
                                className="link-btn cancel"
                                onClick={prev}
                              >
                                Cancel
                              </button>
                            </div>
                            <div className="button-box">
                              <button type="submit" className="link-btn">
                                Save
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SProfileEdit;
