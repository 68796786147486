import patterns from "../../utils/patterns";

export const login = (value) => {
  let error = {};

  if (!value.email) {
    error.email = "Please enter email address";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }
  if (!value.password) {
    error.password = "Password is required";
  } else if (value.password && !/^[^\s]+(\s+[^\s]+)*$/.test(value.password)) {
    error.password = "Password must not contain while spaces";
  }
  // else if (value.password && !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(value.password)) {
  //   error.password = " Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces"
  // }
  return error;
};

export const registrationstep1 = (value) => {
  console.log("value: ", value);
  let error = {};
  if (!value.UserName || !value.UserName.trim()) {
    error.UserName = "Please enter User Name";
  }

  if (!value.Password) {
    error.Password = "Password is required";
  }

  if (!value.ConfirmPassword) {
    error.ConfirmPassword = "Confirm Password is required";
  } else if (value.Password !== value.ConfirmPassword) {
    error.ConfirmPassword = "Confirm Password must match with Password";
  }
  return error;
};

export const registrationstep2 = (value) => {
  let error = {};
  if (!value.CharityName || !value.CharityName.trim()) {
    error.CharityName = "Please enter Charity Name";
  }
  if (!value.PhoneNumber) {
    error.PhoneNumber = "Please enter Phone Number";
  }
  // else if (!patterns.alpha_spaces.test(value.CharityName)) {
  //   error.CharityName = "Please enter only alphabetic";
  // }
  if (!value.CharityPersonName || !value.CharityPersonName.trim()) {
    error.CharityPersonName = "Please enter Name of Nonprofit Representative";
  }
  // else if (!patterns.alpha_spaces.test(value.CharityPersonName)) {
  //   error.CharityPersonName = "Please enter only alphabetic";
  // }
  if (!value.CharityEmailAddress || !value.CharityEmailAddress.trim()) {
    error.CharityEmailAddress = "Please enter Nonprofit Representative Email";
  } else if (
    value.CharityEmailAddress &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.CharityEmailAddress
    )
  ) {
    error.CharityEmailAddress =
      "Please enter valid Nonprofit Representative Email";
  }
  if (!value.CharityDescription || !value.CharityDescription.trim()) {
    error.CharityDescription = "Please enter Nonprofit Full Description";
  }
  // else if (value.CharityDescription.length > 250) {
  //   error.CharityDescription = "Charity Description character shoud be 250";
  // }
  if (!value.CharityBriefDescription || !value.CharityBriefDescription.trim()) {
    error.CharityBriefDescription = "Please enter Nonprofit Brief Description";
  } else if (value.CharityBriefDescription.length > 150) {
    error.CharityBriefDescription =
      "Nonprofit Brief Description character shoud be 150";
  }
  return error;
};

export const registrationstep3 = (value) => {
  let error = {};
  if (!value.CharityWebsite || !value.CharityWebsite.trim()) {
    error.CharityWebsite = "Please enter Nonprofit Website";
  } else if (
    value.CharityWebsite &&
    !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/.test(
      value.CharityWebsite
    )
  ) {
    error.CharityWebsite = "Enter valid Nonprofit Website";
  }

  if (!value.CharityFilter) {
    error.CharityFilter = "Please enter Scope of Nonprofit";
  }
  if (!value.CharityContribution) {
    error.CharityContribution = "Please enter  Nonprofit Total Revenue";
  }

  if (!value.causeList) {
    error.causeList = "Please enter Related Cause";
  }
  return error;
};

export const changePasswordValidation = (value) => {
  let error = {};
  if (!value.OldPassword || !value.OldPassword.trim()) {
    error.OldPassword = "Old Password is Required";
  }

  if (!value.Newpassword || !value.Newpassword.trim()) {
    error.Newpassword = "New Password is Required";
  } else if (
    value.Newpassword &&
    !/^[^\s]+(\s+[^\s]+)*$/.test(value.Newpassword)
  ) {
    error.Newpassword = " New Password must not contain while spaces";
  } else if (
    value.Newpassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.Newpassword
    )
  ) {
    error.Newpassword =
      "New Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  } else if (value.OldPassword === value.Newpassword) {
    error.Newpassword = "Old Password and New Password can not be same";
  }

  if (!value.Confirmpassword || !value.Confirmpassword.trim()) {
    error.Confirmpassword = "Confirm Password is Required";
  }
  // else if (value.Confirmpassword && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value.Confirmpassword)) {
  //   error.Confirmpassword = "Confirm Password must be 8-20 characters and contain at least one number/symbol/upper and lower case letter"
  // }
  else if (value.Newpassword !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm Password must match with New Password";
  }

  return error;
};

export const forgetPasswordValidation = (value) => {
  let error = {};
  if (!value.email) {
    error.email = "Please enter register email id";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please enter valid email address.";
  }
  return error;
};

export const resetPasswordValidation = (value) => {
  let error = {};
  if (!value.Newpassword || !value.Newpassword.trim()) {
    error.Newpassword = "New Password is required";
  } else if (
    value.Newpassword &&
    !/^[^\s]+(\s+[^\s]+)*$/.test(value.Newpassword)
  ) {
    error.Newpassword = " New Password must not contain while spaces";
  } else if (
    value.Newpassword &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.Newpassword
    )
  ) {
    error.Newpassword =
      "New Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }
  if (!value.Confirmpassword || !value.Newpassword.trim()) {
    error.Confirmpassword = "Confirm Password is required";
  }
  // else if (value.Confirmpassword && !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(value.Confirmpassword)) {
  //   error.Confirmpassword = 'New Password must be 8-20 characters and contain at least one number/symbol/upper and lower case letter'
  // }
  else if (value.Newpassword !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm Password must match with New password";
  }
  return error;
};

export const addCommunityValidation = (value) => {
  let error = {};
  if (!value.communityname || !value.communityname.trim()) {
    error.communityname = "Please Enter Community Name ";
  }
  if (!value.shortdescription || !value.shortdescription.trim()) {
    error.shortdescription = "Please Enter Community Description";
  }
  return error;
};

export const addEventValidation = (value, active, isStrategic) => {
  let error = {};
  if (!value.eventname || !value.eventname.trim()) {
    error.eventname = "Please Enter Event Name ";
  }
  if (!value.eventhostname || !value.eventhostname.trim()) {
    error.eventhostname = "Please Enter Event Host Name";
  }
  if (!value.eventdescription || !value.eventdescription.trim()) {
    error.eventdescription = "Please Enter Event Description";
  }
  if (!isStrategic) {
    if (!value.community) {
      error.community = "Please Select Community";
    }
  }

  if (!value.eventhostemailaddresh || !value.eventhostemailaddresh.trim()) {
    error.eventhostemailaddresh = "Please Enter Event Email";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.eventhostemailaddresh
    )
  ) {
    error.eventhostemailaddresh = "Please enter valid email address.";
  }
  if (
    value.eventnumber &&
    value.eventnumber[0] === "(" &&
    value.eventnumber[13] === " "
  ) {
    error.eventnumber = "Please Enter Valid Phone Number";
  }
  if (active === "offline") {
    if (!value.EventAddress || !value.EventAddress.trim()) {
      error.EventAddress = "Please Enter Event Address";
    }
    // if (!value.EventAddress2 || !value.EventAddress2.trim()) {
    //   error.EventAddress2 = "Please Enter Event Address Line2";
    // }
    if (!value.state) {
      error.state = "Please Select State";
    }
    if (!value.city) {
      error.city = "Please Select City";
    }
    if (!value.pincode) {
      error.pincode = "Please Enter Zip Code";
    } else if (!patterns.numeric.test(value.pincode)) {
      error.pincode = "Zipcode shoud be 5 digit";
    }
  } else {
    if (!value.eventLink || !value.eventLink.trim()) {
      error.eventLink = "Please Enter Event Link";
    } else if (
      value.eventLink &&
      !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
        value.eventLink
      )
    ) {
      error.eventLink = "Enter valid Event Link";
    }
  }

  return error;
};

export const EditEventValidation = (value, active) => {
  let error = {};
  if (!value.eventname || !value.eventname.trim()) {
    error.eventname = "Please Enter Event Name ";
  }
  if (!value.eventhostname || !value.eventhostname.trim()) {
    error.eventhostname = "Please Enter Event Host Name";
  }
  if (!value.eventdescription || !value.eventdescription.trim()) {
    error.eventdescription = "Please Enter Event Description";
  }
  if (!value.eventhostemailaddresh || !value.eventhostemailaddresh.trim()) {
    error.eventhostemailaddresh = "Please Enter Event Email";
  } else if (
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.eventhostemailaddresh
    )
  ) {
    error.eventhostemailaddresh = "Please enter valid email address.";
  }
  if (
    value.eventnumber &&
    value.eventnumber[0] === "(" &&
    value.eventnumber[13] === " "
  ) {
    error.eventnumber = "Please Enter Valid Phone Number";
  }
  if (active === "Offline") {
    if (!value.eventAddress || !value.eventAddress.trim()) {
      error.eventAddress = "Please Enter Event Address";
    }

    if (!value.pincode) {
      error.pincode = "Please Enter Zip Code";
    } else if (!patterns.numeric.test(value.pincode)) {
      error.pincode = "Zipcode shoud be 5 digit";
    }
  } else if (active === "virtual") {
    if (!value.eventLink || !value.eventLink.trim()) {
      error.eventLink = "Please Enter Event Link";
    } else if (
      value.eventLink &&
      !/^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/.test(
        value.eventLink
      )
    ) {
      error.eventLink = "Enter valid Event Link";
    }
  }

  return error;
};

export const strategicRegistrationValidation = (value) => {
  let error = {};

  if (
    !value.strategicpartneruserName ||
    !value.strategicpartneruserName.trim()
  ) {
    error.strategicpartneruserName = "Please Enter Giving Partner Username ";
  }
  if (!value.strategicname || !value.strategicname.trim()) {
    error.strategicname = "Please Enter Giving Partner Name";
  }
  if (!value.strategicownername || !value.strategicownername.trim()) {
    error.strategicownername =
      "Please Enter Giving Partner Representative Name";
  } else if (
    value.strategicownername &&
    /^\d*\.?\d+$/.test(value.strategicownername)
  ) {
    error.strategicownername = "Only characters are allowed";
  }

  if (!value.strategicemailaddress || !value.strategicemailaddress.trim()) {
    error.strategicemailaddress = "Please Enter Giving Partner Email Address";
  } else if (
    value.strategicemailaddress &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.strategicemailaddress
    )
  ) {
    error.strategicemailaddress =
      "Please Enter valid Giving Partner Email Address";
  }
  if (!value.password || !value.password.trim()) {
    error.password = "Please Enter Password";
  } else if (value.password && !/^[^\s]+(\s+[^\s]+)*$/.test(value.password)) {
    error.password = " New Password must not contain while spaces";
  } else if (
    value.password &&
    !/^(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~])(?=.*[a-z])(?=.*[A-Z]).{8,20}$/.test(
      value.password
    )
  ) {
    error.password =
      "New Password must be 8-20 characters and contain at least one number/symbol/upper/lower case letter & not contain white spaces";
  }
  if (!value.Confirmpassword || !value.Confirmpassword.trim()) {
    error.Confirmpassword = "Please Enter Confirm Password";
  } else if (value.password !== value.Confirmpassword) {
    error.Confirmpassword = "Confirm Password must match with Password";
  }

  return error;
};
export const AddPAymentValidation = (value) => {
  let error = {};
  if (!value.accountholdername || !value.accountholdername.trim()) {
    error.accountholdername = "Please Enter Account Holder Name";
  } else if (!patterns.alpha_spaces.test(value.accountholdername)) {
    error.accountholdername = "Please Enter valid Account Holder Name";
  }
  if (!value.bankname || !value.bankname.trim()) {
    error.bankname = "Please Enter Bank Name";
  }
  if (!value.accountnumber || !value.accountnumber.trim()) {
    error.accountnumber = "Please Enter Account Number";
  } else if (!patterns.numeric.test(value.accountnumber)) {
    error.accountnumber = "Please Enter valid Account Number";
  } else if (
    !value.accountnumber.trim() ||
    value.accountnumber.trim().length > 17
  ) {
    error.accountnumber = "Please Enter valid Account Number";
  }
  // else if (!value.accountnumber.trim() || value.accountnumber.trim().length !== 19){
  //   error.accountnumber = 'Account Number shoud be 16 digit'
  // }

  if (!value.routingnumber || !value.routingnumber.trim()) {
    error.routingnumber = "Please Enter Routing Number";
  } else if (value.routingnumber.length !== 9) {
    error.routingnumber = "Please Enter  Valid Routing Number";
  }
  return error;
};
export const AddcontactValidation = (value) => {
  let error = {};
  if (!value.Name || !value.Name.trim()) {
    error.Name = "Please Enter Name";
  }
  if (!value.Message || !value.Message.trim()) {
    error.Message = "Please Enter Message";
  }
  if (!value.email || !value.email.trim()) {
    error.email = "Please Enter  Email Address";
  } else if (
    value.email &&
    !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
      value.email
    )
  ) {
    error.email = "Please Enter valid  Email Address";
  }

  return error;
};

export const strategicEditValidation = (value) => {
  let error = {};
  if (!value.StrategicUserName || !value.StrategicUserName.trim()) {
    error.StrategicUserName = "Please Enter Giving Partner Username ";
  }
  if (!value.strategicAddress || !value.strategicAddress.trim()) {
    error.strategicAddress = "Please Enter Giving Partner Address Line 1";
  }

  if (!value.StrategicName || !value.StrategicName.trim()) {
    error.StrategicName = "Please Enter Giving Partner Business Name";
  }
  if (!value.StrategicPersonName || !value.StrategicPersonName.trim()) {
    error.StrategicPersonName =
      "Please Enter Giving Partner Representative Name";
  }
  if (
    !value.StrategicBriefDescription ||
    !value.StrategicBriefDescription.trim()
  ) {
    error.StrategicBriefDescription =
      "Please Enter Giving Partner Brief Description";
  }
  if (!value.StrategicDescription || !value.StrategicDescription.trim()) {
    error.StrategicDescription = "Please Enter Giving Partner Description";
  }
  if (!value.pincode) {
    error.pincode = "Please Enter Zip Code";
  } else if (value.pincode?.length !== 5) {
    error.pincode = " Zipcode shoud be 5 digit ";
  }

  if (!value.PhoneNumber) {
    error.PhoneNumber = "Please Enter Your Phone Number";
  }
  // else if (removeFormatting(value?.PhoneNumber || '').length !== 10) {
  //   error.PhoneNumber = " Phone Number should be 10 digit ";
  // }
  else if (
    value.PhoneNumber &&
    value.PhoneNumber[0] !== "(" &&
    value.PhoneNumber.toString().length !== 10
  ) {
    error.PhoneNumber = "Please Enter Valid Phone Number";
  }
  // && value.PhoneNumber.length !== 14
  else if (
    value.PhoneNumber &&
    value.PhoneNumber[0] === "(" &&
    value.PhoneNumber[13] === " "
  ) {
    error.PhoneNumber = "Please Enter Valid Phone Number";
  }
  if (!value.city?.value) {
    error.city = "Please Enter City";
  }
  if (!value.state?.value) {
    error.state = "Please Enter State";
  }
  if (value.strategicAddress2 && value.strategicAddress2.trim().length === 0) {
    error.strategicAddress2 =
      "Please Enter Valid Giving Partner Address Line 2";
  }
  if (
    value.website &&
    !/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/.test(
      value.website
    )
  ) {
    error.website = "Please Enter valid  Giving Partner Website";
  }
  if (value.tagline && value.tagline.trim().length === 0) {
    error.tagline = "Please Enter Giving Partner valid Tagline";
  }
  return error;
};
