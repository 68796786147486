import { STRATEGIC_APPROVE_EMPLOYEE_F, STRATEGIC_APPROVE_EMPLOYEE_S, STRATEGIC_CREATE_EMPLOYEE_F, STRATEGIC_CREATE_EMPLOYEE_S, STRATEGIC_DASHBOARD_F, STRATEGIC_DASHBOARD_S, STRATEGIC_DAYGRAPH_DASHBOARD_F, STRATEGIC_DAYGRAPH_DASHBOARD_S, STRATEGIC_DELETE_EMPLOYEE_F, STRATEGIC_DELETE_EMPLOYEE_S, STRATEGIC_EMPLOYEE_VERIFICATION_F, STRATEGIC_EMPLOYEE_VERIFICATION_S, STRATEGIC_PENDING_EMPLOYEE_F, STRATEGIC_PENDING_EMPLOYEE_S, STRATEGIC_SIX_MONTH_DASHBOARD_F, STRATEGIC_SIX_MONTH_DASHBOARD_S, STRATEGIC_USER_UPDATE_F, STRATEGIC_USER_UPDATE_S, STRATEGIC_USER_VIEW_F, STRATEGIC_USER_VIEW_S, STRATEGIC_YEARGRAPH_DASHBOARD_F, STRATEGIC_YEARGRAPH_DASHBOARD_S } from "../constants/types";

const initialState = {
    strategicPending: {},
    strategicEmployee: {},
    employeeVerification: {},
    strategicApprove: {},
    deleteEmployee: {},
    strategicUserView: {},
    strategicUserUpdate: {},
    strategicPartnerDashboard: {},
    strategicDayGraphDashboard: [],
    strategicYearGraphDashboard: [],
    strategicsixmonthDashboard: [],
};
const StrategicReducer = (state = initialState, action) => {
    switch (action.type) {
        case STRATEGIC_PENDING_EMPLOYEE_S:
            return {
                ...state,
                strategicPending: action.payload.data,
            };
        case STRATEGIC_PENDING_EMPLOYEE_F:
            return {
                ...state,
                strategicPending: action.payload.message,
            };
        case STRATEGIC_CREATE_EMPLOYEE_S:
            return {
                ...state,
                strategicEmployee: action.payload.data,
            }
        case STRATEGIC_CREATE_EMPLOYEE_F:
            return {
                ...state,
                strategicEmployee: action.payload.message,
            };
        case STRATEGIC_EMPLOYEE_VERIFICATION_S:
            return {
                ...state,
                employeeVerification: action.payload.data,
            };
        case STRATEGIC_EMPLOYEE_VERIFICATION_F:
            return {
                ...state,
                employeeVerification: action.payload.message,
            };
        case STRATEGIC_APPROVE_EMPLOYEE_S:
            return {
                ...state,
                strategicApprove: action.payload.data,
            };
        case STRATEGIC_APPROVE_EMPLOYEE_F:
            return {
                ...state,
                strategicApprove: action.payload.message,
            };
        case STRATEGIC_DELETE_EMPLOYEE_S:
            return {
                ...state,
                deleteEmployee: action.payload.data,
            };
        case STRATEGIC_DELETE_EMPLOYEE_F:
            return {
                ...state,
                deleteEmployee: action.payload.message,
            };
        case STRATEGIC_USER_VIEW_S:
            return {
                ...state,
                strategicUserView: action.payload.data,
            };
        case STRATEGIC_USER_VIEW_F:
            return {
                ...state,
                strategicUserView: action.payload.message,
            };
        case STRATEGIC_USER_UPDATE_S:
            return {
                ...state,
                strategicUserUpdate: action.payload.data,
            };
        case STRATEGIC_USER_UPDATE_F:
            return {
                ...state,
                strategicUserUpdate: action.payload.message,
            };
        case STRATEGIC_DASHBOARD_S:
            return {
                ...state,
                strategicPartnerDashboard: action.payload.data,
            };
        case STRATEGIC_DASHBOARD_F:
            return {
                ...state,
                strategicPartnerDashboard: action.payload.message,
            };
        case STRATEGIC_DAYGRAPH_DASHBOARD_S:
            return {
                ...state,
                strategicDayGraphDashboard: action.payload.data,
            };
        case STRATEGIC_DAYGRAPH_DASHBOARD_F:
            return {
                ...state,
                strategicDayGraphDashboard: action.payload.message,
            };
        case STRATEGIC_YEARGRAPH_DASHBOARD_S:
            return {
                ...state,
                strategicYearGraphDashboard: action.payload.data,
            };
        case STRATEGIC_YEARGRAPH_DASHBOARD_F:
            return {
                ...state,
                strategicYearGraphDashboard: action.payload.message,
            };
        case STRATEGIC_SIX_MONTH_DASHBOARD_S:
            return {
                ...state,
                strategicsixmonthDashboard: action.payload.data
                ,
            };
        case STRATEGIC_SIX_MONTH_DASHBOARD_F:
            return {
                ...state,
                strategicsixmonthDashboard: action.payload.message,
            };

        default:
            return state;
    }
};

export default StrategicReducer