import moment from "moment";
import React from "react";
import { RenderImage } from ".";
import { dateFormatChat, getImageUrl, toAbsoluteUrl } from "../../utils";

const ChatUserList = ({ mediaURL, onUserClick, data, chatReducer }) => {
  return (
    <li
      onClick={() => onUserClick(data)}
      className={`${data?.lastMessage?.isRead === false ? "notReadList" : ""}`}
    >
      <div
        className={`chat-user ${
          chatReducer?.activeUser?.roomId === data?.roomId ? "active-chat" : ""
        } `}
      >
        <div className="rosiChatWrap">
          <RenderImage
            className="border-50"
            filePath={
              data?.userDetails?.profileImage
                ? getImageUrl(
                    mediaURL,
                    "userProfilePic",
                    data?.userDetails?.profileImage
                  )
                : toAbsoluteUrl("/images/userLogo.png")
            }
          />
          <div className="chat-nm-desc">
            <strong>{data.userDetails?.name || ""}</strong>
            {data?.lastMessage?.isCleared === true ? (
              ""
            ) : (
              <p>{data?.lastMessage?.message || ""}</p>
            )}
          </div>
        </div>
        <div className="rosiTime">
          <div className="chat-time">
            <p>{moment(data?.lastMessage?.createdDate).format("MM/DD/YYYY")}</p>
            <span>{dateFormatChat(data?.lastMessage?.createdDate)}</span>
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChatUserList;
