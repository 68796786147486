/* eslint-disable no-unused-vars */
import React, { Suspense, useEffect, useState } from "react";
import axios from "axios";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import store from "../store";
import Routes from "./routes";
import ScrollToTop from "../components/common/scroll";
import Loader from "../components/common/loader";
import { setupAxios, toAbsoluteUrl } from "../utils";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "video-react/styles/scss/video-react.scss";
import { ErrorBoundary } from "../components/Error";
import { getFCMToken, messaging } from "../utils/firebase";
import { onMessage } from "firebase/messaging";

const { PUBLIC_URL } = process.env;

setupAxios(axios, store);

const AppContainer = () => {
  const [isTokenFound, setTokenFound] = useState(false);
  const [getFcmToken, setFcmToken] = useState("");

  const openNotification = (notificationdata, placement) => {
    // `🦄 ${notificationdata?.notification?.body}`
    toast(<div>{notificationdata?.notification?.body}</div>, {
      position: "bottom-right",
      icon: (
        <img
          src={toAbsoluteUrl("/images/favicon.png")}
          alt="logo"
          height="30px"
        />
      ),
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    });
  };
  const is_ios_webview = window
    ? /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
        window.navigator.userAgent
      )
    : true;

  if (!is_ios_webview)
    onMessage(messaging, (payload) => {
      openNotification(payload, "top");
    });

  useEffect(() => {
    if (!is_ios_webview) {
      getFCMToken(setTokenFound, setFcmToken);
      if (isTokenFound && getFcmToken) {
        localStorage.setItem("fcmtoken", getFcmToken);
      }
    }
  }, [is_ios_webview, isTokenFound, getFcmToken]);

  return (
    <Provider store={store}>
      <ToastContainer hideProgressBar={true} />
      <Suspense fallback={<Loader isSuspense />}>
        <Loader>
          <ErrorBoundary>
            <BrowserRouter basename={PUBLIC_URL}>
              <ScrollToTop>
                <Routes />
              </ScrollToTop>
            </BrowserRouter>
          </ErrorBoundary>
        </Loader>
      </Suspense>
    </Provider>
  );
};

export default AppContainer;
