import React, { useEffect, useRef, useState } from "react";
import { rendertextarea } from "../../../../components/forms";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { postAdd, strategicPostList } from "../../../../actions/post";
import { toast } from "react-toastify";
import Videos from "../../../svg/video";
import Images from "../../../svg/image";
import "./post.scss";
import Post from "../../../common/strategicPost";
import PostModal from "../../../common/strategicPost/PostModal";
import Loading from "../../../common/loader";
import { doUploadImage, doUploadVideo } from "../../../../actions/registration";
import { getVideoUrl } from "../../../../utils";

const ProfilePost = () => {
  const formRef = useRef();
  const formRef2 = useRef();
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const strategicpostdetail = useSelector(
    (state) => state.PostReducer.strategicPostList.employeePostList
  );
  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const [isvideo, setisVideo] = useState(false);
  const [ismodel, setmodel] = useState(false);
  const [PostImages, setPostImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [PostVideos, setPostVideos] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [isLoading, setisLoading] = useState(false);
  const [isMyPost, setIsMyPost] = useState(false);

  useEffect(() => {
    const data = {
      page: 1,
      limit: 1000000,
    };
    if (strategicdetail?._id) {
      dispatch(strategicPostList(strategicdetail?._id, data, !isMyPost));
    }
  }, [strategicdetail, isMyPost, dispatch]);

  const onSubmit = (value) => {
    if (value.Caption.trim().length <= 0) {
      return;
    }
    const data = {
      strategicPartnerId: strategicdetail?._id,
      description: value.Caption,
      type: 3,
      video: [],
      image: [],
    };
    dispatch(postAdd(data)).then((res) => {
      if (formRef2?.current?.reset) {
        formRef2.current?.reset();
      }
      const data = {
        page: 1,
        limit: 100,
      };
      setTimeout(() => {
        dispatch(strategicPostList(strategicdetail?._id, data, !isMyPost));
      }, 400);
    });
  };

  const imageUploadHandler = () => {
    return imageFiles.map((image, ind) => {
      const payload = { image: [image], moduleName: "postImages" };
      return dispatch(doUploadImage(payload));
    });
  };

  const videoUploadHandler = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const onPostSubmit = (value) => {
    if (isvideo === false && imageFiles.length === 0) {
      return toast.error("Please attach Image");
    }
    if (isvideo === true && videoSourceFiles.length === 0) {
      return toast.error("Please attach Video");
    }

    let data = {
      strategicPartnerId: strategicdetail?._id,
      description: value.Caption,
      image: [],
      type: isvideo === true ? 2 : 1,
      video: [],
    };
    let postImages = [];

    if (imageFiles.length) {
      Promise.allSettled([...imageUploadHandler()]).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            postImages.push({
              name: value.value?.data?.name,
              url: metaData?.mediaURL + "postImages/" + value.value?.data?.name,
            });
          }
          return null;
        });
        data = { ...data, image: postImages };
        addPostHandler(data);
      });
    } else if (videoSourceFiles.length) {
      const videoPayload = [];
      Promise.allSettled(videoUploadHandler()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(
                metaData?.mediaURL,
                "postImages",
                value.value?.data?.name
              ),
              thumbnail: value.value?.data.thumbnail,
            });
          }
          return null;
        });
        data = { ...data, video: videoPayload };
        addPostHandler(data);
      });
    } else {
      addPostHandler(data);
    }
  };

  const addPostHandler = (data) => {
    dispatch(postAdd(data)).then((res) => {
      if (formRef?.current?.reset) {
        formRef.current?.reset();
      }
      setmodel(!ismodel);
      const data = {
        page: 1,
        limit: 100,
      };
      setisLoading(true);
      setPostImages([]);
      setPostVideos([]);
      setImageFiles([]);
      setTimeout(() => {
        dispatch(strategicPostList(strategicdetail?._id, data, !isMyPost))
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      }, 400);
      toast.success(res.message);
    });
  };

  return (
    <>
      <div className="community-box">
        <div className="post-box">
          <div className="d-flex align-items-end w-100">
            <div className="d-flex w-100 flex-column">
              <span>Post Anything Related to Community...</span>
              <div className="main-box-div post-main-box">
                <Form onSubmit={onSubmit}>
                  {({ handleSubmit, form, values }) => {
                    formRef2.current = form;

                    return (
                      <form onSubmit={handleSubmit}>
                        <div className="input-list d-flex flex-wrap w-100">
                          <div className="col-12 col-div p-0">
                            <Field
                              name="Caption"
                              placeholder="What’s On Your Mind? "
                              component={rendertextarea}
                              className="form-grp form-input-box"
                              inputclass="input-box text-area-box"
                            />
                            <button type="submit" className="post-btn">
                              Post
                            </button>
                          </div>
                        </div>
                      </form>
                    );
                  }}
                </Form>
                <div className="icon-box">
                  <i
                    title="upload"
                    onClick={() => {
                      setmodel(!ismodel);
                      setisVideo(false);
                    }}
                  >
                    <Images />
                  </i>
                  <i
                    title="upload"
                    onClick={() => {
                      setmodel(!ismodel);
                      setisVideo(true);
                    }}
                  >
                    <Videos />
                  </i>
                </div>
              </div>
            </div>
          </div>
          <div className="post-area-box">
            <div className="post-title">
              <h4> {`${!isMyPost ? "My Posts" : "Member Posts"}`}</h4>
              <span onClick={() => setIsMyPost((prev) => !prev)}>
                Switch to <b>{`${!isMyPost ? "Member Posts" : "My Posts"}`}</b>
              </span>
            </div>
            <div className="post-inner">
              <div>
                <ul className="post-list">
                  {!isLoading ? (
                    strategicpostdetail?.map((val, i) => {
                      return (
                        <Post
                          key={val?._id}
                          data={val}
                          isMyPost={isMyPost}
                          isLoading={isLoading}
                          setisLoading={setisLoading}
                          from="Strategic"
                        />
                      );
                    })
                  ) : (
                    <Loading />
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* upload image and video  */}
      <PostModal
        AllImages={PostImages}
        setAllImages={setPostImages}
        imageFiles={imageFiles}
        setImageFiles={setImageFiles}
        onSubmit={onPostSubmit}
        setmodel={setmodel}
        ismodel={ismodel}
        AllVideos={PostVideos}
        setAllVideos={setPostVideos}
        video={isvideo}
        setisVideo={setisVideo}
        formRef={formRef}
        EditPost={false}
        videoSourceURLs={videoSourceURLs}
        setVideoSourceURLs={setVideoSourceURLs}
        videoSourceFiles={videoSourceFiles}
        setVideoSourceFiles={setVideoSourceFiles}
        isMultiple={false}
      />
    </>
  );
};

export default ProfilePost;
