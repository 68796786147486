import React from 'react'

const Images = () => {
  return (
    <>
      <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.625 0.625H1.375C0.752734 0.625 0.25 1.12773 0.25 1.75V24.25C0.25 24.8723 0.752734 25.375 1.375 25.375H30.625C31.2473 25.375 31.75 24.8723 31.75 24.25V1.75C31.75 1.12773 31.2473 0.625 30.625 0.625ZM9.88281 5.6875C11.1238 5.6875 12.1328 6.69648 12.1328 7.9375C12.1328 9.17852 11.1238 10.1875 9.88281 10.1875C8.6418 10.1875 7.63281 9.17852 7.63281 7.9375C7.63281 6.69648 8.6418 5.6875 9.88281 5.6875ZM27.9496 21.0543C27.8984 21.0973 27.8337 21.121 27.7668 21.1211H4.22969C4.075 21.1211 3.94844 20.9945 3.94844 20.8398C3.94844 20.773 3.97305 20.7098 4.01523 20.657L10.0023 13.5555C10.1008 13.4359 10.2801 13.4219 10.3996 13.5203C10.4102 13.5309 10.4242 13.5414 10.4348 13.5555L13.9293 17.7039L19.4875 11.1121C19.5859 10.9926 19.7652 10.9785 19.8848 11.077C19.8953 11.0875 19.9094 11.098 19.9199 11.1121L27.9918 20.6605C28.0832 20.7766 28.0691 20.9559 27.9496 21.0543Z" fill="#42A44C" fillOpacity="0.86"/>
      </svg>

    </>
  )
}

export default Images
