import {
  FAQ_CATEGORY_LIST_F,
  FAQ_CATEGORY_LIST_S,
  FAQ_LIST_F,
  FAQ_LIST_S,
  GET_CMS_LIST_F,
  GET_CMS_LIST_S,
} from "../constants/types";

const initialState = {
  policy: [],
  Faqcategory: [],
  Faqlist: [],
};

const ContentReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CMS_LIST_S:
      return {
        ...state,
        policy: action.payload.data.CMSList,
      };
    case GET_CMS_LIST_F:
      return {
        ...state,
        policy: action.payload.message,
      };
    case FAQ_CATEGORY_LIST_S:
      return {
        ...state,
        Faqcategory: action.payload.data,
      };
    case FAQ_CATEGORY_LIST_F:
      return {
        ...state,
        Faqcategory: action.payload.message,
      };
    case FAQ_LIST_S:
      return {
        ...state,
        Faqlist: action.payload.data,
      };
    case FAQ_LIST_F:
      return {
        ...state,
        Faqlist: action.payload.message,
      };
    default:
      return state;
  }
};
export default ContentReducer;
