import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Charitydetail,
  EditCharitydetail,
} from "../../../../../actions/charity";
import {
  communityDetail,
  EditCommunityDetail,
} from "../../../../../actions/community";
import { doImageDelete } from "../../../../../actions/registration";
import {
  strategicUserUpdate,
  strategicUserView,
} from "../../../../../actions/strategic";
import Delete from "../../../../svg/delete";

const Image = ({ Images = [], moduleName, key, from }) => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const charitydetail = useSelector(
    (state) => state.ChairtyReducer.chairtyDetail?.charityList
  );
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.communityDetails
  );
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );

  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const handleOldDelete = (val) => {
    const data = {
      fileName: val,
      moduleName: moduleName,
    };
    dispatch(doImageDelete(data));
    let filteredImages = Images.filter((o) => o?.name !== val);

    switch (from) {
      case "charity":
        const datacharity = {
          charityId: Change?._id,
          relatedCause: [],
          categoryId: charitydetail?.charityDetails?.categoryId,
          mediaImage: filteredImages,
        };
        dispatch(EditCharitydetail(Change?._id, datacharity))
          .then((res) => {
            dispatch(Charitydetail(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "strategic":
        const dataStrategic = {
          strategicPartnerId: Change?._id,
          mediaImage: filteredImages,
        };
        const allStrategicData = { ...strategicdetail, ...dataStrategic };
        dispatch(strategicUserUpdate(allStrategicData, Change?._id))
          .then((res) => {
            dispatch(strategicUserView(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "commmunity":
        const datacommunity = {
          _id: communitydetail?._id,
          charityId: Change?._id,
          mediaImage: filteredImages,
        };
        dispatch(EditCommunityDetail(datacommunity))
          .then((res) => {
            dispatch(communityDetail(communitydetail?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;
      default:
        break;
    }
  };
  return (
    <>
      <div className="image-box">
        <div className="post-box">
          <div className="post-area-box">
            <div className="post-inner">
              <div className="h-100">
                <ul className="post-list">
                  {Images.map((image, i) => (
                    <li key={image?.name}>
                      <figure className="upload-img" >
                        <i onClick={() => handleOldDelete(image?.name)}>
                          {" "}
                          <Delete />{" "}
                        </i>
                        <img
                          src={
                            metaData.mediaURL + moduleName + "/" + image?.name
                          }
                          alt="world-img"
                        />
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Image;
