import { PAYMENT_DETAIL_GET_F, PAYMENT_DETAIL_GET_S } from "../constants/types";

const initialState = {
   paymentDetail:{}
};

const PaymentReducer = (state = initialState, action) => {
    switch (action.type) {
        case PAYMENT_DETAIL_GET_S:
            
            return {
                ...state, paymentDetail: action.payload.data
            }
        case PAYMENT_DETAIL_GET_F:
            return {
                ...state, paymentDetail: action.payload
            }
        // case EVENT_DETAILS_S:
        //     return {
        //         ...state, eventdetails: action.payload.data
        //     }
        // case EVENT_DETAILS_F:
        //     return {
        //         ...state, eventdetails: action.payload.message
        //     }
        default:
            return state;
    }
}
export default PaymentReducer