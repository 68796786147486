/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link, useNavigate } from "react-router-dom";
import DatePicker from "react-datepicker";
import {
  RenderCityField,
  renderField,
  renderNumberField,
  renderRadioButton,
  RenderSelectField,
  rendertextarea,
} from "../../../components/forms";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import ImgUpload from "../../../components/common/img-upload-home";
import "../event.scss";
import {
  addTOCity,
  addTOCountry,
  addTOState,
  doUploadImage,
} from "../../../actions/registration";
import { useDispatch, useSelector } from "react-redux";
import { eventAdd } from "../../../actions/event";
import { mergeDateTime } from "../../../utils";
import { communityList } from "../../../actions/community";
import { addEventValidation as validate } from "../../../components/validations/signIn";
import { toast } from "react-toastify";
import moment from "moment";

const list = [
  {
    name: "Event Management",
    link: "/event",
    isactive: false,
  },
  {
    name: "Event Add",
    link: "/event/event_add",
    isactive: true,
  },
];

const EventAdd = () => {
  const Change = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [country, setCountry] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [community, setCommunity] = useState([]);
  // const [selectedCountry, setselectedCountry] = useState()
  const [selectedState, setselectedState] = useState();
  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",
    phone_code: "1",
  });
  //Event Image
  const [eventImages, setEventImages] = useState();
  const [eventImageserror, seteventImagesError] = useState("");

  const [eventHostImages, setEventHostImages] = useState();
  const [eventHostIMageserror, seteventHostImagesError] = useState("");

  const [startDate, setStartDate] = useState();
  const [dateerror, setDateError] = useState("");

  const [time, setTime] = useState(moment().format("hh mm a"));
  const [timeerror, setTimeError] = useState("");
  const [active, setActive] = useState("offline");

  const [endTime, setendTime] = useState("");
  const [endTimeError, setendTimeError] = useState("");

  useEffect(() => {
    fetchCountry();
    fetchCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    fetchCity({ stateId: selectedState });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);

  useEffect(() => {
    if (time && startDate) {
      if (moment().utc().format() > mergeDateTime(startDate, time)) {
        setTimeError("Please select future time ");
      } else {
        setTimeError("");
      }
    }
    if (!endTime && startDate) {
      setendTimeError("Please select future time ");
    } else {
      setendTimeError("");
    }

    if (endTime && startDate) {
      if (mergeDateTime(startDate, endTime) < mergeDateTime(startDate, time)) {
        setendTimeError("Please select future time ");
      } else {
        setendTimeError("");
      }
    }
    if (eventHostImages) {
      seteventHostImagesError("");
    }
    if (eventImages) {
      seteventImagesError("");
    }
    if (startDate) {
      setDateError("");
    }
  }, [time, eventHostImages, eventImages, startDate, endTime]);

  const fetchCommunity = () => {
    const val = {
      page: 1,
      limit: 100000,
      status: true,
    };
    dispatch(communityList(Change?.user?.data?._id, val)).then((res) => {
      const data = res.data;
      let communityArr = [];
      data.list.map((val, ind) => {
        return communityArr.push({ value: val._id, label: val.communityName });
      });
      setCommunity(communityArr);
    });
  };
  const fetchCountry = (id) => {
    dispatch(addTOCountry()).then((res) => {
      const data = res.data;
      let countryArr = [];
      data.map((val, ind) => {
        return countryArr.push({
          value: val.name,
          label: val.name,
          id: val.id,
        });
      });
      setCountry(countryArr);
    });
  };

  const fetchState = (data) => {
    dispatch(addTOState(data)).then((res) => {
      const data = res.data;
      let stateArr = [];
      data.map((val, ind) => {
        return stateArr.push({ value: val.name, label: val.name, id: val.id });
      });
      setState(stateArr);
    });
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data)).then((res) => {
      const data = res.data;
      let cityArr = [];
      data.map((val, ind) => {
        return cityArr.push({ value: val.name, label: val.name });
      });
      setCity(cityArr);
    });
  };

  const uploadEventHostImage = () => {
    const payload = { image: eventHostImages, moduleName: "eventHostImages" };
    return dispatch(doUploadImage(payload));
  };

  const uploadEventImage = () => {
    const payload = { image: eventImages, moduleName: "eventImages" };
    return dispatch(doUploadImage(payload));
  };

  const onSubmit = (value) => {
    console.log("value: ", value);
    if (!startDate) {
      setDateError("Please Enter  Event Date");
    }

    if (!endTime) {
      setDateError("Please Enter  end time");
      return;
    }

    if (!time) {
      setTimeError("Please Enter  Event Time");
      return;
    } else if (!eventHostImages) {
      seteventHostImagesError("Please Upload Event Host Image");
      return;
    } else if (!eventImages) {
      seteventImagesError("Please Upload Event Image");
      return;
    }
    let utcDate = mergeDateTime(startDate, time);
    let EndTime = mergeDateTime(startDate, endTime);
    let eventImage = null;
    let eventHostImage = null;
    let data = {
      eventName: value.eventname,
      eventHostname: value.eventhostname,
      description: value.eventdescription,
      eventType: active,
      startDate: utcDate,
      eventTime: utcDate,
      eventEndTime: EndTime,
      // eventHostImage: images,
      // eventImage: mediaimages,
      eventHostPhoneNo:
        value.eventnumber && `${value.eventnumber?.replace(/[^\d]/g, "")}`,
      eventHostEmail: value.eventhostemailaddresh,
    };
    if (Change?.isStrategic) {
      data = { ...data, strategicPartnerId: Change?.user?.data?._id };
    } else {
      data = {
        ...data,
        charityId: Change?.user?.data?._id,
        communityId: value.community.value,
      };
    }
    // if (value.EventAddress2) {
    //   data = { ...data, street2: value.EventAddress2 };
    // }
    let finalData =
      active !== "offline"
        ? { ...data, eventLink: value.eventLink }
        : {
            ...data,
            address: {
              floor: value.EventAddress,
              number: value.EventAddress,
              street: value.EventAddress,
              street2: value.EventAddress2 ? value.EventAddress2 : "",
              city: value.city.label,
              state: value.state.label,
              zipcode: value.pincode,
              country: value.country.label,
            },
          };

    Promise.allSettled([uploadEventHostImage(), uploadEventImage()]).then(
      (res) => {
        if (res?.[0].status === "fulfilled") {
          eventHostImage = res[0].value?.data?.name;
        }
        if (res?.[1].status === "fulfilled") {
          eventImage = res[1].value?.data?.name;
        }

        finalData = { ...finalData, eventHostImage, eventImage };
        eventAddHandler(finalData);
      }
    );
  };

  const eventAddHandler = (data) => {
    dispatch(eventAdd(data))
      .then((res) => {
        navigate("/event");
        toast.success(res.message);
      })
      .catch((res) => toast.error(res.message));
  };

  return (
    <>
      <div>
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Event Add </h2>
        </div>
        <div className="section-inner padding-area event-section">
          <div className="form-area">
            <Form
              mutators={{
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={onSubmit}
              initialValues={{
                country: selectedCountry,
                radio1: "offline",
              }}
              validate={(value) => validate(value, active, Change?.isStrategic)}
            >
              {({ handleSubmit, form, errors, values }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input-list d-flex flex-wrap">
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Name
                      </label>
                      <Field
                        name="eventname"
                        type="text"
                        placeholder="Event Name"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Host Name
                      </label>
                      <Field
                        name="eventhostname"
                        type="text"
                        placeholder="Event Host Name"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        Event Host Phone Number
                      </label>
                      <Field
                        name="eventnumber"
                        placeholder="Event Host Phone Number"
                        component={renderNumberField}
                        formatStyle={"(###) ###-####"}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Host Email Address
                      </label>
                      <Field
                        name="eventhostemailaddresh"
                        type="text"
                        placeholder="Event Host Email Address"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                      />
                    </div>

                    <div className="col-12 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Description
                      </label>
                      <Field
                        name="eventdescription"
                        type="text"
                        placeholder="Event Description"
                        component={rendertextarea}
                        className="form-grp"
                        inputclass="input-box text-area-box "
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Date{" "}
                      </label>
                      <DatePicker
                        minDate={new Date(moment().startOf("day"))}
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        className="input-box"
                        placeholderText="Select Date"
                      />
                      <span className="text-danger">{dateerror}</span>
                    </div>
                    <div className="col-md-3 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Start Time{" "}
                      </label>
                      <input
                        type={"time"}
                        className="input-box"
                        value={time}
                        onChange={(e) => {
                          setTime(e.target.value);
                        }}
                      />
                      <span className="text-danger">{timeerror}</span>
                    </div>
                    <div className="col-md-3 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event End Time{" "}
                      </label>
                      <input
                        type={"time"}
                        className="input-box react-datepicker-ignore-onclickoutside"
                        onChange={(e) => {
                          setendTime(e.target.value);
                        }}
                      />
                      <span className="text-danger">{endTimeError}</span>
                    </div>
                    {!Change?.isStrategic && (
                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Select Community{" "}
                        </label>
                        <Field
                          name="community"
                          component={RenderSelectField}
                          className="basic-multi-select"
                          options={community}
                          isMulti={false}
                        />
                      </div>
                    )}
                    <div className="col-12 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Type{" "}
                      </label>
                      <div className="checkbox-list d-flex flex-wrap">
                        <Field
                          name="radio1"
                          type="radio"
                          value="offline"
                          className="check-label"
                          setActive={setActive}
                          component={renderRadioButton}
                        >
                          In-Person Event
                        </Field>
                        <Field
                          name="radio1"
                          type="radio"
                          value="virtual"
                          className="check-label"
                          setActive={setActive}
                          component={renderRadioButton}
                        >
                          Virtual Event
                        </Field>
                      </div>
                    </div>
                    {active === "offline" ? (
                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Address
                        </label>
                        <Field
                          name="EventAddress"
                          type="text"
                          placeholder="Event Address Line 1"
                          component={renderField}
                          className="form-grp marginClass"
                          inputclass="input-box"
                        />
                        <Field
                          name="EventAddress2"
                          type="text"
                          placeholder="Event Address Line 2"
                          component={renderField}
                          className="form-grp"
                          inputclass="input-box"
                        />
                        <div className="inner-form-list">
                          <Field
                            name="country"
                            placeholder="Country"
                            isCountryField={true}
                            // isEditField={true}
                            setselectedCountry={setselectedCountry}
                            component={RenderSelectField}
                            mutator={form.mutators.setValue}
                            className="form-grp col-grp"
                            // inputclass="input-box select-input-box"
                            options={country}
                            isMulti={false}
                            disabled={true}
                          />
                          <Field
                            name="pincode"
                            placeholder="Zip code"
                            component={renderNumberField}
                            formatStyle={"#####"}
                            className="form-grp col-grp"
                            inputclass="input-box"
                          />

                          <Field
                            name="state"
                            type="text"
                            placeholder="State"
                            mutator={form.mutators.setValue}
                            isStateField={true}
                            setselectedState={setselectedState}
                            component={RenderSelectField}
                            className="form-grp col-grp"
                            // inputclass="input-box"
                            options={state}
                            isMulti={false}
                          />
                          <Field
                            name="city"
                            placeholder="City"
                            component={RenderCityField}
                            className="form-grp col-grp"
                            // inputclass="input-box select-input-box"
                            options={city}
                            isMulti={false}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Link
                        </label>
                        <Field
                          name="eventLink"
                          type="text"
                          placeholder="Event Link"
                          component={renderField}
                          className="form-grp"
                          inputclass="input-box"
                        />
                      </div>
                    )}
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Host Image
                      </label>
                      <div className="upload-div">
                        <ImgUpload
                          multiple={false}
                          setImages={setEventHostImages}
                          images={eventHostImages}
                          moduleName="eventHostImages"
                        />
                      </div>
                      <span className="text-danger">
                        {eventHostIMageserror}
                      </span>
                      {/* <span className='img-text'>Add multiple image up-to 10</span> */}
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Event Image{" "}
                      </label>
                      <div className="upload-div">
                        <ImgUpload
                          multiple={false}
                          setImages={setEventImages}
                          images={eventImages}
                          moduleName="eventImages"
                        />
                      </div>
                      <span className="text-danger">{eventImageserror}</span>
                      {/* <span className='img-text'>Add multiple image up-to 10</span> */}
                    </div>
                  </div>
                  <div className="d-flex flex-wrap form-btn-box position-box">
                    <Link to="/event" className="link-btn cancel">
                      Cancel
                    </Link>
                    <button type="submit" className="link-btn">
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventAdd;
