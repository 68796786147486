import {
    LOGIN_S,
    LOGIN_F,
    LOGOUT,
    STRATEGIC_PARTNER_LOGIN_S,
} from '../constants/types';

const initialState = {
    user: undefined,
    isStrategic: false

};

const authReducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_S:          
            localStorage.setItem('userData', JSON.stringify(action.payload));
            return { ...state, user: action.payload };
        case LOGIN_F:
        case LOGOUT:
            localStorage.removeItem('userData');
            return initialState;
        case STRATEGIC_PARTNER_LOGIN_S:
            localStorage.setItem('userData', JSON.stringify(action.payload));
            return { ...state, user: action.payload, isStrategic: true };
        
        default:
            return state;
    }
}

export default authReducer;