import {
  API_EVENT_ADD,
  API_EVENT_DELETED,
  API_EVENT_DETAILS,
  API_EVENT_LIST,
  API_EVENT_MEMBER_LIST,
  API_EVENT_MEMEBER_DELETE,
  API_EVENT_UPDATE,
  API_STRATEGIC_EVENT_LIST,
} from "../constants/api";
import {
  API,
  EVENT_ADD_F,
  EVENT_DETAILS_F,
  EVENT_DETAILS_S,
  EVENT_LIST_F,
  EVENT_LIST_S,
  EVENT_UPDATE_S,
  GOING_IN_EVENT_F,
  GOING_IN_EVENT_S,
  INTERESTED_IN_EVENT_F,
  INTERESTED_IN_EVENT_S,
  NOTGOING_IN_EVENT_F,
  NOTGOING_IN_EVENT_S,
} from "../constants/types";

export const eventList = (id, data) => ({
  type: API,
  payload: {
    url: API_EVENT_LIST + `${id}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_LIST_F,
      payload: data,
    }),
  },
});

export const eventAdd = (data) => ({
  type: API,
  payload: {
    url: API_EVENT_ADD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: EVENT_ADD_F,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_ADD_F,
      payload: data,
    }),
  },
});

export const eventDetail = (id, data, type) => ({
  type: API,
  payload: {
    url: API_EVENT_DETAILS + `${id}`,
    method: "POST",
    data: data,
    success: (data) => {
      if (type === 1) {
        return {
          type: INTERESTED_IN_EVENT_S,
          payload: data,
        };
      } else if (type === 2) {
        return {
          type: GOING_IN_EVENT_S,
          payload: data,
        };
      } else if (type === 3) {
        return {
          type: NOTGOING_IN_EVENT_S,
          payload: data,
        };
      } else {
        return {
          type: EVENT_DETAILS_S,
          payload: data,
        };
      }
    },
    error: (data) => {
      if (type === 1) {
        return {
          type: INTERESTED_IN_EVENT_F,
          payload: data,
        };
      } else if (type === 2) {
        return {
          type: GOING_IN_EVENT_F,
          payload: data,
        };
      } else if (type === 3) {
        return {
          type: NOTGOING_IN_EVENT_F,
          payload: data,
        };
      } else {
        return {
          type: EVENT_DETAILS_F,
          payload: data,
        };
      }
    },
  },
});

export const eventUpdate = (id, data) => ({
  type: API,
  payload: {
    url: API_EVENT_UPDATE + `${id}`,
    method: "PATCH",
    data: data,
    success: (data) => ({
      type: EVENT_UPDATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_DETAILS_F,
      payload: data,
    }),
  },
});

export const eventDelete = (id, data) => ({
  type: API,
  payload: {
    url: API_EVENT_DELETED + `${id}`,
    method: "POST",
    data: data,
  },
});

export const eventmemberlist = (id) => ({
  type: API,
  payload: {
    url: API_EVENT_MEMBER_LIST + `${id}`,
    method: "GET",
  },
});

export const eventmemberdeletee = (data) => ({
  type: API,
  payload: {
    url: API_EVENT_MEMEBER_DELETE,
    method: "DELETE",
    data: data,
  },
});

export const StrategiceventList = (id, data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_EVENT_LIST + `${id}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: EVENT_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: EVENT_LIST_F,
      payload: data,
    }),
  },
});