import React, { useEffect, useState } from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import "../tabs.scss";
import { useSelector } from "react-redux";
import Image from "../../Charityprofile/ImageVideo/image";
import Video from "../../Charityprofile/ImageVideo/video";
import Upload from "../../../svg/upload";
import ImageModal from "../../../common/modal/ImageModal";
import VideoModal from "../../../common/modal/VideoModal";

const ImageVideoStrategic = () => {
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const [selctedTab, setselctedTab] = useState(1);
  const [isModal, setisModal] = useState(false);
  const [AllImages, setAllImages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);

  const [AllVideos, setAllVideos] = useState([]);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [deletedVideoArray, setDeletedVideoArray] = useState([]);

  useEffect(() => {
    if (strategicdetail?.mediaImage?.length)
      setAllImages(strategicdetail?.mediaImage);
    if (strategicdetail?.mediaVideo?.length)
      setAllVideos(strategicdetail?.mediaVideo);
  }, [strategicdetail, setAllImages]);

  const onTabClick = (e) => {
    setselctedTab(e);
  };
  return (
    <>
      <div className="image-box">
        <Tabs>
          <TabList>
            <Tab onClick={() => onTabClick(1)}>Image</Tab>
            <Tab onClick={() => onTabClick(2)}>Video</Tab>
            <i
              title="upload"
              onClick={() => {
                setisModal(true);
              }}
            >
              {" "}
              <Upload /> Upload Media{" "}
            </i>
          </TabList>
          <TabPanel>
            <Image
              Images={strategicdetail?.mediaImage || []}
              moduleName="strategicImages"
              from="strategic"
            />
          </TabPanel>
          <TabPanel>
            <Video
              videos={strategicdetail?.mediaVideo || []}
              moduleName="strategicImages"
              from="strategic"
            />
          </TabPanel>
        </Tabs>
      </div>
      {selctedTab === 1 ? (
        <ImageModal
          title="Upload Image"
          isModal={isModal}
          setisModal={setisModal}
          AllImages={AllImages}
          setAllImages={setAllImages}
          imageFiles={imageFiles}
          setImageFiles={setImageFiles}
          multiple={true}
          moduleName={"strategicImages"}
          from="strategic"
        />
      ) : (
        <VideoModal
          title="Upload Video"
          isModal={isModal}
          setisModal={setisModal}
          AllVideos={AllVideos}
          setAllVideos={setAllVideos}
          isMultiple={false}
          moduleName={"strategicImages"}
          from="strategic"
          videoSourceURLs={videoSourceURLs}
          setVideoSourceURLs={setVideoSourceURLs}
          videoSourceFiles={videoSourceFiles}
          setVideoSourceFiles={setVideoSourceFiles}
          deletedVideoArray={deletedVideoArray}
          setDeletedVideoArray={(image) =>
            setDeletedVideoArray([...deletedVideoArray, image])
          }
          containOldImages={true}
        />
      )}
    </>
  );
};
export default ImageVideoStrategic;
