import React from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { doImageDelete, doUploadImage } from "../../../actions/registration";
import Delete from "../../svg/delete";
import "./upload.scss";

const ImgUpload = ({
  multiple,
  setImages,
  images,
  oldImages,
  setOldImages,
  setDeleteImage,
  setImg,
  moduleName,
  oldLogo,
  maxNumber,
  callUploadAPI,
}) => {
  //ALL Hooks
  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    setImages(imageList);
    const data = {
      image: [imageList[addUpdateIndex[0]]],
      moduleName: moduleName,
    };
    if (callUploadAPI) {
      if (multiple) {
        imageList.map((image) => {
          const data = { image: [image], moduleName: moduleName };
          return dispatch(doUploadImage(data)).then((res) => {
            setImg((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: metaData?.mediaURL + moduleName + "/" + res.data.name,
              },
            ]);
          });
        });
      } else {
        dispatch(doUploadImage(data)).then((res) => {
            setImg(res.data.name);

        });
      }
    }
  };

  const handleOldDelete = (val) => {
    const data = {
      fileName: val,
      moduleName: moduleName,
    };
    dispatch(doImageDelete(data));
    let filteredImages = oldImages.filter((o) => o?.name !== val);
    setOldImages(filteredImages);
  };

  return (
    <>
      <ImageUploading
        multiple={multiple}
        value={images}
        onError={(e) => {
          if (e?.maxFileSize === true) {
            toast.error("Image size should be less than 2 mb");
          } else if (e?.maxNumber === true) {
            toast.error("You can't upload more than five images");
          }
        }}
        maxNumber={maxNumber}
        maxFileSize={2000000}
        onChange={onChange}
        dataURLKey="data_url"
        acceptType={["jpg", "png", "jpeg", "jfif"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <div className="upload__image-wrapper">
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div className="img-map-box">
              {oldImages?.length && multiple ? (
                oldImages?.map((image, index) => (
                  <div key={image?.url} className="image-item">
                    <img src={image.url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="img-btn"
                        onClick={(e) => handleOldDelete(image?.name)}
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))
              ) : (
                <div></div>
              )}

              {oldLogo && !imageList.length ? (
                <div className="image-item">
                  <img src={oldLogo} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    {/* <button className='img-btn' onClick={() => handleOldDelete(oldLogo)} title="delete" ><Delete /></button>  * */}
                  </div>
                </div>
              ) : (
                ""
              )}

              {imageList?.map((image, index) => (
                <div key={image?.url} className="image-item">
                  <img src={image.data_url} alt="" width="100" />
                  <div className="image-item__btn-wrapper">
                    {/* <button className='img-btn' onClick={() => onImageRemove(index)} title="delete" ><Delete /></button> */}
                  </div>
                </div>
              ))}

              <button
                type="button"
                className="upload-btn"
                style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                {...dragProps}
              >
                +
              </button>
            </div>
          </div>
        )}
      </ImageUploading>
    </>
  );
};

export default ImgUpload;
