import {
  API_EMPLOYEE,
  API_STRATEGIC_APPROVE_EMPLOYEE,
  API_STRATEGIC_CREATE_EMPLOYEE,
  API_STRATEGIC_DASHBOARD,
  API_STRATEGIC_DAYGRAPH_DASHBOARD,
  API_STRATEGIC_DELETE_EMPLOYEE,
  API_STRATEGIC_EMPLOYEE_VERIFICATION,
  API_STRATEGIC_PENDING_EMPLOYEE,
  API_STRATEGIC_SIX_MONTH_DASHBORD,
  API_STRATEGIC_USER_UPDATE,
  API_STRATEGIC_USER_VIEW,
  API_STRATEGIC_YEARGRAPH_DASHBOARD,
} from "../constants/api";
import {
  API,
  STRATEGIC_APPROVE_EMPLOYEE_F,
  STRATEGIC_APPROVE_EMPLOYEE_S,
  STRATEGIC_CREATE_EMPLOYEE_F,
  STRATEGIC_CREATE_EMPLOYEE_S,
  STRATEGIC_DASHBOARD_F,
  STRATEGIC_DASHBOARD_S,
  STRATEGIC_DAYGRAPH_DASHBOARD_F,
  STRATEGIC_DAYGRAPH_DASHBOARD_S,
  STRATEGIC_EMPLOYEE_VERIFICATION_F,
  STRATEGIC_EMPLOYEE_VERIFICATION_S,
  STRATEGIC_PENDING_EMPLOYEE_F,
  STRATEGIC_PENDING_EMPLOYEE_S,
  STRATEGIC_SIX_MONTH_DASHBOARD_F,
  STRATEGIC_SIX_MONTH_DASHBOARD_S,
  STRATEGIC_USER_UPDATE_F,
  STRATEGIC_USER_UPDATE_S,
  STRATEGIC_USER_VIEW_F,
  STRATEGIC_USER_VIEW_S,
  STRATEGIC_YEARGRAPH_DASHBOARD_F,
  STRATEGIC_YEARGRAPH_DASHBOARD_S,
} from "../constants/types";

export const strategicPendinglisting = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_PENDING_EMPLOYEE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_PENDING_EMPLOYEE_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_PENDING_EMPLOYEE_F,
      payload: data,
    }),
  },
});

export const strategicCreateEmployee = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_CREATE_EMPLOYEE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_CREATE_EMPLOYEE_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_CREATE_EMPLOYEE_F,
      payload: data,
    }),
  },
});

export const strategicEmployeeVerification = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_EMPLOYEE_VERIFICATION,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_EMPLOYEE_VERIFICATION_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_EMPLOYEE_VERIFICATION_F,
      payload: data,
    }),
  },
});

export const strategicApproveListing = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_APPROVE_EMPLOYEE,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_APPROVE_EMPLOYEE_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_APPROVE_EMPLOYEE_F,
      payload: data,
    }),
  },
});

export const strategicDeleteEmployee = (id) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_DELETE_EMPLOYEE + id,
    method: "GET",
  },
});

export const strategicUserView = (id) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_USER_VIEW + `${id}`,
    method: "GET",
    data: id,
    success: (data) => ({
      type: STRATEGIC_USER_VIEW_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_USER_VIEW_F,
      payload: data,
    }),
  },
});

export const strategicUserUpdate = (data, id) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_USER_UPDATE + `${id}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_USER_UPDATE_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_USER_UPDATE_F,
      payload: data,
    }),
  },
});

export const strategicPartnerDashboard = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_DASHBOARD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_DASHBOARD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_DASHBOARD_F,
      payload: data,
    }),
  },
});

export const strategicDayGraphDashboard = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_DAYGRAPH_DASHBOARD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_DAYGRAPH_DASHBOARD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_DAYGRAPH_DASHBOARD_F,
      payload: data,
    }),
  },
});

export const strategicYearGraphDashboard = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_YEARGRAPH_DASHBOARD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_YEARGRAPH_DASHBOARD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_YEARGRAPH_DASHBOARD_F,
      payload: data,
    }),
  },
});

export const strategicpriviossixmonthdashbord= (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_SIX_MONTH_DASHBORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_SIX_MONTH_DASHBOARD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_SIX_MONTH_DASHBOARD_F,
      payload: data,
    }),
  },
});

export const employeeView = (id) => ({
  type: API,
  payload: {
    url: API_EMPLOYEE + `${id}`,
    method: "GET",
  },
});
