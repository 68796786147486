/* eslint-disable no-unused-vars */
import React, { useRef } from "react";
import { Field } from "react-final-form";
import Select from "react-select";
import NumberFormat from "react-number-format";
import { getVideoUrl } from "../../utils";
import { useSelector } from "react-redux";
import { addTOCity } from "../../actions/registration";
import "../../components/common/img-upload-common/upload.scss";
import Delete from "../svg/delete";
import store from "../../store";
import CreatableSelect from "react-select/creatable";

export const renderField = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  children,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <input
        type={type}
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      />
      {children}
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderNumberField = ({
  input,
  type,
  placeholder,
  formatStyle,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <NumberFormat
        {...input}
        format={formatStyle}
        className={inputclass}
        placeholder={placeholder}
        readOnly={readOnly}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const renderNumberWithCommas = ({
  input,
  placeholder,
  className,
  inputclass,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <input
        {...input}
        onChange={(e) => {
          e = e.target.value?.toString().replace(/,/g, "");
          e = e?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

          input.onChange(e);
        }}
        value={input.value}
        placeholder={placeholder}
        className={inputclass}
      />
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);
export const rendertextarea = ({
  input,
  type,
  placeholder,
  className,
  inputclass,
  readOnly,
  meta: { touched, error },
}) => (
  <>
    <div className={className}>
      <textarea
        {...input}
        readOnly={readOnly}
        className={inputclass}
        placeholder={placeholder}
      ></textarea>
      <div className="input-error">
        {touched && error && <span className="mb-0 text-danger">{error}</span>}
      </div>
    </div>
  </>
);

export const RenderSelectField = ({
  input,
  options,
  className,
  inputclass,
  isMulti,
  mutator,
  placeholder,
  isCountryField,
  setselectedCountry,
  isStateField,
  setselectedState,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={className}>
        <Select
          {...input}
          value={input.value}
          isMulti={isMulti}
          onChange={(e) => {
            if (isCountryField) {
              mutator("state", "");
              mutator("city", "");
              setselectedCountry(e?.id);
              input.onChange(e);
            } else if (isStateField) {
              mutator("city", "");
              setselectedState(e?.id);
              input.onChange(e);
            } else {
              input.onChange(e);
            }
          }}
          isDisabled={disabled}
          options={options}
          className={inputclass}
          classNamePrefix="select"
          placeholder={placeholder}
        />
        <div className="input-error">
          {touched && error && (
            <span className="mb-0 text-danger">{error}</span>
          )}
        </div>
      </div>
    </>
  );
};

export const RenderCityField = ({
  input,
  options,
  className,
  inputclass,
  isMulti,
  mutator,
  placeholder,
  isCountryField,
  setselectedCountry,
  isStateField,
  setselectedState,
  disabled,
  meta: { touched, error },
}) => {
  return (
    <>
      <div className={className}>
        <CreatableSelect
          {...input}
          value={input.value}
          isMulti={isMulti}
          onChange={(e) => {
            if (isCountryField) {
              mutator("state", "");
              mutator("city", "");
              setselectedCountry(e?.id);
              input.onChange(e);
            } else if (isStateField) {
              mutator("city", "");
              setselectedState(e?.id);
              input.onChange(e);
            } else {
              input.onChange(e);
            }
          }}
          isDisabled={disabled}
          options={options}
          className={inputclass}
          classNamePrefix="select"
          placeholder={placeholder}
        />
        <div className="input-error">
          {touched && error && (
            <span className="mb-0 text-danger">{error}</span>
          )}
        </div>
      </div>
    </>
  );
};

export const EditSelectField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <Select
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            await store.dispatch(addTOCity({ stateId: e?.value }));
            setselectedCity("");
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
    </div>
  </>
);

export const EditCityField = ({
  options,
  className,
  isMulti,
  data,
  setselectedState,
  setselectedCity,
  placeholder,
  isCountryField,
  isStateField,
  setselectedCountry,
  disabled,
}) => (
  <>
    <div className={className}>
      <CreatableSelect
        value={data}
        isMulti={isMulti}
        onChange={async (e) => {
          if (isCountryField) {
            setselectedCountry(e);
          } else if (isStateField) {
            setselectedState(e);
            await store.dispatch(addTOCity({ stateId: e?.value }));
            setselectedCity("");
          } else {
            setselectedCity(e);
          }
        }}
        isDisabled={disabled}
        options={options}
        classNamePrefix="select"
        placeholder={placeholder}
      />
    </div>
  </>
);
export const renderRadioButton = ({
  input,
  className,
  children,
  setActive,
  disabled,
  checked,
  meta: { touched, error },
}) => (
  <>
    <label className={className}>
      {children}
      <input
        {...input}
        defaultChecked
        onChange={(e) => {
          setActive(e.target.value);
          input.onChange(e);
        }}
        disabled={disabled}
      />
      <span className="checkmark"></span>
    </label>
    <div className="input-error">
      {touched && error && <span className="mb-0 text-danger">{error}</span>}
    </div>
  </>
);

export const FileField = ({ name, ...props }) => (
  <Field name={name}>
    {({ input: { value, onChange, ...input } }) => (
      <input
        {...input}
        type="file"
        onChange={({ target }) => onChange(target.files)} // instead of the default target.value
        {...props}
      />
    )}
  </Field>
);

export const renderSelect = ({
  input,
  name,
  option,
  list,
  placeholder,
  id,
  className,
  meta: { touched, error },
}) => {
  return (
    <>
      <select {...input} className={className}>
        {list.map((value, i) => (
          <option key={value?.option} value={value.option}>
            {" "}
            {value.name}{" "}
          </option>
        ))}
      </select>
      <div className="input-error">
        {touched && error && (
          <span className="mb-0 text-right text-danger">{error}</span>
        )}
      </div>
    </>
  );
};

export const RenderVideoField = ({
  videoArray,
  setVideoArray,
  oldVideos = [],
  moduleName,
  isMultiple,
  oneVideoValidation = false,
  videoSourceURLs,
  setVideoSourceURLs,
  videoSourceFiles,
  setVideoSourceFiles,
  setDeletedVideoArray,
  containOldImages,
}) => {
  const metaData = useSelector((state) => state.metaReducer);
  let ref = useRef();

  const handleFileChange = (event) => {
    let fileArray = Object.values(event.target.files) || [];
    if (fileArray.length) {
      setVideoSourceFiles(fileArray);
      fileArray.forEach((_, ind) => {
        const file = event.target.files[ind];
        const url = URL.createObjectURL(file);
        setVideoSourceURLs([...videoSourceURLs, url]);
        return null;
      });
    }
  };

  const onDelete = (val) => {
    let filteredVideos = videoArray.filter((o) => o?.name !== val);
    setVideoArray(filteredVideos);
    setDeletedVideoArray({ name: val });
  };

  const deleteLocalImageFile = (index) => {
    let filteredVideos = videoSourceFiles.filter((_, i) => i !== index);
    let filteredVideosURL = videoSourceURLs.filter((_, i) => i !== index);
    setVideoSourceFiles(filteredVideos);
    setVideoSourceURLs(filteredVideosURL);
  };

  return (
    <>
      <div className="d-flex upload__image-wrapper">
        <div className="upload-input">
          {oneVideoValidation ? (
            videoArray?.length + videoSourceFiles?.length < 1 && (
              <button
                type="button"
                className="btn btn-light upload-btn"
                onClick={() => {
                  ref.current.click();
                }}
              >
                +
              </button>
            )
          ) : (
            <button
              type="button"
              className="btn btn-light upload-btn"
              onClick={() => {
                ref.current.click();
              }}
            >
              +
            </button>
          )}
        </div>
        <div className="display-video img-map-box">
          {containOldImages && videoArray?.length
            ? videoArray.map((val, i) => {
                return (
                  <>
                    <div className="video-box">
                      <div key={val?.url} className="video-box image-item">
                        <a
                          href={`${val?.url}`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <img
                            src={getVideoUrl(
                              metaData?.mediaURL,
                              moduleName,
                              val?.thumbnail,
                              true
                            )}
                            alt={val?.name}
                            height={80}
                            width={80}
                          />
                        </a>
                      </div>
                      <div className="image-item__btn-wrapper">
                        <button
                          type="button"
                          className="delete img-btn"
                          onClick={() => {
                            onDelete(val?.name);
                          }}
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            : ""}
          {videoSourceURLs?.length
            ? videoSourceURLs.map((url, ind) => {
                return (
                  <>
                    <div className="video-box">
                      <div key={url} className="video-box image-item">
                        <a href={`${url}`} target={"_blank"} rel="noreferrer">
                          <video
                            key={url}
                            src={url}
                            style={{
                              width: "90px",
                              height: "90px",
                              objectFit: "cover",
                            }}
                          />
                        </a>
                      </div>
                      <div className="image-item__btn-wrapper">
                        <button
                          type="button"
                          className="delete img-btn"
                          onClick={() => deleteLocalImageFile(ind)}
                        >
                          <Delete />
                        </button>
                      </div>
                    </div>
                  </>
                );
              })
            : null}
        </div>
      </div>
      <input
        style={{ display: "none" }}
        ref={ref}
        multiple={isMultiple}
        onChange={(e) => handleFileChange(e)}
        type={"file"}
        accept="video/*"
      />
    </>
  );
};
