import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import { Link } from "react-router-dom";
import View from "../../../components/svg/view";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { debounce } from "../../../utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "./transaction.scss";
import {
  strategictransactionList,
  strategictransactionListExportExcel,
} from "../../../actions/transaction";

const list = [
  {
    name: "Transaction Mangement",
    link: "/s_transaction",
    isactive: false,
  },
  {
    name: "Transaction List",
    link: "/transaction",
    isactive: true,
  },
];

const customStyles = {
  headCells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
  cells: {
    style: {
      paddingTop: "8px",
      paddingBottom: "8px",
    },
  },
};

const Transaction = () => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const metaData = useSelector((state) => state.metaReducer);
  const List = useSelector(
    (state) => state.TransactionReducer.strategictransaction.transactionList
  );
  const dispatch = useDispatch();

  const [totalRecords, settotalRecords] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    search: "",
    column: "",
    order: "",
    activeFrom: startDate || null,
    activeTo: endDate || null,
    StrategicPartnerId:Change?._id
  });

  useEffect(() => {
    dispatch(strategictransactionList(arg)).then((res) => {
      settotalRecords(res?.data?.total_records || 0);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arg]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  const onSortChange = (column, direction) => {
    
    setArg({ ...arg, column: column?.name, order: direction });
  };

  const columns = [
    {
      name: "Employee Name",
      selector: (row) => row.firstName + '  ' + row.lastName,
      
    },
    {
      name: "Email Id",
      selector: (row) => row.email,
      minWidth: "100%",
      width: "300px",
    },
    {
      name: "Date Of Transaction",
      selector: (row) =>
        moment(row.createdDate).local().format("MMM D YYYY,h:mm a"),
      minWidth: "100%",
      width: "200px",
    },
    {
      name: "Amount",
      selector: (row) => (
        <div className="status-box">
          <span className="amount">
            $ {row.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
          </span>
        </div>
      ),
      minWidth: "100%",
      width: "200px",
    },
    
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-box">
          <Link
            to={`s_transaction/s_transaction_view/${row._id}`}
            className="btn right"
            title="view"
          >
            {" "}
            <View />{" "}
          </Link>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const handleDateChange = async (date) => {
    if (date) {
      setEndDate(new Date(moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss")));
    setArg({ ...arg, activeTo: new Date(moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss")) });
    } else {
      setEndDate(null)
      setArg({...arg,activeTo:null})
    }
    
  };
  const handlelist = () => {
    const data = {
      activeFrom: startDate ? moment(startDate).startOf("day").format("MM/DD/YYYY HH:mm:ss") : null,
      activeTo: endDate ?moment(endDate).endOf("day").format("MM/DD/YYYY HH:mm:ss") : null
    }
    dispatch(strategictransactionListExportExcel(Change?._id,data)).then((res) =>
      window.open(metaData?.mediaURL + res.data.URL)
    );
  };

  return (
    <>
      <div className="transaction-section padding-area">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Transaction Management </h2>
        </div>
        <div className="section-inner">
          <div className="d-flex w-100">
            {/* <div className="div-btn-box align-items-end w-100 justify-content-between">
            </div> */}
            <div className="transWrapperHeader div-btn-box">
              <div className="search-box">
                <input
                  type="text"
                  placeholder="Search Employee Name"
                  className="search-input"
                  onChange={(e) => optimizedFn(e.target.value)}
                />
              </div>
              <div className="transDate">
                <div>
                  <label className="date-lable">From:</label>
                  <DatePicker
                    placeholderText="Start Date"
                    selected={startDate}
                    onChange={(date) => {
                      if (date) {
                        setStartDate(new Date(moment(date).startOf("day").format("MM/DD/YYYY HH:mm:ss")));
                      setArg({
                        ...arg,
                        activeFrom: moment(date).startOf("day").format("MM/DD/YYYY HH:mm:ss"),
                      });
                      } else {
                        setStartDate(null)
                        setArg({...arg,activeFrom: null})
                      }
                      
                    }}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={new Date(moment())}
                    className="search-input"
                  />
                </div>
                <span style={{ margin: "0 10px", paddingTop: "15px" }}> </span>

                <div>
                  <label className="date-lable">To:</label>
                  <DatePicker
                    placeholderText="End Date"
                    selected={endDate}
                    onChange={handleDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date(moment())}
                    className="search-input"
                  />
                </div>
              </div>
              <button
                type="button"
                className="btn export"
                title="export"
                onClick={handlelist}
              >
                {" "}
                Export Excel{" "}
              </button>
              {/* <Link to="/community/community_add" className='btn' title='add' > <Plus /> Add New community  </Link> */}
            </div>
          </div>
          <DataTable
            border
            columns={columns}
            data={List}
            onSort={onSortChange}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
          />
        </div>
      </div>
    </>
  );
};

export default Transaction;
