import React from "react";
import "./strategicprofile.scss";
import ProfileView from "./view";



const Strategicprofile = () => {

  return (
    <>
      <div>
        <ProfileView />
      </div>
    </>
  )
}

export default Strategicprofile;