import { COUNTRY_F, COUNTRY_S, CREATE_REGISTER_F, CREATE_REGISTER_S } from "../constants/types";


const initialState = {
  country:{},
  strategicRegistration:{}
};
const RegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    
   case COUNTRY_S:
    return{
      ...state ,
      country:action.payload.data
    }
    case COUNTRY_F :
    return{
      ...state ,
      country:action.payload.message
    }
    case CREATE_REGISTER_S:
      return{
        ...state,
        strategicRegistration:action.payload.data
      }
      case CREATE_REGISTER_F:
      return{
        ...state,
        strategicRegistration:action.payload.message
      }
    default:
      return state;
  }
};

export default RegistrationReducer;
