import { combineReducers } from 'redux';

import api from './api';
import auth from './auth';
import ChairtyReducer from './charity';
import RegistrationReducer from './Registration'
import CmsReducer from './cms';
import metaReducer from './meta';
import CommunityReducer from './community'
import EventReducer from './event'
import PostReducer from './post';
import PaymentReducer from './payment'
import StrategicReducer from './strategic';
import TransactionReducer from './transaction'
import ContentReducer from './content'
import DashboardReducer from './dashboard'
import chatReducer from './chat'
import SubscribeDonorReducer from './subscribe'
import NotifictionReducer from './notification'
const rootReducer = combineReducers({
    api,
    auth,
    RegistrationReducer,
    ChairtyReducer,
    CmsReducer,
    metaReducer,
    CommunityReducer,
    EventReducer,
    PostReducer,
    PaymentReducer,
    StrategicReducer,
    TransactionReducer,
    ContentReducer,
    DashboardReducer,
    chat: chatReducer,
    SubscribeDonorReducer,
    NotifictionReducer

})

export default rootReducer;