import React, { lazy, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthGuard from "../components/auth";
import ChangePassword from "../pages/ChangePassword";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import { useDispatch } from "react-redux";
import { storeMetaData } from "../actions/meta";
import Event from "../pages/Event";
import EventAdd from "../pages/Event/add";
import EventEdit from "../pages/Event/edit";
import EventView from "../pages/Event/view";
import SubscribedDonor from "../pages/SubscribedDonor";
import Register from "../pages/StrategicPanel/SignUp";
import Chat from "../pages/Chat";
import STransaction from "../pages/StrategicPanel/Transaction";
import Strategicprofile from "../pages/StrategicPanel/Strategicprofile";
import SProfileEdit from "../pages/StrategicPanel/Strategicprofile/edit";
import CommunityTransaction from "../pages/Community Transaction";
import TransactionView from "../pages/StrategicPanel/Transaction/view";

const Layout = lazy(() => import("../components/layout"));
const SignIn = lazy(() => import("../pages/SignIn"));
const Dashboard = lazy(() => import("../pages/Dashboard"));

const Community = lazy(() => import("../pages/Community"));
const CommunityAdd = lazy(() => import("../pages/Community/add"));
const CommunityView = lazy(() => import("../pages/Community/view"));
const CommunityEdit = lazy(() => import("../pages/Community/edit"));

const Policy = lazy(() => import("../pages/Content/policy"));
const Terms = lazy(() => import("../pages/Content/terms"));

const CharityProfile = lazy(() => import("../pages/Charityprofile"));
const ProfileEdit = lazy(() => import("../pages/Charityprofile/edit"));
const ProfileView = lazy(() => import("../pages/Charityprofile/view"));

const PaymentEdit = lazy(() => import("../pages/Payment/edit"));

const Transaction = lazy(() => import("../pages/Transaction"));

const Contact = lazy(() => import("../pages/Content/ContactUs"));

const FAQ = lazy(() => import("../pages/Content/FAQ"));

const Notification = lazy(() => import("../pages/Notification"));

const Employee = lazy(() => import("../pages/StrategicPanel/Employee"));
const EmployeeView = lazy(() =>
  import("../pages/StrategicPanel/Employee/view")
);

const SContact = lazy(() =>
  import("../pages/StrategicPanel/Content/ContactUs")
);
const SFAQ = lazy(() => import("../pages/StrategicPanel/Content/FAQ"));
const SPolicy = lazy(() => import("../pages/StrategicPanel/Content/policy"));
const STerms = lazy(() => import("../pages/StrategicPanel/Content/terms"));

const StrategicDashboard = lazy(() =>
  import("../pages/StrategicPanel/Dashboard")
);

const Routing = () => {
  const userTypeData = JSON.parse(localStorage.getItem("userType"));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(storeMetaData());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Routes>
      <Route path="/signin" element={<SignIn />} />
      <Route path="/charity-signin" element={<SignIn charityAdmin={true} />} />
      <Route path="/strategic_signin" element={<SignIn isStrategic={true} />} />

      <Route path="/forgot_password" element={<ForgotPassword />} />
      <Route
        path="/strategic_forgot_password"
        element={<ForgotPassword isStrategic={true} />}
      />

      <Route path="/change_password" element={<ChangePassword />} />
      <Route
        path="/strategic_change_password"
        element={<ChangePassword isStrategic={true} />}
      />

      <Route path="/signup" element={<Register />} />
      <Route path="/reset-password/:token" element={<ResetPassword />} />
      <Route
        path="/strategic_reset_password/:token"
        element={<ResetPassword isStrategic={true} />}
      />

      <Route
        path="/"
        element={
          <AuthGuard>
            <Layout />
          </AuthGuard>
        }
      >
        <Route index path="/dashboard" element={<Dashboard />} />

        {/* Community */}
        <Route index path="/community" element={<Community />} />
        <Route
          index
          path="/community/community_add"
          element={<CommunityAdd />}
        />
        <Route
          index
          path="/community/community_edit/:id"
          element={<CommunityEdit />}
        />
        <Route
          index
          path="/community/Community_view/:id"
          element={<CommunityView />}
        />

        {/* Content */}
        <Route index path="/community/privacy_policy" element={<Policy />} />
        <Route index path="/community/Terms_condition" element={<Terms />} />

        {/* Charityprofile */}
        <Route index path="/charity_profile" element={<CharityProfile />} />
        <Route index path="/charity_profile/edit" element={<ProfileEdit />} />
        <Route index path="/charity_profile/view" element={<ProfileView />} />

        {/* Event Mangement */}
        <Route index path="/event" element={<Event />} />
        <Route index path="/event/event_add" element={<EventAdd />} />
        <Route index path="/event/event_edit/:id" element={<EventEdit />} />
        <Route index path="/event/event_view/:id" element={<EventView />} />

        {/* Transaction */}
        <Route index path="/transaction" element={<Transaction />} />
        <Route
          index
          path="/communitytransaction"
          element={<CommunityTransaction />}
        />

        {/* Contact */}
        <Route index path="/contact_us" element={<Contact />} />

        {/* Contact */}
        <Route index path="/faq" element={<FAQ />} />

        {/* Notification */}
        <Route index path="/notification" element={<Notification />} />

        {/* Subscribed Donor */}
        <Route index path="/subscribed_donor" element={<SubscribedDonor />} />

        {/* payment */}
        <Route index path="/payment/payment_edit" element={<PaymentEdit />} />

        {/* Strategic partner Panel */}

        <Route
          index
          path="/strategicDashboard"
          element={<StrategicDashboard />}
        />

        {/* Employee */}
        <Route index path="/employee" element={<Employee />} />
        <Route
          index
          path="/employee/employee_view/:id"
          element={<EmployeeView />}
        />

        {/* Content */}
        <Route
          index
          path="/content/strategic_contact_us"
          element={<SContact />}
        />
        <Route index path="/content/strategic_faq" element={<SFAQ />} />
        <Route
          index
          path="/content/strategic_privacy_policy"
          element={<SPolicy />}
        />
        <Route
          index
          path="/content/strategic_Terms_condition"
          element={<STerms />}
        />

        {/* chat */}
        <Route index path="/message" element={<Chat />} />

        {/* Transaction */}
        <Route index path="/s_transaction" element={<STransaction />} />
        <Route index path= 's_transaction/s_transaction_view/:id' element ={<TransactionView/>}/>

        <Route index path="/strategic_profile" element={<Strategicprofile />} />
        <Route
          index
          path="/strategic_profile/edit"
          element={<SProfileEdit />}
        />

        {/* chat */}
        <Route index path="/chat" element={<Chat />} />

        {userTypeData === 1 ? (
          <Route path="/" element={<Navigate replace to="/dashboard" />} />
        ) : (
          <Route
            path="/"
            element={<Navigate replace to="/strategicDashboard" />}
          />
        )}
      </Route>
      <Route path="*" element={<Navigate replace to="/signin" />} />
    </Routes>
  );
};

export default Routing;
