import React, { useRef } from "react";
import { Field, Form } from "react-final-form";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  communityDelete,
  communityDetail,
  communityhideunhide,
  communityList,
} from "../../../actions/community";
import {
  eventDelete,
  eventDetail,
  eventList,
  eventmemberdeletee,
  StrategiceventList,
} from "../../../actions/event";
import {
  employeePostList,
  postDelete,
  postList,
  strategicPostList,
} from "../../../actions/post";
import {
  strategicEmployeeVerification,
  strategicPendinglisting,
  strategicCreateEmployee,
  strategicDeleteEmployee,
  strategicApproveListing,
} from "../../../actions/strategic";
import { renderField, rendertextarea } from "../../forms";
import "./delete.scss";

const Dmodel = ({
  name,
  smallText,
  setmodel,
  ismodel,
  id,
  arg,
  from,
  isMyPost,
  employeeId,
}) => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const isStrategic = useSelector((state) => state.auth?.isStrategic);
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.communityDetails
  );
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const dispatch = useDispatch();
  const formRef = useRef();

  const onSubmit = (value) => {
    switch (from) {
      case "community":
        dispatch(communityDelete(id))
          .then((res) => {
            dispatch(communityList(Change?._id, arg));
            if (arg?.status) {
              toast.success("Community deleted Successfully");
            } else {
              toast.success("Community Restore Successfully");
            }
          })
          .catch((res) => toast.error(res.message));
        setmodel(false);
        break;
      case "event":
        const data = {
          id: id,
        };
        dispatch(eventDelete(id, data))
          .then((res) => {
            if (isStrategic) {
              dispatch(StrategiceventList(Change?._id, arg));
            } else {
              dispatch(eventList(Change?._id, arg));
            }
            if (arg?.status) {
              toast.success("Event deleted Successfully");
            } else {
              toast.success("Event Restore Successfully");
            }
          })
          .catch((res) => toast.error(res.message));
        setmodel(false);
        break;
      case "post":
        dispatch(postDelete(id))
          .then((res) => {
            const data = {
              page: 1,
              limit: 100,
            };
            dispatch(postList(communitydetail?._id, data, isMyPost));
            toast.success(res.message);
            setmodel(false);
          })
          .catch((res) => toast.error(res.message));

        break;

      case "strategicPost":
        dispatch(postDelete(id)).then((res) => {
          dispatch(employeePostList(employeeId, { page: 1, limit: 1000000 }));
          dispatch(
            strategicPostList(
              strategicdetail?._id,
              { page: 1, limit: 100 },
              !isMyPost
            )
          );
          toast.success(res.message);
          setmodel(false);
        });
        break;
      case "memeber":
        dispatch(eventmemberdeletee({ _id: id }))
          .then((res) => {
            setmodel(false);
            if (arg?.status === "Interested") {
              dispatch(eventDetail(arg?._id, arg, 1));
            } else if (arg?.status === "Going") {
              dispatch(eventDetail(arg?._id, arg, 2));
            } else if (arg?.status === "Notgoing") {
              dispatch(eventDetail(arg?._id, arg, 3));
            }
            toast.success(res.message);
          })
          .catch((res) => {
            toast.error(res.message);
          });

        break;
      case "rejectEmployee":
        const datas = {
          employeeId: id,
          status: "Reject",
          rejectReason: value.reject,
        };
        dispatch(strategicEmployeeVerification(datas))
          .then((res) => {
            if (formRef?.current?.reset) {
              formRef.current?.reset();
            }
            dispatch(strategicPendinglisting(arg));
          })
          .catch((err) => {
            if (formRef?.current?.reset) {
              formRef.current?.reset();
            }
          });
        setmodel(false);

        break;

      case "addEmployee":
        const addStrategicEmployee = {
          strategicPartnerId: Change?._id,
          workEmail: value.textfield,
        };
        dispatch(strategicCreateEmployee(addStrategicEmployee))
          .then((res) => {
            if (formRef?.current?.reset) {
              formRef.current?.reset();
            }
            dispatch(strategicPendinglisting(arg));
            toast.success(res.message);
          })
          .catch((err) => {
            if (formRef?.current?.reset) {
              formRef.current?.reset();
            }
            toast.error(err.message);
          });
        setmodel(false);

        break;
      case "deleteEmployee":
        dispatch(strategicDeleteEmployee(id)).then((res) => {
          toast.success(res.message);
          dispatch(strategicApproveListing(arg))
            .then((res) => {
              //   settotalRecords(res?.data?.total_records || 0)
            })
            .catch((err) => toast.error(err.message));
          setmodel(false);
        });
        break;
      case "hidecommunity":
        dispatch(communityhideunhide(id))
          .then((res) => {
            dispatch(communityDetail(id));
            toast.success(res.message);
            setmodel(false);
          })
          .catch((res) => toast.error(res.message));
        break;
      default:
        break;
    }
  };

  const required = (value) => {
    if (!value) {
      return "Please Enter Email Address";
    } else if (value && !value.trim()) {
      return "Please Enter Valid Email Address.";
    } else if (
      !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-z\-0-9]+\.)+[a-z]{2,}))$/i.test(
        value
      )
    ) {
      return "Please Enter Valid Email Address.";
    }
  };

  const rejectrequire = (value) => {
    if (!value) {
      return "Please Enter Reason";
    } else if (value && !value.trim()) {
      return "Please Enter Valid Reason.";
    }
  };

  const renderConditionalField = () => {
    return (
      <>
        {from === "addEmployee" ? (
          <>
            <h2>{name}</h2>
            <h4>Associate Email known to Giving Partner </h4>
            <Field
              name="textfield"
              component={renderField}
              placeholder={"Enter work Email of Associate"}
              validate={required}
              type="text"
              className="form-grp"
              inputclass="input-box"
            />
          </>
        ) : (
          <>
            <h2>{name} ?</h2>
            <p>{smallText}</p>
          </>
        )}
      </>
    );
  };

  const conditionalModalBtnJsx = () => {
    return (
      <>
        {from === "addEmployee" ? (
          <>
            <button
              type="button"
              className="btn-box no"
              onClick={() => {
                setmodel(false);
                formRef?.current?.restart();
              }}
            >
              Cancel
            </button>
            <button type="submit" className="btn-box">
              Add
            </button>
          </>
        ) : (
          <>
            <button
              type="button"
              className="btn-box no"
              onClick={() => setmodel(false)}
            >
              No
            </button>
            <button type="submit" className="btn-box">
              Yes
            </button>
          </>
        )}
      </>
    );
  };

  return (
    <>
      <div className={`model-area ${ismodel ? "open-model" : ""}`}>
        <div className="model-box">
          <div className="modele-text-box">
            <Form onSubmit={onSubmit}>
              {({ handleSubmit, form }) => {
                formRef.current = form;
                return (
                  <form onSubmit={handleSubmit}>
                    {from === "rejectEmployee" ? (
                      <>
                        <h2>{name}</h2>
                        <h4>Reason for Reject </h4>
                        <Field
                          name="reject"
                          component={rendertextarea}
                          type="text"
                          validate={rejectrequire}
                          className="form-grp"
                          inputclass={"input-box text-area-box"}
                        />
                      </>
                    ) : (
                      renderConditionalField()
                    )}

                    <ul className="input-list">
                      <li>
                        <div className="div-checkbox">
                          <div className="inner-checkbox"></div>
                        </div>
                      </li>
                    </ul>
                    <div className="model-btn">
                      {from === "rejectEmployee" ? (
                        <>
                          <button
                            type="button"
                            className="btn-box no"
                            onClick={() => setmodel(false)}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn-box">
                            Reject
                          </button>
                        </>
                      ) : (
                        conditionalModalBtnJsx()
                      )}
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dmodel;
