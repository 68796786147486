import {
  API_CHANGE_PASSWORD,
  API_FORGOT_PASSWORD,
  API_LOGIN,
  API_LOGOUT,
  API_REFRESH_TOKEN,
  API_RESET_PASSWORD,
  API_STRATEGIC_CHANGE_PASSWORD,
  API_STRATEGIC_FORGOT_PASSWORD,
  API_STRATEGIC_PARTNER_LOGIN,
  API_STRATEGIC_RESET_PASSWORD,
  API_SUPER_ADMIN_LOGIN,
} from "../constants/api";
import {
  API,
  LOGIN_S,
  LOGIN_F,
  LOGOUT,
  FORGOT_PASSWORD_S,
  FORGOT_PASSWORD_F,
  RESET_PASSWORD_S,
  RESET_PASSWORD_F,
  STRATEGIC_PARTNER_LOGIN_S,
  STRATEGIC_PARTNER_LOGIN_F,
  STRATEGIC_FORGOT_PASSWORD_S,
  STRATEGIC_FORGOT_PASSWORD_F,
  STRATEGIC_RESET_PASSWORD_S,
  STRATEGIC_RESET_PASSWORD_F,
} from "../constants/types";


export const doLogin = (data) => ({
  type: API,
  payload: {
    url: API_LOGIN,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});

export const doSuperAdminLogin = (data) => ({
  type: API,
  payload: {
    url: API_SUPER_ADMIN_LOGIN,
    method: "POST",
    data: data,
    success: (data) => ({
      type: LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: LOGIN_F,
      payload: data,
    }),
  },
});


export const doLogout = () =>{
  let user = JSON.parse(localStorage.getItem("userData"));
  const userType = JSON.parse(localStorage.getItem("userType"));
  return ({
  type: API,
  payload: {
    url: API_LOGOUT,
    method: "POST",
    data: {_id:user?.data?._id,type: userType === 1 ? "charity" :"givingPartner"},
    success: (data) => ({
      type: LOGOUT,
      payload: data,
    }),
    error: (data) => ({
      type: LOGOUT,
      payload: data,
    }),
  },
})};

export const changePassword = (data, showLoader = false) => ({
  type: API,
  payload: {
    url: API_CHANGE_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => doLogout(),
    showLoader,
  },
});

export const charityForgotPassword = (data) => ({
  type: API,
  payload: {
    url: API_FORGOT_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: FORGOT_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: FORGOT_PASSWORD_F,
      payload: data,
    }),
  },
});

export const chairtyResetPassword = (data) => ({
  type: API,
  payload: {
    url: API_RESET_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: RESET_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: RESET_PASSWORD_F,
      payload: data,
    }),
  },
});
export const Remembermereferesh = (data) => ({
  type: API,
  payload: {
    istokan:false,
    url: API_REFRESH_TOKEN,
    method: "POST",
    data: data,
  },
});

export const strategicLogin = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_PARTNER_LOGIN,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_PARTNER_LOGIN_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_PARTNER_LOGIN_F,
      payload: data,
    }),
  },
});

export const strategicForgotPassword = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_FORGOT_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_FORGOT_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_FORGOT_PASSWORD_F,
      payload: data,
    }),
  },
});

export const strategicResetPassword = (data) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_RESET_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => ({
      type: STRATEGIC_RESET_PASSWORD_S,
      payload: data,
    }),
    error: (data) => ({
      type: STRATEGIC_RESET_PASSWORD_F,
      payload: data,
    }),
  },
});

export const strategicChangePassword = (data,showLoader = false) => ({
  type: API,
  payload: {
    url: API_STRATEGIC_CHANGE_PASSWORD,
    method: "POST",
    data: data,
    success: (data) => doLogout(),
    showLoader,
  },
});
