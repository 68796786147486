import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Charitydetail, EditCharitydetail } from "../../../actions/charity";
import {
  communityDetail,
  EditCommunityDetail,
} from "../../../actions/community";
import { doUploadVideo } from "../../../actions/registration";
import {
  strategicUserUpdate,
  strategicUserView,
} from "../../../actions/strategic";
import { getVideoUrl } from "../../../utils";
import { RenderVideoField } from "../../forms";

const VideoModal = ({
  title = "",
  isModal,
  setisModal,
  AllVideos = [],
  setAllVideos,
  isMultiple,
  moduleName,
  from,
  videoSourceURLs,
  setVideoSourceURLs,
  videoSourceFiles,
  setVideoSourceFiles,
  deletedVideoArray,
  setDeletedVideoArray,
  containOldImages,
}) => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const charitydetail = useSelector(
    (state) => state.ChairtyReducer.chairtyDetail?.charityList
  );
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.communityDetails
  );
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const uploadVideoFiles = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName,
          video: file,
        })
      )
    );
  };

  const onSave = (e) => {
    e.preventDefault();
    const videoPayload = [...AllVideos];
    Promise.allSettled(uploadVideoFiles()).then((res) => {
      res.forEach((value) => {
        if (value.status === "fulfilled") {
          videoPayload.push({
            name: value.value?.data?.name,
            url: getVideoUrl(
              metaData.mediaURL,
              moduleName,
              value.value?.data?.name
            ),
            thumbnail: value.value?.data.thumbnail,
          });
        }
        return null;
      });
      onSaveHandler(videoPayload, deletedVideoArray);
    });
  };

  const onSaveHandler = (videos, deletedVideos) => {
    switch (from) {
      case "charity":
        if (AllVideos?.length + videos.length === 0) {
          return toast.error("Please attach Video");
        }
        const datacharity = {
          charityId: Change?._id,
          relatedCause: [],
          categoryId: charitydetail?.charityDetails?.categoryId,
          mediaVideo: videos,
        };
        dispatch(EditCharitydetail(Change?._id, datacharity))
          .then((res) => {
            setisModal(false);
            setVideoSourceFiles([]);
            setVideoSourceURLs([]);
            dispatch(Charitydetail(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "strategic":
        if (AllVideos?.length + videos.length === 0) {
          return toast.error("Please attach Video");
        }

        const dataStrategic = {
          strategicPartnerId: Change?._id,
          mediaVideo: videos,
        };
        const allStrategicData = { ...strategicdetail, ...dataStrategic };
        dispatch(strategicUserUpdate(allStrategicData, Change?._id))
          .then((res) => {
            setisModal(false);
            setVideoSourceFiles([]);
            setVideoSourceURLs([]);
            dispatch(strategicUserView(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "commmunity":
        if (AllVideos?.length + videos.length === 0) {
          return toast.error("Please attach Video");
        }
        const datacommunity = {
          _id: communitydetail?._id,
          charityId: Change?._id,
          mediaVideo: videos,
        };
        dispatch(EditCommunityDetail(datacommunity))
          .then((res) => {
            setisModal(false);
            setVideoSourceFiles([]);
            setVideoSourceURLs([]);
            dispatch(communityDetail(communitydetail?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;
      default:
        break;
    }
  };

  const onClose = (e) => {
    e.preventDefault();
    setisModal(false);
    setVideoSourceFiles([]);
    setVideoSourceURLs([]);
    switch (from) {
      case "charity":
        setAllVideos(charitydetail?.charityDetails?.mediaVideo);
        break;
      case "commmunity":
        setAllVideos(communitydetail?.mediaVideo);
        break;
      case "strategic":
        setAllVideos(strategicdetail?.mediaVideo);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* upload image and video  */}
      <div className={`model-area upload-model ${isModal ? "open-model" : ""}`}>
        <div className="model-box">
          <div className="modele-text-box">
            <h2>{title}</h2>
            <div className="form-area">
              <form>
                <div className="input-list d-flex flex-wrap">
                  <div className="col-12 col-div">
                    <label className="label-text">
                      {" "}
                      <span>*</span>
                      {title}{" "}
                    </label>
                    <RenderVideoField
                      videoArray={AllVideos}
                      setVideoArray={setAllVideos}
                      moduleName={moduleName}
                      isMultiple={isMultiple}
                      videoSourceURLs={videoSourceURLs}
                      setVideoSourceURLs={setVideoSourceURLs}
                      videoSourceFiles={videoSourceFiles}
                      setVideoSourceFiles={setVideoSourceFiles}
                      setDeletedVideoArray={setDeletedVideoArray}
                      containOldImages={false}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap form-btn-box">
                  <button type="button" onClick={onSave} className="link-btn">
                    Save
                  </button>
                  <Link to="/" onClick={onClose} className="link-btn cancel">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default VideoModal;
