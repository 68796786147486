import { EMPLOYEE_POST_LIST_F, EMPLOYEE_POST_LIST_S, POST_COMMENT_LIST_F, POST_COMMENT_LIST_S, POST_LIST_F, POST_LIST_S, STRATEGIC_POST_LIST_F, STRATEGIC_POST_LIST_S } from "../constants/types";

const initialState = {
    postList: [],
    comment: [],
    strategicPostList: [],
    employeePostList :[]
};

const PostReducer = (state = initialState, action) => {
    switch (action.type) {
        case POST_LIST_S:
            return {
                ...state, postList: action.payload.data
            }
        case POST_LIST_F:
            return {
                ...state, postList: action.payload.message
            }
        case POST_COMMENT_LIST_S:
            return {
                ...state, comment: action.payload.data
            }
        case POST_COMMENT_LIST_F:
            return {
                ...state, comment: action.payload.message
            }
        case STRATEGIC_POST_LIST_S:
            return {
                ...state, strategicPostList: action.payload.data
            }
        case STRATEGIC_POST_LIST_F:
            return {
                ...state, strategicPostList: action.payload.message
            }
            case EMPLOYEE_POST_LIST_S:
            return {
                ...state, employeePostList: action.payload.data
            }
        case EMPLOYEE_POST_LIST_F:
            return {
                ...state, employeePostList: action.payload.message
            }
        default:
            return state;
    }
}
export default PostReducer