/* eslint-disable eqeqeq */
import { doLogout, Remembermereferesh } from "../actions/auth";
import { useEffect } from "react";
import { LOGIN_S, STRATEGIC_PARTNER_LOGIN_S } from "../constants/types";
import moment from "moment";

//To concate the path for the public folder
export const toAbsoluteUrl = (pathname) => process.env.PUBLIC_URL + pathname;

// outside click
export const OutSideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export const setupAxios = (axios, store) => {
  let token = JSON.parse(localStorage.getItem("userData"));
  const userType = JSON.parse(localStorage.getItem("userType"));

  let isRefreshing = false;
  if (token) {
    axios.defaults.headers.common["Authorization"] = token?.data?.authToken;
    if (token.data && userType == "1") {
      store.dispatch({ type: LOGIN_S, payload: token });
    }
    if (token.data && userType == "2") {
      store.dispatch({ type: STRATEGIC_PARTNER_LOGIN_S, payload: token });
    }
  } else {
    delete axios.defaults.headers.common["Authorization"];
  }

  axios.interceptors.response.use(null, (err) => {
    if (err.response) {
      if (
        JSON.parse(localStorage.getItem("userData"))?.data?.authToken &&
        err.response.status === 403 &&
        !isRefreshing
      ) {
        isRefreshing = true;
        store
          .dispatch(
            Remembermereferesh({
              token: JSON.parse(localStorage.getItem("userData"))?.data
                ?.refreshToken,
            })
          )
          .then((res) => {
            isRefreshing = false;
            let newdata = {
              ...JSON.parse(localStorage.getItem("userData")),
              data: {
                ...JSON.parse(localStorage.getItem("userData"))?.data,
                authToken: res.data?.authToken,
              },
            };
            localStorage.setItem("userData", JSON.stringify(newdata));
            // window.location.reload()
          })
          .catch((res) => {
            isRefreshing = false;
            // store.dispatch(doLogout())
          });
        return;
      } else if (err.response.status === 401) {
        window.location.reload();
        store.dispatch(doLogout());
        return Promise.reject(err);
      } else if (
        !token?.data?.authToken &&
        err.response.status === 403 &&
        !isRefreshing
      ) {
        if (token && userType == "1") {
          // store.dispatch(doLogout())
        }
        if (token && userType == "2") {
          // store.dispatch(strategicLogout())
        }
        return Promise.reject(err);
      } else return Promise.reject(err);
    } else if (err.request) {
      return Promise.reject({
        response: {
          data: {
            message: "Something went wrong, Please try again later!!!",
          },
        },
      });
    }
  });
};
export const loadVideo = (file) =>
  new Promise((resolve, reject) => {
    try {
      let video = document.createElement("video");
      video.preload = "metadata";

      video.onloadedmetadata = function () {
        resolve(this);
      };

      video.onerror = function () {
        reject("Invalid video. Please select a video file.");
      };

      video.src = window.URL.createObjectURL(file);
    } catch (e) {
      reject(e);
    }
  });

export const debounce = (func) => {
  let timer;
  return function (...args) {
    const context = this;
    if (timer) clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      func.apply(context, args);
    }, 700);
  };
};

export const getVideoUrl = (
  baseurl,
  moduleName,
  fileName,
  isThumbnail = false
) => {
  if (!isThumbnail) return `${baseurl}${moduleName}/videos/${fileName}`;
  else return `${baseurl}${moduleName}/videos/thumbnail/${fileName}`;
};

export const mergeDateTime = (date, time) => {
  if (date && time?.length) {
    let timeArray = time.split(":");
    if (timeArray?.length === 2) {
      return moment(date)
        .set("hour", timeArray[0])
        .set("minute", timeArray[1])
        .utc()
        .format();
    } else {
      return "";
    }
  } else {
    return "";
  }
};
export const scrolltoBottomFun = (wrapperId) => {
  let chatWrapper = document.getElementById(wrapperId);
  if (chatWrapper) {
    chatWrapper.scrollTo(0, chatWrapper.scrollHeight);
  }
};
export const dateFormatChat = (date) => {
  if (date) return moment(date).format("hh:mm A");
  else return "-";
};
export const getImageUrl = (baseurl, moduleName, fileName) => {
  if (baseurl && fileName) return `${baseurl}${moduleName}/${fileName}`;
  else return ``;
};
export const taxIdFormater = (taxId) => {
  return taxId.slice(0, 2) + "-" + taxId.slice(2);
};
export const formatPhoneNumber = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    return "(" + match[1] + ") " + match[2] + "-" + match[3];
  }
  return null;
};

export const amountFormate = (totalamount) => {
  if (!totalamount) return 0;
  return totalamount?.toString().includes(".")
    ? parseFloat(totalamount)
        ?.toFixed(2)
        ?.toString()
        ?.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    : totalamount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
