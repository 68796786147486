import { COMMUNITY_TRANSCTION_LIST_F, COMMUNITY_TRANSCTION_LIST_S, STRATEGIC_TRANSACTION_LIST_F, STRATEGIC_TRANSACTION_LIST_S, TRANSACTION_DETAIL_F, TRANSACTION_DETAIL_S, TRANSACTION_LIST_F, TRANSACTION_LIST_S } from "../constants/types";

const initialState = {
    transaction:[],
    communitytransction:[],
    strategictransaction:[],
    transctiondetail:[]
};

const TransactionReducer = (state = initialState, action) => {
    switch (action.type) {
        case TRANSACTION_LIST_S:
            return {
                ...state, transaction:action.payload.data
            }
        case TRANSACTION_LIST_F:
            return {
                ...state, transaction: action.payload.message
            }
        case COMMUNITY_TRANSCTION_LIST_S:
            return {
                ...state, communitytransction:action.payload.data
            }
        case COMMUNITY_TRANSCTION_LIST_F:
            return {
                ...state, communitytransction:action.payload.message
            }

            case STRATEGIC_TRANSACTION_LIST_S:
            return {
                ...state, strategictransaction:action.payload.data
            }
        case STRATEGIC_TRANSACTION_LIST_F:
            return {
                ...state, strategictransaction:action.payload.message
            }
            case TRANSACTION_DETAIL_S:
                return {
                    ...state, transctiondetail: action.payload.data
                }
            case TRANSACTION_DETAIL_F:
                return {
                    ...state, transctiondetail: action.payload.message
                }
        default:
            return state;
    }
}
export default TransactionReducer