import { EVENT_DETAILS_F, EVENT_DETAILS_S, EVENT_LIST_F, EVENT_LIST_S, GOING_IN_EVENT_F, GOING_IN_EVENT_S, INTERESTED_IN_EVENT_F, INTERESTED_IN_EVENT_S, NOTGOING_IN_EVENT_F, NOTGOING_IN_EVENT_S } from "../constants/types";

const initialState = {
    eventList: {},
    eventdetails: {},
    interested: {},
    going: {},
    notGoing: {}
};

const EventReducer = (state = initialState, action) => {
    switch (action.type) {
        case EVENT_LIST_S:
            return {
                ...state, eventList: action.payload.data
            }
        case EVENT_LIST_F:
            return {
                ...state, eventList: action.payload.message
            }
        case EVENT_DETAILS_S:
            return {
                ...state, eventdetails: action.payload.data
            }
        case EVENT_DETAILS_F:
            return {
                ...state, eventdetails: action.payload.message
            }
        case INTERESTED_IN_EVENT_S:
            return {
                ...state, interested: action.payload.data
            }
        case INTERESTED_IN_EVENT_F:
            return {
                ...state, interested: action.payload.message
            }
        case GOING_IN_EVENT_S:
            return {
                ...state, going: action.payload.data
            }
        case GOING_IN_EVENT_F:
            return {
                ...state, going: action.payload.message
            }
        case NOTGOING_IN_EVENT_S:
            return {
                ...state, notGoing: action.payload.data
            }
        case NOTGOING_IN_EVENT_F:
            return {
                ...state, notGoing: action.payload.message
            }
        default:
            return state;
    }
}
export default EventReducer