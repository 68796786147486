import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AuthGuard = ({ children }) => {
  const navigate = useNavigate();
  let isLoggedIn = false;
  const token = JSON.parse(localStorage.getItem("userData"));
  const userTypeData = JSON.parse(localStorage.getItem("userType"));

  if (token) {
    isLoggedIn = true;
  }
  useEffect(() => {
    if (!isLoggedIn && userTypeData === 1) {
      navigate("/signin", { replace: true });
      localStorage.removeItem("userType");
    } else if (!isLoggedIn && userTypeData === 2) {
      navigate("/strategic_signin", { replace: true });
      localStorage.removeItem("userType");
    } else if (!isLoggedIn && !userTypeData) {
      navigate("/signin", { replace: true });
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, navigate]);

  return <>{children}</>;
};

export default AuthGuard;
