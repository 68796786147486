import { COMMUNITY_DETAIL_F, COMMUNITY_DETAIL_S, COMMUNITY_LIST_F, COMMUNITY_LIST_S } from "../constants/types";

const initialState = {
    communityList: {},
    communityDetails: {}
};

const CommunityReducer = (state = initialState, action) => {
    switch (action.type) {
        case COMMUNITY_LIST_S:
            return {
                ...state, communityList: action.payload.data
            }
        case COMMUNITY_LIST_F:
            return {
                ...state, communityList: action.payload.message
            }
        case COMMUNITY_DETAIL_S:
            return {
                ...state, communityDetails: action.payload.data.communityDetails
            }
        case COMMUNITY_DETAIL_F:
            return {
                ...state, communityDetails: action.payload.message
            }
        default:
            return state;
    }
}
export default CommunityReducer