import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Charitydetail,
  EditCharitydetail,
} from "../../../../../actions/charity";
import {
  communityDetail,
  EditCommunityDetail,
} from "../../../../../actions/community";
import { doVideoDelete } from "../../../../../actions/registration";
import {
  strategicUserUpdate,
  strategicUserView,
} from "../../../../../actions/strategic";
import { getVideoUrl, toAbsoluteUrl } from "../../../../../utils";
import Delete from "../../../../svg/delete";
import Play from "../../../../svg/play";

const Video = ({ videos, moduleName, from }) => {
  const metaData = useSelector((state) => state.metaReducer);
  const Change = useSelector((state) => state.auth?.user?.data);
  const charitydetail = useSelector(
    (state) => state.ChairtyReducer.chairtyDetail?.charityList
  );
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.communityDetails
  );
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );

  const dispatch = useDispatch();
  const onDelete = (val) => {
    const data = {
      moduleName: moduleName,
      fileName: val,
    };
    dispatch(doVideoDelete(data));
    let filteredVideos = videos.filter((o) => o?.name !== val);
    switch (from) {
      case "charity":
        const datacharity = {
          charityId: Change?._id,
          relatedCause: [],
          categoryId: charitydetail?.charityDetails?.categoryId,
          mediaVideo: filteredVideos,
        };
        dispatch(EditCharitydetail(Change?._id, datacharity))
          .then((res) => {
            dispatch(Charitydetail(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "strategic":
        const dataStrategic = {
          strategicPartnerId: Change?._id,
          mediaVideo: filteredVideos,
        };

        const allStrategicData = { ...strategicdetail, ...dataStrategic };
        dispatch(strategicUserUpdate(allStrategicData, Change?._id))
          .then((res) => {
            dispatch(strategicUserView(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "commmunity":
        const datacommunity = {
          _id: communitydetail?._id,
          charityId: Change?._id,
          mediaVideo: filteredVideos,
        };
        dispatch(EditCommunityDetail(datacommunity))
          .then((res) => {
            dispatch(communityDetail(communitydetail?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;
      default:
        break;
    }
  };

  const handlevideo = (val) => {
    window.open(val, "_blank");
  };
  return (
    <>
      <div className="image-box video-box">
        <div className="post-box">
          <div className="post-area-box">
            <div className="post-inner">
              <div>
                <ul className="post-list">
                  {videos.map((val, i) => (
                    <li key={val?.url}>
                      <figure className="upload-img">
                        <span className="btn-play">
                          <div title="play" className="play">
                            {" "}
                            <Play />
                          </div>
                        </span>
                        <i>
                          <div
                            title="play"
                            className="play"
                            onClick={() => {
                              handlevideo(val?.url);
                            }}
                          >
                            {" "}
                            <Play />
                          </div>
                          <div
                            title="delete"
                            className="delete-btn"
                            onClick={() => {
                              onDelete(val?.name);
                            }}
                          >
                            <Delete />
                          </div>
                        </i>
                        <a
                          href={`${val?.url}`}
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          <img
                            // src={getVideoUrl(
                            //   metaData?.mediaURL,
                            //   moduleName,
                            //   val?.thumbnail,
                            //   true
                            // )}
                            src={toAbsoluteUrl("/images/placeholder.png")}
                            alt={val?.name}
                            height={80}
                            width={80}
                          />
                        </a>
                      </figure>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
