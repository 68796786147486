export const API = "API";
export const API_REQUEST = "API_REQUEST";
export const API_SUCCESS = "API_SUCCESS";
export const API_ERROR = "API_ERROR";

export const LOGIN_S = "LOGIN_SUCCESS";
export const LOGIN_F = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const FETCH_META_S = "FETCH_META_S";
export const FETCH_META_F = "FETCH_META_F";

export const IMAGE_DELETE_S = "IMAGE_DELETE_S";
export const IMAGE_DELETE_F = "IMAGE_DELETE_F";

export const VIDEO_DELETE_S = "VIDEO_DELETE_S";
export const VIDEO_DELETE_F = "VIDEO_DELETE_F";

export const CAUSE_S = "CAUSE_SUCCESS";
export const CAUSE_F = "CAUSE_FAIL";

export const CATEGORY_S = "CATEGORY_S";
export const CATEGORY_F = "CATEGORY_F";

export const CHARITY_LISTING_S = "CHARITY_LISTING_S";
export const CHARITY_LISTING_F = "CHARITY_LISTING_F";

export const CHARITY_DETAIL_S = "CHARITY_DETAIL_S";
export const CHARITY_DETAIL_F = "CHARITY_DETAIL_F";

export const CHARITY_VERIFICTAION_S = "CHARITY_VERIFICTAION_S";
export const CHARITY_VERIFICTAION_F = "CHARITY_VERIFICTAION_F";

// admin cause
export const CAUSE_LISTING_SUCCESS = "CAUSE_LISTING_SUCCESS";
export const CAUSE_LISTING_FAIL = "CAUSE_LISTING_FAIL";

export const CAUSE_ADD_SUCCESS = "CAUSE_ADD_SUCCESS";
export const CAUSE_ADD_FAIL = "CAUSE_ADD_FAIL";

export const CAUSE_DETAIL_SUCCESS = "CAUSE_DETAIL_SUCCESS";
export const CAUSE_DETAIL_FAIL = "CAUSE_DETAIL_FAIL";

export const CAUSE_DELETE_SUCCESS = "CAUSE_DELETE_SUCCESS";
export const CAUSE_DELETE_FAIL = "CAUSE_DELETE_FAIL";

export const CAUSE_EDIT_SUCCESS = "CAUSE_EDIT_SUCCESS";
export const CAUSE_EDIT_FAIL = "CAUSE_EDIT_FAIL";

// -------------------------------

// New charity Accept

export const CHARITY_LISTING_ACCEPT_SUCCESS = "CHARITY_LISTING_ACCEPT_SUCCESS";
export const CHARITY_LISTING_ACCEPT_FAIL = "CHARITY_LISTING_ACCEPT_FAIL";

// -------------------------------

// Charity Category

export const CHARITY_CATEGORY_LISTING_SUCCESS =
  "CHARITY_CATEGORY_LISTING_SUCCESS";
export const CHARITY_CATEGORY_LISTING_FAIL = "CHARITY_CATEGORY_LISTING_FAIL";

export const CHARITY_CATEGORY_ADD_SUCCESS = "CHARITY_CATEGORY_ADD_SUCCESS";
export const CHARITY_CATEGORY_ADD_FAIL = "CHARITY_CATEGORY_ADD_FAIL";

export const CHARITY_CATEGORY_DETAIL_SUCCESS =
  "CHARITY_CATEGORY_DETAIL_SUCCESS";
export const CHARITY_CATEGORY_DETAIL_FAIL = "CHARITY_CATEGORY_DETAIL_FAIL";

export const CHARITY_CATEGORY_DELETE_SUCCESS =
  "CHARITY_CATEGORY_DELETE_SUCCESS";
export const CHARITY_CATEGORY_DELETE_FAIL = "CHARITY_CATEGORY_DELETE_FAIL";

export const CHARITY_CATEGORY_EDIT_SUCCESS = "CHARITY_CATEGORY_EDIT_SUCCESS";
export const CHARITY_CATEGORY_EDIT_FAIL = "CHARITY_CATEGORY_EDIT_FAIL";

// -------------------------------

export const CAUSE_EDIT_S = "CAUSE_EDIT_S";
export const CAUSE_EDIT_F = "CAUSE_EDIT_F";

export const HOMEPAGE_UPDATE_S = "HOMEPAGE_UPDATE_S";
export const HOMEPAGE_UPDATE_F = "HOMEPAGE_UPDATE_F";

export const HOMEPAGE_DETAIL_S = "HOMEPAGE_DETAIL_S";
export const HOMEPAGE_DETAIL_F = "HOMEPAGE_DETAIL_F";

export const CAUSE_UPDATE_S = "CAUSE_UPDATE_S";
export const CAUSE_UPDATE_F = "CAUSE_UPDATE_F";

export const COUNTRY_S = "COUNTRY_S";
export const COUNTRY_F = "COUNTRY_F";

export const STATE_S = "STATE_S";
export const STATE_F = "STATE_F";

export const CITY_S = "CITY_S";
export const CITY_F = "CITY_F";

export const FORGOT_PASSWORD_S = "FORGOT_PASSWORD_S";
export const FORGOT_PASSWORD_F = "FORGOT_PASSWORD_F";

export const RESET_PASSWORD_S = "RESET_PASSWORD_S";
export const RESET_PASSWORD_F = "RESET_PASSWORD_F";

//community
export const COMMUNITY_LIST_S = "COMMUNITY_LIST_S";
export const COMMUNITY_LIST_F = "COMMUNITY_LIST_F";

export const COMMUNITY_CREAT_S = "COMMUNITY_CREAT_S";
export const COMMUNITY_CREAT_F = "COMMUNITY_CREAT_F";

export const COMMUNITY_DETAIL_S = "COMMUNITY_DETAIL_S";
export const COMMUNITY_DETAIL_F = "COMMUNITY_DETAIL_F";

export const COMMUNITY_DELETE_S = "COMMUNITY_DELETE_S";
export const COMMUNITY_DELETE_F = "COMMUNITY_DELETE_F";

export const COMMUNITY_EDIT_S = "COMMUNITY_EDIT_S";
export const COMMUNITY_EDIT_F = "COMMUNITY_EDIT_F";

//Edit profile view
export const EDIT_CHARITY_VIEW_S = "EDIT_CHARITY_VIEW_S";
export const EDIT_CHARITY_VIEW_F = "EDIT_CHARITY_VIEW_F";

//Event
export const EVENT_LIST_S = "EVENT_LIST_S";
export const EVENT_LIST_F = "EVENT_LIST_F";

export const EVENT_ADD_S = "EVENT_ADD_S";
export const EVENT_ADD_F = "EVENT_ADD_F";

export const EVENT_DETAILS_S = "EVENT_DETAILS_S";
export const EVENT_DETAILS_F = "EVENT_DETAILS_F";

export const INTERESTED_IN_EVENT_S = "INTERESTED_IN_EVENT_S";
export const INTERESTED_IN_EVENT_F = "INTERESTED_IN_EVENT_F";

export const GOING_IN_EVENT_S = "GOING_IN_EVENT_S";
export const GOING_IN_EVENT_F = "GOING_IN_EVENT_F";

export const NOTGOING_IN_EVENT_S = "NOTGOING_IN_EVENT_S";
export const NOTGOING_IN_EVENT_F = "NOTGOING_IN_EVENT_F";

export const INTERESTED_S = "INTERESTED_S";
export const INTERESTED_F = "INTERESTED_F";
export const EVENT_UPDATE_S = "EVENT_UPDATE_S";
export const EVENT_UPDATE_f = "EVENT_UPDATE_F";

// export const EVENT_MEMBER_DELETE_S = 'EVENT_MEMBER_DELETE_S'
// export const EVENT_MEMBER_DELETE_F = 'EVENT_MEMBER_DELETE_F'

//Post
export const POST_ADD_S = "POST_ADD_S";
export const POST_ADD_F = "POST_ADD_F";

export const POST_LIST_S = "POST_LIST_S";
export const POST_LIST_F = "POST_LIST_F";

export const POST_UPDATE_S = "POST_UPDATE_S";
export const POST_UPDATE_F = "POST_UPDATE_F";

export const POST_DELETE_S = "POST_DELETE_S";
export const POST_DELETE_F = "POST_DELETE_F";

export const POST_HIDE_S = "POST_HIDE_S";
export const POST_HIDE_F = "POST_HIDE_F";

export const POST_COMMENT_LIST_S = "POST_COMMENT_LIST_S";
export const POST_COMMENT_LIST_F = "POST_COMMENT_LIST_F";

//Payment
export const PAYMENT_DETAILS_ADD_S = "PAYMENT_DETAILS_ADD_S";
export const PAYMENT_DETAILS_ADD_F = "PAYMENT_DETAILS_ADD_F";

export const PAYMENT_DETAIL_GET_S = "PAYMENT_DETAIL_GET_S";
export const PAYMENT_DETAIL_GET_F = "PAYMENT_DETAIL_GET_F";

export const PAYMENT_DETAIL_UPDATE_S = "PAYMENT_DETAIL_UPDATE_S";
export const PAYMENT_DETAIL_UPDATE_F = "PAYMENT_DETAIL_UPDATE_F";

// Transection
export const TRANSACTION_LIST_S = "TRANSACTION_LIST_S";
export const TRANSACTION_LIST_F = "TRANSACTION_LIST_F";

export const COMMUNITY_TRANSCTION_LIST_S = "COMMUNITY_TRANSCTION_LIST_S";
export const COMMUNITY_TRANSCTION_LIST_F = "COMMUNITY_TRANSCTION_LIST_F";
//Content Mangement
export const GET_CMS_LIST_S = "GET_CMS_LIST_S";
export const GET_CMS_LIST_F = "GET_CMS_LIST_F";

export const FAQ_CATEGORY_LIST_S = "FAQ_CATEGORY_LIST_S";
export const FAQ_CATEGORY_LIST_F = "FAQ_CATEGORY_LIST_F";

export const FAQ_LIST_S = "FAQ_LIST_S";
export const FAQ_LIST_F = "FAQ_LIST_F";

//Dashboard
export const DASHBOARD_S = "DASHBOARD_S";
export const DASHBOARD_F = "DASHBOARD_F";

export const DASHBOARD_YEARGRAPH_S = "DASHBOARD_YEARGRAPH_S";
export const DASHBOARD_YEARGRAPH_F = "DASHBOARD_YEARGRAPH_F";

export const DASHBOARD_SIX_MONTH_S = "DASHBOARD_SIX_MONTH_S";
export const DASHBOARD_SIX_MONTH_F = "DASHBOARD_SIX_MONTH_F";

export const DASHBOARD_DAYGRAPH_S = "DASHBOARD_DAYGRAPH_S";
export const DASHBOARD_DAYGRAPH_F = "DASHBOARD_DAYGRAPH_F";

export const DASHBOARD_DAILYUPDATE_S = "DASHBOARD_DAILYUPDATE_S";
export const DASHBOARD_DAILYUPDATE_F = "DASHBOARD_DAILYUPDATE_F";

// Chat Types
// -------------------------------------

export const USER_CONNECTION_STATUS = "USER_CONNECTION_STATUS";

export const CHAT_USER_LIST_S = "CHAT_USER_LIST_S";
export const CHAT_USER_LIST_F = "CHAT_USER_LIST_F";

export const CHAT_LIST_S = "CHAT_LIST_S";
export const CHAT_LIST_F = "CHAT_F";

export const CHAT_PAGE_CHANGE = "CHAT_PAGE_CHANGE";
export const CHAT_LIMIT_CHANGE = "CHAT_LIMIT_CHANGE";

export const USER_LIST_LIMIT_CHANGE = "USER_LIST_LIMIT_CHANGE";

export const CHAT_ACTIVE_USER = "CHAT_ACTIVE_USER";

//subscribe sonor
export const SUBSCRIBE_DONOR_LIST_S = "SUBSCRIBE_DONOR_LIST_S";
export const SUBSCRIBE_DONOR_LIST_F = "SUBSCRIBE_DONOR_LIST_F";
export const SUBSCRIBE_ONETIMEDONOR_LIST_S = "SUBSCRIBE_ONETIMEDONOR_LIST_S";
export const SUBSCRIBE_ONETIMEDONOR_LIST_F = "SUBSCRIBE_ONETIMEDONOR_LIST_F";
//strategic
export const CREATE_REGISTER_S = "CREATE_REGISTER_S";
export const CREATE_REGISTER_F = "CREATE_REGISTER_F";

//Login
export const STRATEGIC_PARTNER_LOGIN_S = "STRATEGIC_PARTNER_LOGIN_S";
export const STRATEGIC_PARTNER_LOGIN_F = "STRATEGIC_PARTNER_LOGIN_F";
export const STRATEGIC_PARTNER_LOGOUT = "STRATEGIC_PARTNER_LOGOUT";

//Forgot Password
export const STRATEGIC_FORGOT_PASSWORD_S = "STRATEGIC_FORGOT_PASSWORD_S";
export const STRATEGIC_FORGOT_PASSWORD_F = "STRATEGIC_FORGOT_PASSWORD_F";

//Reset Password
export const STRATEGIC_RESET_PASSWORD_S = "STRATEGIC_RESET_PASSWORD_S";
export const STRATEGIC_RESET_PASSWORD_F = "STRATEGIC_RESET_PASSWORD_F";

//Employee
export const STRATEGIC_CREATE_EMPLOYEE_S = "STRATEGIC_CREATE_EMPLOYEE_S";
export const STRATEGIC_CREATE_EMPLOYEE_F = "STRATEGIC_CREATE_EMPLOYEE_F";

export const STRATEGIC_EMPLOYEE_VERIFICATION_S =
  "STRATEGIC_EMPLOYEE_VERIFICATION_S";
export const STRATEGIC_EMPLOYEE_VERIFICATION_F =
  "STRATEGIC_EMPLOYEE_VERIFICATION_F";

export const STRATEGIC_PENDING_EMPLOYEE_S = "STRATEGIC_PENDING_EMPLOYEE_S";
export const STRATEGIC_PENDING_EMPLOYEE_F = "STRATEGIC_PENDING_EMPLOYEE_F";

export const STRATEGIC_APPROVE_EMPLOYEE_S = "STRATEGIC_APPROVE_EMPLOYEE_S";
export const STRATEGIC_APPROVE_EMPLOYEE_F = "STRATEGIC_APPROVE_EMPLOYEE_F";

export const STRATEGIC_DELETE_EMPLOYEE_S = "STRATEGIC_DELETE_EMPLOYEE_S";
export const STRATEGIC_DELETE_EMPLOYEE_F = "STRATEGIC_DELETE_EMPLOYEE_F";

//NOTIFICTION
export const NOTIFICTION_LIST_S = "NOTIFICTION_LIST_S";
export const NOTIFICTION_LIST_F = "NOTIFICTION_LIST_F";

//Strategic Partner Profile
export const STRATEGIC_USER_VIEW_S = "STRATEGIC_USER_VIEW_S";
export const STRATEGIC_USER_VIEW_F = "STRATEGIC_USER_VIEW_F";

export const STRATEGIC_USER_UPDATE_S = "STRATEGIC_USER_UPDATE_S";
export const STRATEGIC_USER_UPDATE_F = "STRATEGIC_USER_UPDATE_F";

//Strategic Partner Post
export const STRATEGIC_POST_LIST_S = "STRATEGIC_POST_LIST_S";
export const STRATEGIC_POST_LIST_F = "STRATEGIC_POST_LIST_F";

export const EMPLOYEE_POST_LIST_S = "EMPLOYEE_POST_LIST_S";
export const EMPLOYEE_POST_LIST_F = "EMPLOYEE_POST_LIST_F";

//Strategic Dashboard
export const STRATEGIC_DASHBOARD_S = "STRATEGIC_DASHBOARD_S";
export const STRATEGIC_DASHBOARD_F = "STRATEGIC_DASHBOARD_F";

export const STRATEGIC_DAYGRAPH_DASHBOARD_S = "STRATEGIC_DAYGRAPH_DASHBOARD_S";
export const STRATEGIC_DAYGRAPH_DASHBOARD_F = "STRATEGIC_DAYGRAPH_DASHBOARD_F";

export const STRATEGIC_YEARGRAPH_DASHBOARD_S =
  "STRATEGIC_YEARGRAPH_DASHBOARD_S";
export const STRATEGIC_YEARGRAPH_DASHBOARD_F =
  "STRATEGIC_YEARGRAPH_DASHBOARD_F";

  export const STRATEGIC_SIX_MONTH_DASHBOARD_S =
  "STRATEGIC_SIX_MONTH_DASHBOARD_S";
export const STRATEGIC_SIX_MONTH_DASHBOARD_F =
  "STRATEGIC_SIX_MONTH_DASHBOARD_F";


//strategic Transction

export const STRATEGIC_TRANSACTION_LIST_S = "STRATEGIC_TRANSACTION_LIST_S";
export const STRATEGIC_TRANSACTION_LIST_F = "STRATEGIC_TRANSACTION_LIST_F";

export const TRANSACTION_DETAIL_S = 'TRANSACTION_DETAIL_S'
export const TRANSACTION_DETAIL_F = 'TRANSACTION_DETAIL_F'
