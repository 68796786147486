import React from "react";
import { Field, Form } from "react-final-form";
import { rendertextarea, RenderVideoField } from "../../forms";
import { ImgUploadCommon } from "../img-upload-common";

const PostModal = ({
  setmodel,
  ismodel,
  AllImages,
  setAllImages,
  imageFiles,
  setImageFiles,
  setDeleteImage,
  AllImagesContainOldImages,
  onSubmit,
  AllVideos,
  setAllVideos,
  videoSourceURLs,
  setVideoSourceURLs,
  videoSourceFiles,
  setVideoSourceFiles,
  setDeletedVideoArray,
  containOldImages,
  video,
  setisVideo,
  formRef = {},
  PostData = {},
  EditPost,
}) => {
  const handleClose = (form) => {
    setmodel((prev) => !prev);
    setVideoSourceFiles([]);
    setVideoSourceURLs([]);
    setImageFiles([]);
    if (video === true) {
      setisVideo((prev) => !prev);
    }
    form.reset();
    setImageFiles([]);
  };

  return (
    <div className={`model-area upload-model ${ismodel ? "open-model" : ""}`}>
      <div className="model-box">
        <div className="modele-text-box">
          <h2>{EditPost ? "Edit Post" : "Add Post"}</h2>
          <div className="form-area">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                text: PostData?.title || "",
                Caption: PostData?.description || "",
              }}
            >
              {({ handleSubmit, form }) => {
                formRef.current = form;

                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-list d-flex flex-wrap">
                      <div className="col-12 col-div">
                        <div className="col-12 col-div">
                          <label className="label-text"> Caption</label>
                          <Field
                            name="Caption"
                            component={rendertextarea}
                            placeholder="Enter your Caption"
                            className="form-grp"
                            inputclass="input-box text-area-box"
                          />
                        </div>
                        {PostData?.type !== 3 &&
                          (video === false ? (
                            <>
                              <label className="label-text">
                                {" "}
                                Upload Image{" "}
                              </label>
                              <ImgUploadCommon
                                AllImages={AllImages}
                                setAllImages={setAllImages}
                                imageFiles={imageFiles}
                                setImageFiles={setImageFiles}
                                maxNumber={5}
                                multiple={true}
                                moduleName="postImages"
                                callUploadAPI={false}
                                AllImagesContainOldImages={
                                  AllImagesContainOldImages
                                }
                                setDeleteImage={setDeleteImage}
                              />{" "}
                            </>
                          ) : (
                            <>
                              <label className="label-text">
                                {" "}
                                Upload Video{" "}
                              </label>
                              <RenderVideoField
                                oneVideoValidation={true}
                                isMultiple={false}
                                moduleName="postImages"
                                videoArray={AllVideos}
                                setVideoArray={setAllVideos}
                                videoSourceURLs={videoSourceURLs}
                                setVideoSourceURLs={setVideoSourceURLs}
                                videoSourceFiles={videoSourceFiles}
                                setVideoSourceFiles={setVideoSourceFiles}
                                setDeletedVideoArray={setDeletedVideoArray}
                                containOldImages={containOldImages}
                              />{" "}
                            </>
                          ))}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap form-btn-box">
                      <button type="submit" className="link-btn">
                        Save
                      </button>
                      <button
                        type="button"
                        onClick={() => handleClose(form)}
                        className="link-btn cancel"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostModal;
