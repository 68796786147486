/* eslint-disable no-unused-vars */
import React from "react";
import ImageUploading from "react-images-uploading";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {  doUploadImage } from "../../../actions/registration";
import Delete from "../../svg/delete";
import "./upload.scss";

export const ImgUploadCommon = ({
  maxNumber = 99999,
  multiple,
  AllImages,
  setAllImages,
  imageFiles,
  setImageFiles,
  moduleName,
  isDisplayOldImage = true,
  setDeleteImage,
  callUploadAPI,
  AllImagesContainOldImages,
}) => {
  const dispatch = useDispatch();
  const metaData = useSelector((state) => state.metaReducer);

  const onChange = (imageList, addUpdateIndex) => {
    if (setImageFiles) {
      setImageFiles(imageList);
    }
    if (callUploadAPI) {
      for (let index = 0; index < imageList?.length; index++) {
        const element = imageList[index];
        const data = { image: [element], moduleName: moduleName };

        if (!element?._id) {
          dispatch(doUploadImage(data)).then((res) => {
            setAllImages((prev) => [
              ...prev,
              {
                name: res.data.name,
                url: metaData?.mediaURL + moduleName + "/" + res.data.name,
              },
            ]);
          });
        }
      }
    }
  };

  const handleOldDelete = (selctedImage) => {
    // const data = {
    //   fileName: selctedImage?.name,
    //   moduleName: moduleName,
    // };
    // dispatch(doImageDelete(data)).then((res) => {
    let filteredImages = AllImages.filter(
      (val) => val.name !== selctedImage?.name
    );
    setAllImages(filteredImages);
    setDeleteImage({ name: selctedImage?.name });
    // });
  };

  return (
    <>
      <ImageUploading
        multiple={multiple}
        value={callUploadAPI ? AllImages : imageFiles}
        onError={(e) => {
          if (e?.maxFileSize === true) {
            toast.error("Image size should be less than 2 mb");
          } else if (e?.maxNumber === true) {
            toast.error("You can't upload more than five images");
          }
        }}
        onChange={onChange}
        maxNumber={maxNumber}
        dataURLKey="url"
        maxFileSize={2000000}
        // acceptType={["jpg", "svg", "png", "video"]}
        // acceptType={[ng"]}
      >
        {({
          imageList,
          onImageUpload,
          onImageRemoveAll,
          onImageUpdate,
          onImageRemove,
          isDragging,
          dragProps,
          errors,
        }) => (
          // write your building UI
          <div className="imageModalSet">
            {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
            <div className="img-map-box">
              <button
                type="button"
                className="upload-btn"
                style={isDragging ? { color: "red" } : null}
                onClick={onImageUpload}
                {...dragProps}
              >
                +
              </button>
              {AllImagesContainOldImages &&
                AllImages?.filter((val) => {
                  if (isDisplayOldImage) return val;
                  else return !val?._id;
                })?.map((val, index) => (
                  <div key={val.url} className="image-item">
                    <img src={val.url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="img-btn"
                        onClick={() => handleOldDelete(val)}
                        title="Delete"
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))}
              {imageList
                ?.filter((val) => {
                  if (isDisplayOldImage) return val;
                  else return !val?._id;
                })
                ?.map((val, index) => (
                  <div key={val?.url} className="image-item">
                    <img src={val.url} alt="" width="100" />
                    <div className="image-item__btn-wrapper">
                      <button
                        type="button"
                        className="img-btn"
                        onClick={() =>
                          callUploadAPI
                            ? handleOldDelete(val)
                            : onImageRemove(index)
                        }
                        title="Delete"
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </ImageUploading>
    </>
  );
};
