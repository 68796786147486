import { io } from "socket.io-client";
import {
  CHAT_ACTIVE_USER,
  CHAT_LIMIT_CHANGE,
  CHAT_LIST_F,
  CHAT_LIST_S,
  CHAT_PAGE_CHANGE,
  CHAT_USER_LIST_S,
  USER_CONNECTION_STATUS,
  USER_LIST_LIMIT_CHANGE,
} from "../constants/types";
import store from "../store";

export const socket = io(process.env.REACT_APP_SOCKET_URL, {
  // WARNING: in that case, there is no fallback to long-polling
  transports: ["websocket"], // or [ "websocket", "polling" ] (the order matters)
});

// ===============
// Redux events
// ===============

// Reset Chat redux
export const resetChat = (page) => {
  store.dispatch({ type: CHAT_PAGE_CHANGE, payload: Number(1) });
  store.dispatch({ type: CHAT_LIMIT_CHANGE, payload: Number(10) });
  store.dispatch({ type: CHAT_LIST_F, payload: [] });
  store.dispatch({ type: CHAT_ACTIVE_USER, payload: {} });
  store.dispatch({ type: USER_LIST_LIMIT_CHANGE, payload: 10 });
};
// Reset Chat history
export const resetChatHistory = (data = []) => {
  store.dispatch({ type: CHAT_LIST_S, payload: data });
};
// Reset Chat User List
export const resetChatUserList = (data = []) => {
  store.dispatch({ type: CHAT_USER_LIST_S, payload: data });
};

// ===============
// Emit events
// ===============

// To Connect User with socket
export const connectUser = (userId) => {
  let requestPayload = { userId: userId };

  socket.emit("user-connected", requestPayload, (res) => {
    if (socket.connected) {
      store.dispatch({ type: USER_CONNECTION_STATUS, payload: true });
    } else {
      store.dispatch({ type: USER_CONNECTION_STATUS, payload: false });
    }
  });
};

// Event to get user list (list of user)
export const getChatUserList = (data) => {
  const { page, limit, userId, search = "" } = data;

  let requestPayload = { perPage: limit, page: page, userId: userId, search };

  socket.emit("chat-listing", requestPayload, (res) => {
    store.dispatch({ type: CHAT_USER_LIST_S, payload: res });
  });
};

// Event to get chat history of room
export const getChatHistory = (data) => {
  const { userId, roomId, page, limit } = data;

  let requestPayload = {
    userId: userId,
    roomId: roomId,
    perPage: limit,
    page: page,
  };

  socket.emit("chat-history", requestPayload, (res) => {
    //when we clear chat that time issue crate condition
    // if (res?.list?.length) {
    store.dispatch({ type: CHAT_LIST_S, payload: res?.list });
    // }
  });
};

// Event to send message
export const sendMessage = (data, callBackFun) => {
  const { userId, roomId, message, type } = data;

  let requestPayload = {
    userId: userId,
    roomId: roomId,
    message: message,
    type: type || "",
  };

  socket.emit("send-message", requestPayload, (res) => {
    callBackFun();
  });
};

// ===============
// On events
// ===============

// On Event to get new message
export const getNewMessage = (callBackFun) => {
  socket.on("server-receive-message", callBackFun);
};
