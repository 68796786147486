import React from 'react'

const Chats = () => {
  return (
    <>
      <svg id="Layer_1" enableBackground="new 0 0 404.009 404.009" height="512" viewBox="0 0 404.009 404.009" width="512" xmlns="http://www.w3.org/2000/svg"><g><g><path d="m46.594 315.11c-13.5 0-26.689-3.445-38.642-10.157-6.15-3.167-8.532-11.091-4.858-17.052 12.207-19.506 16.615-43.35 11.026-65.774-5.793-23.247-14.175-42.826-14.115-67.436.208-86.341 72.073-156.337 158.326-154.662 83.31 1.678 151.969 71.71 151.97 155.042.001 108.833-114.057 185.243-214.75 143.216-13.879 10.894-31.324 16.823-48.957 16.823zm-16.404-26.572c18.968 5.891 40.301 1.185 54.928-12.956 3.604-3.485 8.995-4.362 13.517-2.199 86.207 41.217 187.666-23.387 187.665-118.312 0-70.834-57.624-129.621-128.452-131.047-72.992-1.45-133.667 57.666-133.843 130.725-.057 23.375 9.063 42.056 14.047 64.199 5.251 23.335 2.485 48.011-7.862 69.59z"/></g><g><path d="m357.414 404.009c-17.632 0-35.078-5.93-48.955-16.822-53.55 22.349-117.248 12.171-161.155-25.747-5.016-4.332-5.57-11.909-1.239-16.926 4.332-5.015 11.909-5.571 16.925-1.238 38.942 33.63 95.973 41.195 142.384 19.007 4.521-2.164 9.911-1.286 13.517 2.199 14.626 14.14 35.96 18.849 54.928 12.955-12.206-25.459-13.897-54.626-4.438-81.628.085-.243.178-.482.278-.72 6.908-16.285 10.389-33.593 10.346-51.441-.076-31.468-10.544-60.577-30.274-84.182-4.25-5.086-3.573-12.653 1.512-16.903 5.084-4.251 12.653-3.573 16.903 1.512 23.036 27.561 35.771 62.902 35.859 99.515.051 20.971-4.018 41.323-12.093 60.499-8.482 24.553-4.581 50.842 9.002 72.709 3.677 5.962 1.293 13.887-4.859 17.056-11.951 6.709-25.143 10.154-38.641 10.155z"/></g><g><g><ellipse cx="155.152" cy="155.669" rx="18.988" ry="18.978"/></g><g><ellipse cx="85.196" cy="155.669" rx="18.988" ry="18.978"/></g><g><ellipse cx="225.109" cy="155.669" rx="18.988" ry="18.978"/>
      </g>
      </g>
      </g>
      </svg>


    </>
  )
}

export default Chats
