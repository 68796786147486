/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { Field, Form } from "react-final-form";
import { Link, useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  EditCityField,
  EditSelectField,
  renderField,
  renderNumberField,
  renderRadioButton,
  RenderSelectField,
  rendertextarea,
} from "../../../components/forms";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import ImgUpload from "../../../components/common/img-upload-home";
import "../event.scss";
import { useDispatch, useSelector } from "react-redux";
import { eventDetail, eventUpdate } from "../../../actions/event";
import {
  addTOCity,
  addTOState,
  doUploadImage,
} from "../../../actions/registration";
import { communityList } from "../../../actions/community";
import { EditEventValidation as validate } from "../../../components/validations/signIn";
import moment from "moment";
import { mergeDateTime } from "../../../utils";
import { toast } from "react-toastify";

const list = [
  {
    name: "Event Management",
    link: "/event",
    isactive: false,
  },
  {
    name: "Event Edit",
    link: "/event/event_edit",
    isactive: true,
  },
];

const EventEdit = () => {
  const EventDetail = useSelector((state) => state.EventReducer.eventdetails);
  const Change = useSelector((state) => state.auth);
  const metaData = useSelector((state) => state.metaReducer);

  const { countryList, stateList, cityList } = useSelector(
    (state) => state.metaReducer
  );

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const [selectedCountry, setselectedCountry] = useState({
    value: 233,
    label: "United States",
    phone_code: "1",
  });
  const [selectedState, setselectedState] = useState("");
  const [selectedCity, setselectedCity] = useState("");
  const [addressError, setAddressError] = useState({
    city: false,
    state: false,
  });

  const [community, setCommunity] = useState([]);

  //imges
  const [eventImages, setEventImages] = useState();
  const [oldEventImages, setoldEventImages] = useState();

  const [eventHostImages, setEventHostImages] = useState();
  const [oldEventHostImages, setoldEventHostImages] = useState();

  const [startDate, setStartDate] = useState(new Date());
  const [dateerror, setDateError] = useState("");

  const [time, setTime] = useState(
    moment(EventDetail?.[0]?.startDate).format("HH:mm")
  );
  const [timeerror, setTimeError] = useState("");
  const [endTime, setendTime] = useState(
    moment(EventDetail?.[0]?.eventEndTime || "").format("HH:mm")
  );
  const [endTimeError, setendTimeError] = useState("");
  const [active, setActive] = useState();
  const formRef = useRef();

  useEffect(() => {
    if (formRef.current) {
      formRef.current.initialize({
        eventname: EventDetail?.[0]?.eventName || "",
        eventhostname: EventDetail?.[0]?.eventHostname || "",
        eventdescription: EventDetail?.[0]?.description || "",
        community:
          {
            label: EventDetail?.[0]?.communityName,
            value: EventDetail?.[0]?.communityId,
          } || "",
        eventAddress: EventDetail?.[0]?.eventLocation?.floor || "",
        eventAddress2: EventDetail?.[0]?.eventLocation?.street2 || "",
        pincode: EventDetail?.[0]?.eventLocation?.zipcode || "",
        radio1: EventDetail?.[0]?.eventType,
        eventLink: EventDetail?.[0]?.eventLink,
        eventnumber: EventDetail?.[0]?.eventHostPhoneNo,
        eventhostemailaddresh: EventDetail?.[0]?.eventHostEmail,
      });
    }
  }, [EventDetail]);

  useEffect(() => {
    const data = {
      _id: id,
    };
    dispatch(eventDetail(id, data)).then((res) => {
      if (res.data?.[0]?.startDate) {
        setTime(moment(res.data?.[0]?.startDate).format("HH:mm"));
        setStartDate(new Date(res.data?.[0]?.startDate));
        setendTime(moment(res.data?.[0]?.eventEndTime).format("HH:mm"));
        setActive(res.data?.[0]?.eventType);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchState({ countryId: selectedCountry?.value });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCountry]);

  useEffect(() => {
    if (time && startDate) {
      if (moment().utc().format() > mergeDateTime(startDate, time)) {
        setTimeError("Please select future time ");
      } else {
        setTimeError("");
      }
    }
    if (endTime && startDate) {
      if (mergeDateTime(startDate, endTime) < mergeDateTime(startDate, time)) {
        setendTimeError("Please select future time ");
      } else {
        setendTimeError("");
      }
    }

    if (startDate) {
      setDateError("");
    }
  }, [time, eventHostImages, eventImages, startDate, endTime]);

  useEffect(() => {
    let initialState = [];
    if (stateList?.length) {
      initialState = stateList.filter(
        (val) => val.label === EventDetail?.[0]?.eventLocation?.state
      );
      if (initialState?.length) {
        setselectedState(initialState[0]);
        fetchCity({ stateId: initialState[0]?.value });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [EventDetail, stateList]);

  useEffect(() => {
    let initialState = [];
    if (cityList?.length) {
      initialState = cityList.filter(
        (val) => val.label === EventDetail?.[0]?.eventLocation?.city
      );
      if (initialState?.length) {
        setselectedCity(initialState[0]);
      } else {
        setselectedCity({ label: EventDetail?.[0]?.eventLocation?.city, value: EventDetail?.[0]?.eventLocation?.city });
      }
    }
  }, [EventDetail, cityList]);

  useEffect(() => {
    fetchCommunity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setoldEventImages(EventDetail?.[0]?.eventImage);
    setoldEventHostImages(EventDetail?.[0]?.eventHostImage);
  }, [EventDetail]);

  const fetchState = (data) => {
    dispatch(addTOState(data));
  };

  const fetchCity = (data) => {
    dispatch(addTOCity(data));
  };
  const fetchCommunity = () => {
    const val = {
      page: 1,
    };
    dispatch(communityList(Change?.user?.data?._id, val)).then((res) => {
      const data = res.data;
      let communityArr = [];
      data.list.map((val, ind) => {
        return communityArr.push({ value: val._id, label: val.communityName });
      });
      setCommunity(communityArr);
    });
  };

  const uploadEventHostImage = () => {
    const payload = { image: eventHostImages, moduleName: "eventHostImages" };
    return dispatch(doUploadImage(payload));
  };

  const uploadEventImage = () => {
    const payload = { image: eventImages, moduleName: "eventImages" };
    return dispatch(doUploadImage(payload));
  };

  const onSubmit = (value) => {
    if (!startDate) {
      setDateError("Please Enter  Event Date");
      return;
    }

    if (!time) {
      setTimeError("Please Enter  Event Start Time");
      return;
    }
    if (!endTime) {
      setendTimeError("Please Enter  Event End Time");
    }
    if (active === "offline") {
      if (!selectedState?.label) {
        setAddressError({ ...addressError, state: true });
        return;
      }
      if (!selectedCity?.label) {
        setAddressError({ ...addressError, city: true });
        return;
      }
    }

    let utcDate = mergeDateTime(startDate, time);
    let EndTime = mergeDateTime(startDate, endTime);
    let eventImage = null;
    let eventHostImage = null;
    let data = {
      eventName: value.eventname,
      eventHostname: value.eventhostname,
      description: value.eventdescription,
      eventType: active,
      startDate: utcDate,
      eventTime: utcDate,
      eventEndTime: EndTime,
      // eventHostImage: images,
      // eventImage: mediaimages,
      eventHostPhoneNo: value.eventnumber
        ? `${value.eventnumber?.replace(/[^\d]/g, "")}`
        : "",
      eventHostEmail: value.eventhostemailaddresh,
    };
    if (Change?.isStrategic) {
      data = { ...data, strategicPartnerId: Change?.user?.data?._id };
    } else {
      data = {
        ...data,
        charityId: Change?.user?.data?._id,
        communityId: value.community.value,
      };
    }
    let finalData =
      active !== "offline"
        ? { ...data, eventLink: value.eventLink }
        : {
          ...data,
          address: {
            floor: value.eventAddress,
            number: value.eventAddress,
            street: value.eventAddress,
            street2: value.eventAddress2,
            city: selectedCity?.label || "",
            state: selectedState?.label || "",
            zipcode: value.pincode,
            country: selectedCountry?.label || "",
          },
        };

    let promiseArr = [];
    if (eventHostImages) {
      promiseArr = [uploadEventHostImage];
    }
    if (eventImage) {
      promiseArr = [...promiseArr, uploadEventImage];
    }
    if (promiseArr.length) {
      Promise.allSettled(promiseArr).then((res) => {
        if (res?.[0].status === "fulfilled") {
          eventHostImage = res[0].value?.data?.name;
        }
        if (res?.[1].status === "fulfilled") {
          eventImage = res[1].value?.data?.name;
        }

        finalData = { ...finalData, eventHostImage, eventImage };
        eventUpdateHandler(finalData);
      });
    } else {
      eventUpdateHandler(finalData);
    }
  };

  const eventUpdateHandler = (data) => {
    dispatch(eventUpdate(id, data))
      .then((res) => {
        navigate("/event");
        toast.success(res.message);
      })
      .catch((res) => toast.error(res.message));
  };
  return (
    <>
      <div>
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Event Edit </h2>
        </div>
        <div className="section-inner padding-area event-section">
          <div className="form-area">
            <Form
              mutators={{
                // expect (field, value) args from the mutator
                setValue: ([field, value], state, { changeValue }) => {
                  changeValue(state, field, () => value);
                },
              }}
              onSubmit={onSubmit}
              validate={(value) => validate(value, EventDetail?.[0]?.eventType)}
            >
              {({ handleSubmit, form, values }) => {
                formRef.current = form;
                return (
                  <form onSubmit={handleSubmit}>
                    <div className="input-list d-flex flex-wrap">
                      <div className="col-md-6 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Name
                        </label>
                        <Field
                          name="eventname"
                          type="text"
                          placeholder="Event Name"
                          component={renderField}
                          className="form-grp"
                          inputclass="input-box"
                          address
                        />
                      </div>
                      <div className="col-md-6 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Host Name
                        </label>
                        <Field
                          name="eventhostname"
                          type="text"
                          placeholder="Event Host Name"
                          component={renderField}
                          className="form-grp"
                          inputclass="input-box"
                        />
                      </div>
                      <div className="col-md-6 col-div">
                        <label className="label-text">
                          {" "}
                          Event Host Phone Number
                        </label>
                        <Field
                          name="eventnumber"
                          type="text"
                          placeholder="Event Host Phone Number"
                          component={renderNumberField}
                          formatStyle={"(###) ###-####"}
                          className="form-grp"
                          inputclass="input-box"
                        />
                      </div>
                      <div className="col-md-6 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Host Email Address
                        </label>
                        <Field
                          name="eventhostemailaddresh"
                          type="text"
                          placeholder="Event Host Email Address"
                          component={renderField}
                          className="form-grp"
                          inputclass="input-box"
                        />
                      </div>

                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Description
                        </label>
                        <Field
                          name="eventdescription"
                          type="text"
                          placeholder="Event Description"
                          component={rendertextarea}
                          className="form-grp"
                          inputclass="input-box text-area-box "
                        />
                      </div>
                      <div className="col-md-6 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Date{" "}
                        </label>
                        <DatePicker
                          minDate={new Date(moment().startOf("day"))}
                          selected={startDate}
                          onChange={(date) => setStartDate(date)}
                          className="input-box"
                          placeholderText="Select Date"
                        />
                        <span className="text-danger">{dateerror}</span>
                      </div>
                      <div className="col-md-3 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Start Time{" "}
                        </label>
                        <input
                          type="time"
                          className="input-box react-datepicker-ignore-onclickoutside"
                          value={time}
                          onChange={(e) => setTime(e.target.value)}
                        />
                        <span className="text-danger">{timeerror}</span>
                      </div>
                      <div className="col-md-3 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event End Time{" "}
                        </label>
                        <input
                          type={"time"}
                          className="input-box react-datepicker-ignore-onclickoutside"
                          value={endTime}
                          onChange={(e) => {
                            setendTime(e.target.value);
                          }}
                        />
                        <span className="text-danger">{endTimeError}</span>
                      </div>
                      {!Change?.isStrategic && (
                        <div className="col-12 col-div">
                          <label className="label-text">
                            {" "}
                            <span>*</span> Select Community{" "}
                          </label>
                          <Field
                            name="community"
                            component={RenderSelectField}
                            className="basic-multi-select"
                            options={community}
                            isMulti={false}
                          />
                        </div>
                      )}
                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Type{" "}
                        </label>
                        <div className="checkbox-list d-flex flex-wrap">
                          <Field
                            name="radio1"
                            type="radio"
                            value="offline"
                            className="check-label"
                            setActive={setActive}
                            component={renderRadioButton}
                          >
                            In-Person Event
                          </Field>
                          <Field
                            name="radio1"
                            type="radio"
                            value="virtual"
                            className="check-label"
                            setActive={setActive}
                            component={renderRadioButton}
                          >
                            Virtual Event
                          </Field>
                        </div>
                      </div>
                      {active === "offline" ? (
                        <div className="col-md-6 col-div">
                          <label className="label-text">
                            {" "}
                            <span>*</span> Event Address
                          </label>
                          <Field
                            name="eventAddress"
                            type="text"
                            placeholder="Event Address"
                            component={renderField}
                            className="form-grp marginClass"
                            inputclass="input-box"
                          />
                          <Field
                            name="eventAddress2"
                            type="text"
                            placeholder="Event Address Line 2"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          />
                          <div className="inner-form-list">
                            <EditSelectField
                              name="country"
                              placeholder="Country"
                              isCountryField={true}
                              data={selectedCountry}
                              setselectedCountry={setselectedCountry}
                              className="form-grp col-grp formGrp"
                              inputclass="input-box slectStep"
                              options={countryList}
                              disabled={true}
                              isMulti={false}
                              style={{ border: "none" }}
                            />

                            <Field
                              name="pincode"
                              // type="number"
                              placeholder="Zip code"
                              component={renderNumberField}
                              formatStyle={"#####"}
                              className="form-grp col-grp"
                              inputclass="input-box"
                            />
                            <EditSelectField
                              placeholder="State"
                              isStateField={true}
                              data={selectedState}
                              setselectedCity={setselectedCity}
                              setselectedState={setselectedState}
                              className="form-grp col-grp formGrp"
                              inputclass="input-box slectStep"
                              options={stateList}
                              isMulti={false}
                              style={{ border: "none" }}
                            />
                            {addressError.state && (
                              <div className="input-error">
                                <span className="mb-0 text-danger">
                                  Please select state
                                </span>
                              </div>
                            )}
                            <EditCityField
                              placeholder="City"
                              data={selectedCity}
                              setselectedCity={setselectedCity}
                              className="form-grp col-grp formGrp"
                              inputclass="input-box slectStep"
                              options={cityList}
                              isMulti={false}
                              style={{ border: "none" }}
                            />
                            {addressError.city && (
                              <div className="input-error">
                                <span className="mb-0 text-danger">
                                  Please select city
                                </span>
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div className="col-12 col-div">
                          <label className="label-text">
                            {" "}
                            <span>*</span> Event Link
                          </label>
                          <Field
                            name="eventLink"
                            type="text"
                            placeholder="Event Link"
                            component={renderField}
                            className="form-grp"
                            inputclass="input-box"
                          />
                        </div>
                      )}
                      <div className="col-md-6 img-col col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Host Image{" "}
                        </label>
                        <div className="upload-div">
                          <ImgUpload
                            multiple={false}
                            setImages={setEventHostImages}
                            images={eventHostImages}
                            oldImages={oldEventHostImages}
                            oldLogo={
                              metaData?.mediaURL +
                              "eventHostImages/" +
                              EventDetail?.[0]?.eventHostImage
                            }
                            moduleName="eventHostImages"
                            callUploadAPI={false}
                          />
                        </div>
                      </div>
                      <div className="col-12 col-div">
                        <label className="label-text">
                          {" "}
                          <span>*</span> Event Image{" "}
                        </label>
                        <div className="upload-div">
                          <ImgUpload
                            multiple={false}
                            setImages={setEventImages}
                            images={eventImages}
                            oldImages={oldEventImages}
                            oldLogo={
                              metaData?.mediaURL +
                              "eventImages/" +
                              EventDetail?.[0]?.eventImage
                            }
                            moduleName="eventImages"
                            callUploadAPI={false}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="d-flex flex-wrap form-btn-box position-box">
                      <Link to="/event" className="link-btn cancel">
                        Cancel
                      </Link>
                      <button type="submit" className="link-btn">
                        Save
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventEdit;
