import React from 'react'

const Videos = () => {
  return (
    <>
      <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M30.0625 5.62773L25.5625 8.21875V2.875C25.5625 1.63398 24.5535 0.625 23.3125 0.625H2.5C1.25898 0.625 0.25 1.63398 0.25 2.875V23.125C0.25 24.366 1.25898 25.375 2.5 25.375H23.3125C24.5535 25.375 25.5625 24.366 25.5625 23.125V17.7812L30.0625 20.3723C30.8113 20.8047 31.75 20.2633 31.75 19.402V6.60156C31.75 5.73672 30.8113 5.19531 30.0625 5.62773ZM9.53125 7.375C9.53125 7.52969 9.40469 7.65625 9.25 7.65625H5.3125C5.15781 7.65625 5.03125 7.52969 5.03125 7.375V5.6875C5.03125 5.53281 5.15781 5.40625 5.3125 5.40625H9.25C9.40469 5.40625 9.53125 5.53281 9.53125 5.6875V7.375ZM29.2188 16.9727L25.5625 14.8703V11.1332L29.2188 9.02734V16.9727Z" fill="#42A44C" fillOpacity="0.86"/>
      </svg>

    </>
  )
}

export default Videos
