import { NOTIFICTION_LIST_F, NOTIFICTION_LIST_S } from "../constants/types";

const initialState = {
notifictionlist:[]
}

const NotifictionReducer = (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICTION_LIST_S:
            return {
                ...state, notifictionlist: action.payload.data
            }
        case NOTIFICTION_LIST_F:
            return {
                ...state, notifictionlist: action.payload.message
            }
        default:
            return state;
        // case HOMEPAGE_DETAIL_S:
        //     return {
        //         ...state, homepagedetail: action.payload.data
        //     }
        // case HOMEPAGE_UPDATE_F:
        //     return {
        //         ...state, homepagedetail: action.payload.message
        //     }
    }
}
export default NotifictionReducer;
