import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../components/layout/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./view.scss";
import { useParams } from "react-router-dom";
import Interested from "./tabs/Interested";
import Going from "./tabs/Going";
import NotGoing from "./tabs/NotGoing";
import { useDispatch, useSelector } from "react-redux";
import { eventDetail, eventmemberlist } from "../../../actions/event";
import moment from "moment";
import { formatPhoneNumber } from "../../../utils";

const list = [
  {
    name: "Event Management",
    link: "/event",
    isactive: false,
  },
  {
    name: "Event View",
    link: "/event/event_view",
    isactive: true,
  },
];

const EventView = () => {
  const metaData = useSelector((state) => state.metaReducer);

  const EventDetail = useSelector((state) => state.EventReducer.eventdetails);
  const dispatch = useDispatch();
  const { id } = useParams();

  const [ismodel, setmodel] = useState(false);
  useEffect(() => {
    const data = {
      _id: id,
    };
    dispatch(eventDetail(id, data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handlememeberlist = () => {
    dispatch(eventmemberlist(id)).then((res) =>
      window.open(metaData?.mediaURL + res.data.URL)
    );
  };
  return (
    <>
      <div className="event-view">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Event View </h2>
        </div>
        <div className="section-inner ">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-box">
              <div className="d-flex flex-wrap view-inner-box">
                <div className="col-md-6 col-box">
                  <div className="view-leftbox">
                    <div>
                      <figure className="help-img">
                        <img
                          src={
                            metaData?.mediaURL +
                            "eventImages/" +
                            EventDetail?.[0]?.eventImage
                          }
                          alt="event-img"
                        />
                      </figure>
                      <div className="inner-views">
                        <div className="event-info-box">
                          <div>
                            <label className="label-text">Event Name:</label>{" "}
                            {EventDetail?.[0]?.eventName}
                          </div>
                          {EventDetail?.[0]?.communityName &&<div>
                            <label className="label-text">
                              Community Name :
                            </label>{" "}
                            {EventDetail?.[0]?.communityName}
                          </div>}
                        </div>

                        <div class="add-box">
                          <ul class="add-list">
                            <li class="date">
                              Event on{" "}
                              <span>
                                {moment(EventDetail?.[0]?.startDate).format(
                                  " DD-MM-YYYY"
                                )}
                              </span>
                            </li>
                            <li class="time">
                              Event Start Time{" "}
                              <span>
                                {moment(EventDetail?.[0]?.startDate).format(
                                  " hh:mm a "
                                )}
                              </span>
                            </li>
                            <li class="time">
                              Event End Time{" "}
                              <span>
                                {moment(EventDetail?.[0]?.eventEndTime).format(
                                  " hh:mm a "
                                )}
                              </span>
                            </li>
                            {EventDetail?.[0]?.eventHostPhoneNo ? (
                              <li class="phone">
                                <a
                                  href={`tel: ${EventDetail?.[0]?.eventHostPhoneNo}`}
                                >
                                  {formatPhoneNumber(
                                    EventDetail?.[0]?.eventHostPhoneNo?.toString()
                                  )}
                                </a>
                              </li>
                            ) : (
                              ""
                            )}
                            <li class="mail">
                              {" "}
                              <a
                                href={`mailto: ${EventDetail?.[0]?.eventHostEmail}`}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {EventDetail?.[0]?.eventHostEmail}{" "}
                              </a>
                            </li>
                          </ul>
                          <p>
                            {" "}
                            {EventDetail?.[0]?.eventType === "offline" ? (
                              `${EventDetail?.[0]?.eventLocation?.street},${" "}${EventDetail?.[0]?.eventLocation?.city},${" "}${EventDetail?.[0]?.eventLocation?.state},${" "}${EventDetail?.[0]?.eventLocation?.zipcode}`
                            ) : (
                              <a
                                href={`${
                                  EventDetail?.[0]?.eventLink
                                    ?.toString()
                                    ?.indexOf("http") !== -1
                                    ? EventDetail?.[0]?.eventLink
                                    : `https://${EventDetail?.[0]?.eventLink}`
                                }`}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {EventDetail?.[0]?.eventLink}
                              </a>
                            )}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="right-inner-box">
                    <div className="list-member">
                      <h4>Event Creator </h4>
                      <div className="d-flex flex-wrap title-img-box">
                        <figure className="create-img-box">
                          <img
                            src={
                              metaData?.mediaURL +
                              "eventHostImages/" +
                              EventDetail?.[0]?.eventHostImage
                            }
                            alt="logo-img"
                          />
                        </figure>
                        <h5>
                          {" "}
                          <span>Event By</span>{" "}
                          {EventDetail?.[0]?.eventHostname}
                        </h5>
                      </div>
                    </div>
                    <div className="view-about-box">
                      <h4>About Events</h4>
                      <p>{EventDetail?.[0]?.description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 pl-0">
              <div className="view-rightbox">
                <h2>Member Detail</h2>
                <button
                  onClick={() => handlememeberlist()}
                  className="link-btn member-btn"
                >
                  {" "}
                  Export Excel{" "}
                </button>
                <Tabs>
                  <TabList>
                    <Tab>Interested</Tab>
                    <Tab>Going</Tab>
                    <Tab>Not Going</Tab>
                  </TabList>

                  <TabPanel>
                    <Interested ismodel={ismodel} setmodel={setmodel} />
                  </TabPanel>
                  <TabPanel>
                    <Going ismodel={ismodel} setmodel={setmodel} />
                  </TabPanel>
                  <TabPanel>
                    <NotGoing ismodel={ismodel} setmodel={setmodel} />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Dmodel name="Are you sure you want to remove event" setmodel={setmodel} ismodel={ismodel} className="d-model" /> */}
    </>
  );
};

export default EventView;
