import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import {
  API_KEY,
  APPID,
  AUTH_DOMAIN,
  MEASURMENTID,
  MESSAGINGID,
  PROJECT_ID,
  STORAGE_BUCKET,
  VAPIDKEY,
} from "../constants/api";

const firebaseConfig = {
  apiKey: API_KEY,
  authDomain: AUTH_DOMAIN,
  projectId: PROJECT_ID,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGINGID,
  appId: APPID,
  measurementId: MEASURMENTID,
};

let is_ios_web_view = /(iPhone|iPod|iPad).*AppleWebKit(?!.*Version)/i.test(
  navigator.userAgent
);

const firebaseApp = !is_ios_web_view && initializeApp(firebaseConfig);
export const messaging = !is_ios_web_view && getMessaging(firebaseApp);

export const getFCMToken = (setTokenFound, setFcmToken) => {
  if (!is_ios_web_view) {
    return getToken(messaging, { vapidKey: `${VAPIDKEY}` })
      .then((currentToken) => {
        if (currentToken) {
          setTokenFound(true);
          setFcmToken(currentToken);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        // catch error while creating client token
      });
  } else {
    setFcmToken("");
  }
};

export const onMessageListener = () => {
  if (!is_ios_web_view)
    return new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });
};
