import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/layout/Breadcrumb";
import DataTable from "react-data-table-component";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import "../Master/Charitys/charitys.scss";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import "./transaction.scss";
import {
  communityTransactionExcel,
  communitytransactionListAction,
} from "../../actions/transaction";
import { amountFormate, debounce } from "../../utils";
import Chat from "../../components/svg/chat";
import { useNavigate } from "react-router-dom";

const list = [
  {
    name: "Community Transaction",
    link: "/communitytransaction",
    isactive: false,
  },
  {
    name: "Community Transaction List",
    link: "/transaction",
    isactive: true,
  },
];
const CommunityTransaction = () => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const metaData = useSelector((state) => state.metaReducer);
  const charityId = JSON.parse(localStorage.getItem("userData"))?.data?._id;
  const navigate = useNavigate();
  const [totalRecords, settotalRecords] = useState(0);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    search: "",
    column: "",
    order: "",
    activeFrom: startDate || null,
    activeTo: endDate || null,
    charityId: Change?._id,
  });

  const dispatch = useDispatch();
  const List = useSelector(
    (state) => state.TransactionReducer.communitytransction.communityPaymentList
  );

  useEffect(() => {
    dispatch(communitytransactionListAction(arg)).then((res) => {
      settotalRecords(res?.data?.total_records || 0);
    });
  }, [arg]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  const onSortChange = (column, direction) => {
    setArg({ ...arg, column: column?.sortName, order: direction });
  };

  const handleDateChange = async (date) => {
    if (date) {
      setEndDate(
        new Date(moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss"))
      );
      setArg({
        ...arg,
        activeTo: moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss"),
      });
    } else {
      setEndDate(null);
      setArg({
        ...arg,
        activeTo: null,
      });
    }
  };
  const onMessageBtnClick = (e, row) => {
    e.stopPropagation();
    navigate("/chat", {
      state: {
        from: "transction",
        sendToId: row?.userId,
        name: row?.userName,
      },
    });
  };
  const customStyles = {
    headCells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  };

  const handlelist = () => {
    const data = {
      activeFrom: startDate
        ? moment(startDate).startOf("day").format("MM/DD/YYYY HH:mm:ss")
        : null,
      activeTo: endDate
        ? moment(endDate).endOf("day").format("MM/DD/YYYY HH:mm:ss")
        : null,
    };

    dispatch(communityTransactionExcel(charityId, data)).then((res) =>
      window.open(metaData?.mediaURL + res.data.URL)
    );
  };

  const columns = [
    {
      name: " Community Name",
      sortName: "communityName",
      selector: (row) => row.communityName,
      sortable: true,
    },
    {
      name: "Donor Name",
      selector: (row) => row.userFullName,
    },
    {
      name: "Date and Time of Transaction",

      selector: (row) =>
        moment(row.createdDate).local().format("MMM D YYYY,h:mm a"),
    },
    {
      name: "Net Amount of Donation",
      selector: (row) => (
        <div className="status-box">
          {row.percentageWiseAmount >= 0 ? (
            <span className="amount">
              ${amountFormate(row.percentageWiseAmount)}
            </span>
          ) : (
            ""
          )}
        </div>
      ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-box">
          <button
            className="btn right"
            title="message"
            onClick={(e) => onMessageBtnClick(e, row)}
          >
            {" "}
            <Chat />{" "}
          </button>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div>
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2> Community Transaction </h2>
        </div>
        <div className="section-inner transaction-section">
          <div className="d-flex align-items-center justify-content-between top-areabox">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search By Community/Donor Name"
                className="search-input"
                onChange={(e) => optimizedFn(e.target.value)}
              />
            </div>
            <div className="div-btn-box">
              <div>
                <label className="date-lable">From:</label>
                <DatePicker
                  placeholderText="Start Date"
                  selected={startDate}
                  onChange={(date) => {
                    if (date) {
                      setStartDate(
                        new Date(
                          moment(date)
                            .startOf("day")
                            .format("MM/DD/YYYY HH:mm:ss")
                        )
                      );
                      setArg({
                        ...arg,
                        activeFrom: moment(date)
                          .startOf("day")
                          .format("MM/DD/YYYY HH:mm:ss"),
                      });
                    } else {
                      setStartDate(null);
                      setArg({
                        ...arg,
                        activeFrom: null,
                      });
                    }
                  }}
                  selectsStart
                  maxDate={new Date(moment())}
                  className="search-input"
                />
              </div>
              <span style={{ margin: "0 10px", paddingTop: "15px" }}> </span>

              <div>
                <label className="date-lable">To:</label>
                <DatePicker
                  placeholderText="End Date"
                  selected={endDate}
                  onChange={handleDateChange}
                  selectsEnd
                  maxDate={new Date(moment())}
                  minDate={startDate}
                  className="search-input"
                />
              </div>

              <button
                type="button"
                className="btn export"
                title="export"
                onClick={handlelist}
              >
                {" "}
                Export Excel{" "}
              </button>
            </div>
          </div>
          <DataTable
            border
            columns={columns}
            data={List}
            onSort={onSortChange}
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
          />
        </div>
      </div>
    </>
  );
};
export default CommunityTransaction;
