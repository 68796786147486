import {
  API_COMMUNITY_CREAT,
  API_COMMUNITY_DELETE,
  API_COMMUNITY_DETAIL,
  API_COMMUNITY_EDIT,
  API_COMMUNITY_HIDE,
  API_COMMUNITY_LIST,
} from "../constants/api";
import {
  API,
  COMMUNITY_CREAT_F,
  COMMUNITY_CREAT_S,
  COMMUNITY_DELETE_F,
  COMMUNITY_DELETE_S,
  COMMUNITY_DETAIL_F,
  COMMUNITY_DETAIL_S,
  COMMUNITY_EDIT_F,
  COMMUNITY_EDIT_S,
  COMMUNITY_LIST_F,
  COMMUNITY_LIST_S,
} from "../constants/types";

export const communityList = (id, data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_LIST + `${id}`,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_LIST_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_LIST_F,
      payload: data,
    }),
  },
});

export const communityCreat = (data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_CREAT,
    method: "POST",
    data: data,
    success: (data) => ({
      type: COMMUNITY_CREAT_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_CREAT_F,
      payload: data,
    }),
  },
});

export const communityDetail = (id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_DETAIL + `${id}`,
    method: "GET",
    success: (data) => ({
      type: COMMUNITY_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_DETAIL_F,
      payload: data,
    }),
  },
});

export const communityDelete = (id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_DELETE + `${id}`,
    method: "DELETE",
    success: (data) => ({
      type: COMMUNITY_DELETE_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_DELETE_F,
      payload: data,
    }),
  },
});

export const EditCommunityDetail = (data) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_EDIT,
    method: "PATCH",
    data,
    success: (data) => ({
      type: COMMUNITY_EDIT_S,
      payload: data,
    }),
    error: (data) => ({
      type: COMMUNITY_EDIT_F,
      payload: data,
    }),
  },
});

export const communityhideunhide = (id) => ({
  type: API,
  payload: {
    url: API_COMMUNITY_HIDE + id,
    method: "POST",
  },
});
