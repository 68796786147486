/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Player } from "video-react";
import {
  employeePostList,
  onPostCommentLikeUnlike,
  postCommment,
  postCommmentList,
  postCommmentReply,
  postLike,
  postUpdate,
  strategicPostList,
} from "../../../actions/post";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { getVideoUrl, OutSideClick, toAbsoluteUrl } from "../../../utils";
import Dmodel from "../delete";
import Comment from "../../svg/comment";
import { Autoplay, Pagination, Navigation } from "swiper";
import "../../../pages/Community/view/view.scss";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PostModal from "./PostModal";
import Heart from "../../svg/heart";
import { useParams } from "react-router-dom";
import Linkify from "react-linkify";
import { doUploadImage, doUploadVideo } from "../../../actions/registration";

const Post = ({ data, isMyPost, setisLoading, from }) => {
  const Change = useSelector((state) => state.auth?.user?.data);

  const strategicdetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const metaData = useSelector((state) => state.metaReducer);
  const formRef = useRef();
  const listref = useRef();
  const { id } = useParams();
  const dispatch = useDispatch();
  const [ishide, sethide] = useState(0);
  const [ismodel, setmodel] = useState(false);
  const [isdeletemodel, setDeletemodel] = useState(false);
  const [isvideo, setisVideo] = useState(false);
  const [PostImages, setPostImages] = useState(data?.image);
  const [imageFiles, setImageFiles] = useState([]);
  const [deleteImage, setDeleteImage] = useState([]);
  const [PostVideos, setPostVideos] = useState(data?.video);
  const [videoSourceURLs, setVideoSourceURLs] = useState([]);
  const [videoSourceFiles, setVideoSourceFiles] = useState([]);
  const [deletedVideoArray, setDeletedVideoArray] = useState([]);
  const [arg, setArg] = useState({
    page: 1,
    limit: 3,
  });
  const [viewmore, setViewMore] = useState(true);
  const [replycomment, setReplyComment] = useState("");
  const [viewReplyComment, setViewReplyComment] = useState();
  const [comment, setComment] = useState("");
  const [replycommentText, setReplyCommentText] = useState("");
  const [commentList, setCommentList] = useState(false);
  const [commentdetail, setCommentdetail] = useState();
  const [readDesc, setReadDesc] = useState(true);

  const handleClick = (e) => {
    if (e.target.id === ishide) sethide(0);
    else sethide(e.target.id);
  };

  const showCommentHandler = (resetLimit) => {
    if (resetLimit === true && viewmore === true) {
      setArg({ ...arg, limit: 1000000 });
      setViewMore((prev) => !prev);
    } else if (resetLimit === true && viewmore === false) {
      setArg({ ...arg, limit: 3 });
      setViewMore((prev) => !prev);
    }

    if (resetLimit === false && commentList === true) {
      setCommentList((prev) => !prev);
      return;
    }
    if (resetLimit === false && replycomment === data?._id) {
      setReplyComment(null);
    } else {
      setReplyComment(data?._id);
    }
  };

  useEffect(() => {
    if (replycomment === data?._id) {
      dispatch(postCommmentList(data?._id, arg)).then((res) => {
        setCommentdetail(res.data);
        if (res.data.comment.length === 0) {
          setCommentList(false);
        } else setCommentList(true);
      });
    }
  }, [dispatch, arg, replycomment]);

  const handleEdit = () => {
    if (data?.type === 2) {
      setisVideo(true);
    }
    setPostVideos(data?.video);
    setPostImages(data?.image);
    setmodel((prev) => !prev);
  };

  const handleDelete = () => {
    setDeletemodel(true);
  };

  const viewCommentReplyHandler = (id) => {
    if (viewReplyComment === id) {
      setViewReplyComment(null);
    } else {
      setViewReplyComment(id);
    }
  };

  const imageUploadHandler = () => {
    return imageFiles.map((image, ind) => {
      const payload = { image: [image], moduleName: "postImages" };
      return dispatch(doUploadImage(payload));
    });
  };

  const videoUploadHandler = () => {
    return videoSourceFiles?.map((file) =>
      dispatch(
        doUploadVideo({
          moduleName: "postImages",
          video: file,
        })
      )
    );
  };

  const onPostSubmit = (value) => {
    if (data?.type === 3 && !value.Caption) {
      return toast.error("Please Enter Caption");
    }
    if (
      !value.Caption &&
      isvideo === false &&
      PostImages.length === 0 &&
      imageFiles.length === 0
    ) {
      return toast.error("Please attach Image");
    }
    if (!value.Caption && isvideo === true && videoSourceFiles.length === 0) {
      return toast.error("Please attach Video");
    }
    const postImages = [];
    let updateData = {
      _id: data?._id,
      description: value.Caption === undefined ? "" : value.Caption,
      image: [],
      removeImage: deleteImage,
      type: data?.type,
      video: PostVideos,
      removeVideo: [],
    };

    if (imageFiles.length) {
      Promise.allSettled([...imageUploadHandler()]).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            postImages.push({
              name: value.value?.data?.name,
              url: metaData?.mediaURL + "postImages/" + value.value?.data?.name,
            });
          }
        });
        data = { ...updateData, image: postImages };
        editPostHandler(data);
      });
    } else if (videoSourceFiles.length) {
      const videoPayload = [];
      Promise.allSettled(videoUploadHandler()).then((res) => {
        res.forEach((value) => {
          if (value.status === "fulfilled") {
            videoPayload.push({
              name: value.value?.data?.name,
              url: getVideoUrl(
                metaData.mediaURL,
                "postImages",
                value.value?.data?.name
              ),
              thumbnail: value.value?.data.thumbnail,
            });
          }
          return null;
        });
        updateData = {
          ...updateData,
          video: videoPayload,
          removeVideo: deletedVideoArray,
        };
        editPostHandler(updateData);
      });
    } else {
      editPostHandler(updateData);
    }
  };

  const editPostHandler = (data) => {
    dispatch(postUpdate(data?._id, data)).then((res) => {
      if (from === "employee") {
        dispatch(employeePostList(id, { page: 1, limit: 1000000 }));
      } else {
        setisLoading(true);
        dispatch(
          strategicPostList(
            strategicdetail?._id,
            { page: 1, limit: 1000000 },
            !isMyPost
          )
        )
          .then(() => setisLoading(false))
          .catch(() => setisLoading(false));
      }
      setmodel(!ismodel);
      toast.success(res.message);
      if (formRef?.current?.reset) {
        formRef?.current?.reset();
      }
      setImageFiles([]);
    });
  };

  const onPostLikeUnlike = () => {
    dispatch(
      postLike(data?._id.toString(), !data?.islike ? "like" : "unlike")
    ).then((res) => {
      if (from === "employee") {
        dispatch(employeePostList(id, { page: 1, limit: 1000000 }));
      } else {
        dispatch(
          strategicPostList(
            strategicdetail?._id,
            { page: 1, limit: 1000000 },
            !isMyPost
          )
        );
      }
    });
  };

  const onPostcomment = () => {
    const commentdata = {
      postId: data?._id,
      userId: Change?._id,
      comment: comment,
      isReply: false,
    };
    dispatch(postCommment(commentdata)).then((res) => {
      setComment("");
      dispatch(postCommmentList(data?._id, arg)).then((res) =>
        setCommentdetail(res.data)
      );
      if (from === "employee") {
        dispatch(employeePostList(id, { page: 1, limit: 1000000 }));
      } else {
        dispatch(
          strategicPostList(
            strategicdetail?._id,
            { page: 1, limit: 1000000 },
            !isMyPost
          )
        );
      }
    });
  };

  const onPostcommentReply = (val) => {
    const commentdata = {
      postId: val?.postId,
      userId: Change?._id,
      commentId: val?._id,
      comment: replycommentText,
      isReply: true,
    };
    dispatch(postCommmentReply(commentdata)).then((res) => {
      setReplyComment("");
      setReplyCommentText("");
      dispatch(postCommmentList(data?._id, arg)).then((res) =>
        setCommentdetail(res.data)
      );
      if (from === "employee") {
        dispatch(employeePostList(id, { page: 1, limit: 1000000 }));
      } else {
        dispatch(
          strategicPostList(
            strategicdetail?._id,
            { page: 1, limit: 1000000 },
            !isMyPost
          )
        );
      }
    });
  };

  const onPostCommentLike = (val) => {
    dispatch(onPostCommentLikeUnlike(val?._id)).then((res) => {
      dispatch(postCommmentList(data?._id, arg)).then((res) =>
        setCommentdetail(res.data)
      );
    });
  };
  const pagination = {
    clickable: true,
  };

  const toggalecomment = (val) => {
    if (replycomment === val) {
      setReplyComment("");
    } else {
      setReplyComment(val);
    }
  };

  OutSideClick(listref, () => {
    sethide(0);
  });

  const handlePostImage = () => {
    if (data?.createdBy === 5) {
      return data?.userDetails?.profileImage ? (
        <img
          src={
            metaData?.mediaURL +
            "userProfilePic/" +
            data?.userDetails?.profileImage
          }
          alt="world-img"
        />
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    } else if (data?.createdBy === 3) {
      return data?.strategicPartnerDetails?.profileImage ? (
        <img
          src={
            metaData?.mediaURL +
            "strategicLogo/" +
            data?.strategicPartnerDetails?.profileImage
          }
          alt="world-img"
        />
      ) : (
        <img src={toAbsoluteUrl("/images/placeholder.png")} alt="placeholder" />
      );
    }
  };

  // Post Comment Image show Function
  const handlecommentimage = (val) => {
    switch (val) {
      case "Strategic":
        return "strategicLogo/";
      case "Donor":
        return "userProfilePic/";
      case "Rosi Giving":
        return "adminImages/";
      default:
        break;
    }
  };

  const renderVideoHandler = () => {
    return (
      <>
        {data?.type === 2
          ? data?.video.map((val) => (
              <figure className="post-img" key={val?.url}>
                <Player
                  poster={
                    metaData?.mediaURL +
                    "postImages/videos/thumbnail/" +
                    val?.thumbnail
                  }
                  height={"100%"}
                  width={"100%"}
                  autoPlay={false}
                  playsInline
                >
                  <source src={val?.url}></source>
                </Player>
              </figure>
            ))
          : ""}
      </>
    );
  };

  const replyCommentHandler = (val) => {
    return (
      <>
        {viewReplyComment === val?._id
          ? val?.replyCommnet.map((reply, i) => (
              <li className="comment-bar children-comment-bar" key={reply?._id}>
                <div className="d-flex">
                  <figure>
                    <img
                      src={
                        reply?.userDetails?.[0]?.profileImage
                          ? metaData?.mediaURL +
                            handlecommentimage(reply?.userDetails?.[0]?.title) +
                            reply?.userDetails?.[0]?.profileImage
                          : toAbsoluteUrl("/images/placeholder.png")
                      }
                      alt="leader"
                    />
                  </figure>
                  <div className="comment-wrap">
                    <h3 className="commentPersonName">
                      {reply?.userDetails?.[0]?.title === "Donor"
                        ? reply?.userDetails[0]?.firstName +
                          " " +
                          reply?.userDetails[0]?.lastName
                        : reply?.userDetails[0]?.firstName}
                    </h3>
                    <p>
                      {moment(reply?.createdDate)
                        .local()
                        .format(" D MMM YYYY, h:mm a")}
                    </p>
                  </div>
                </div>
                <div className="postCommentAction">
                  <p>
                    {" "}
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                        </a>
                      )}
                    >
                      {reply?.comment}
                    </Linkify>
                  </p>
                  <div className="d-flex flex-wrap align-items-center">
                    <figure
                      className={`social-img  ${
                        reply?.islike === true ? "hearts" : "causeHeart"
                      } `}
                      onClick={() => onPostCommentLike(reply)}
                    >
                      <Heart />
                    </figure>
                    <span>{reply?.likeCount} Like&nbsp;</span>
                    <div onClick={() => toggalecomment(reply?._id)}>
                      <span>Reply</span>
                    </div>
                  </div>
                </div>

                {replycomment === reply?._id && (
                  <div className="comment-box">
                    <input
                      className="input-box comment-input"
                      placeholder="Write your Reply comment....."
                      type="text"
                      value={replycommentText}
                      onChange={(e) => setReplyCommentText(e.target.value)}
                    />
                    <button
                      type="button"
                      className="btn-text"
                      onClick={() => onPostcommentReply(val)}
                    >
                      Add Your Comment
                    </button>
                  </div>
                )}
              </li>
            ))
          : ""}
      </>
    );
  };

  return (
    <>
      <li>
        <div className="box-post">
          <div className="title-post">
            {/* <div className="d-flex"> */}
            <figure className="post-img round-box">{handlePostImage()}</figure>
            <div className="small-title">
              <h5>
                {data?.createdBy === 5
                  ? data?.userDetails?.firstName +
                    " " +
                    data?.userDetails?.lastName
                  : data?.strategicPartnerDetails?.firstName}
              </h5>
              <span>
                {moment(data?.createdDate)
                  .local()
                  .format(" D MMM YYYY, h:mm a")}
              </span>
            </div>
            {/* </div> */}
            <div className="post-menu-box" ref={listref}>
              <ul className="dots-li" id={1} onClick={handleClick}>
                <li></li>
                <li></li>
                <li></li>
              </ul>
              <ul
                // eslint-disable-next-line eqeqeq
                className={`submenu-list ${ishide == 1 ? " open-menu " : ""}`}
              >
                <li>
                  {" "}
                  <div className="post-li" type="button" onClick={handleDelete}>
                    {" "}
                    Delete Post{" "}
                  </div>{" "}
                </li>
                <li>
                  <div className="post-li" type="button" onClick={handleEdit}>
                    {" "}
                    Edit Post{" "}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="inner-postbox-strategic">
            <p className={!readDesc ? "" : "readLess"}>
              <Linkify
                componentDecorator={(decoratedHref, decoratedText, key) => (
                  <a target="blank" href={decoratedHref} key={key}>
                    {decoratedText}
                  </a>
                )}
              >
                {data?.description}
              </Linkify>
            </p>
            {data?.description?.length ? (
              <button
                className={
                  data?.description.trim().length < 200
                    ? "hideBtn"
                    : "showbutton"
                }
                onClick={() => setReadDesc((prev) => !prev)}
              >
                {readDesc ? "Read More" : "Read Less"}
              </button>
            ) : (
              ""
            )}
            {data?.type === 1 ? (
              <Swiper
                spaceBetween={50}
                slidesPerView={1}
                pagination={pagination}
                modules={[Autoplay, Pagination, Navigation]}
                navigation={true}
              >
                {data?.image.map((images, i) => (
                  <SwiperSlide key={images?.url}>
                    <figure className="post-img">
                      <img src={images?.url} alt="post-imag" />
                    </figure>
                  </SwiperSlide>
                ))}
              </Swiper>
            ) : (
              renderVideoHandler()
            )}
          </div>
          <div className="social-box">
            <div>
              <ul className="social-list">
                <li>
                  <div className="d-flex flex-wrap align-items-center">
                    <figure
                      className={`social-img  ${
                        data?.islike ? "hearts" : "causeHeart"
                      } `}
                      onClick={onPostLikeUnlike}
                    >
                      <Heart />
                    </figure>
                    <span>{data?.countLike}</span>
                  </div>
                </li>
                <li>
                  <div className="d-flex flex-wrap align-items-center">
                    <figure className="social-img">
                      <Comment />
                    </figure>
                    <span onClick={() => showCommentHandler(false)}>
                      {data?.countComment} Comments
                    </span>
                  </div>
                </li>
              </ul>
              {/* comment-section */}
              {commentList === true ? (
                <div className="comment-section">
                  <ul className={`comment-sectionList read-commet-section`}>
                    {commentdetail?.comment.map((val, i) => (
                      <li className="comment-parent" key={val?._id}>
                        {/* Parent Comment */}
                        <ul>
                          <li className="comment-bar">
                            <div className="innerbox-div">
                              <div className="d-flex">
                                <figure>
                                  <img
                                    src={
                                      val?.userDetails?.[0]?.profileImage
                                        ? metaData?.mediaURL +
                                          handlecommentimage(
                                            val?.userDetails?.[0]?.title
                                          ) +
                                          val?.userDetails?.[0]?.profileImage
                                        : toAbsoluteUrl(
                                            "/images/placeholder.png"
                                          )
                                    }
                                    alt="leader"
                                  />
                                </figure>
                                <div className="comment-wrap">
                                  <h3 className="commentPersonName">
                                    {val?.userDetails?.[0]?.firstName +
                                      " " +
                                      val?.userDetails?.[0]?.lastName}
                                  </h3>
                                  <p>
                                    {moment(val?.createdDate)
                                      .local()
                                      .format(" D MMM YYYY, h:mm a")}
                                  </p>
                                </div>
                              </div>
                            </div>

                            <div className="postCommentAction postStart">
                              <p>
                                <Linkify
                                  componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key
                                  ) => (
                                    <a
                                      target="blank"
                                      href={decoratedHref}
                                      key={key}
                                    >
                                      {decoratedText}
                                    </a>
                                  )}
                                >
                                  {val?.comment}
                                </Linkify>
                              </p>
                              <div className="d-flex flex-wrap align-items-center w-100">
                                <figure
                                  className={`social-img ${
                                    val?.islike === true
                                      ? "hearts"
                                      : "causeHeart"
                                  } `}
                                  onClick={() => onPostCommentLike(val)}
                                >
                                  <Heart />
                                </figure>
                                <span>{val?.likeCount} Like&nbsp;</span>
                                {"  "}
                                <div onClick={() => toggalecomment(val?._id)}>
                                  <span
                                    onClick={() =>
                                      viewCommentReplyHandler(val?._id)
                                    }
                                  >
                                    {val?.replyCommnet.length} Reply
                                  </span>
                                </div>
                              </div>
                            </div>

                            {/* Parent Comment Box*/}
                            {replycomment === val?._id && (
                              <div className="comment-box">
                                <input
                                  className="input-box comment-input"
                                  placeholder="Write your Reply comment....."
                                  type="text"
                                  value={replycommentText}
                                  onChange={(e) =>
                                    setReplyCommentText(e.target.value)
                                  }
                                />
                                <button
                                  type="button"
                                  className="btn-text"
                                  onClick={() => onPostcommentReply(val)}
                                >
                                  Add Your Comment
                                </button>
                              </div>
                            )}
                          </li>

                          <div className="w-100">
                            <ul className={`inner-comment-box`}>
                              {/* Child Comment */}
                              {val?.replyCommnet.length !== 0
                                ? replyCommentHandler(val)
                                : ""}
                            </ul>
                          </div>
                        </ul>
                      </li>
                    ))}
                  </ul>
                  {commentdetail?.total_records[0].count > 3 ? (
                    <span
                      className="views-btns"
                      onClick={() => showCommentHandler(viewmore)}
                    >{`${!viewmore ? "View Less" : "View more"}`}</span>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {replycomment === data?._id && (
              <div className="comment-box">
                <input
                  className="input-box comment-input"
                  placeholder="Write your comment....."
                  type="text"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                />
                <button
                  type="button"
                  className="btn-text"
                  onClick={onPostcomment}
                >
                  Add Your Comment
                </button>
              </div>
            )}
          </div>
        </div>
      </li>

      <PostModal
        ismodel={ismodel}
        AllImages={PostImages}
        setAllImages={setPostImages}
        imageFiles={imageFiles}
        setImageFiles={setImageFiles}
        setDeleteImage={(image) => setDeleteImage([...deleteImage, image])}
        AllVideos={PostVideos}
        setAllVideos={setPostVideos}
        videoSourceURLs={videoSourceURLs}
        setVideoSourceURLs={setVideoSourceURLs}
        videoSourceFiles={videoSourceFiles}
        setVideoSourceFiles={setVideoSourceFiles}
        setDeletedVideoArray={(image) =>
          setDeletedVideoArray([...deletedVideoArray, image])
        }
        containOldImages={true}
        onSubmit={onPostSubmit}
        setmodel={setmodel}
        PostData={data}
        video={isvideo}
        formRef={formRef}
        EditPost={true}
        AllImagesContainOldImages={true}
      />
      <Dmodel
        name="Are you sure you want to Delete Post"
        id={data?._id}
        isMyPost={isMyPost}
        setmodel={setDeletemodel}
        ismodel={isdeletemodel}
        from="strategicPost"
        employeeId={id}
      />
    </>
  );
};

export default Post;
