export const { REACT_APP_API_BASE: API_BASE } = process.env;
export const { REACT_APP_API_IMAGE_BASE: UPLOAD_URL } = process.env;
export const { REACT_APP_APIKEY: API_KEY } = process.env;
export const { REACT_APP_AUTHDOMAIN: AUTH_DOMAIN } = process.env;
export const { REACT_APP_PROJECT_ID: PROJECT_ID } = process.env;
export const { REACT_APP_STORAGEBUCKET: STORAGE_BUCKET } = process.env;
export const { REACT_APP_MESSAGING_SENDING_ID: MESSAGINGID } = process.env;
export const { REACT_APP_APPID: APPID } = process.env;
export const { REACT_APP_VAPIDKEY: VAPIDKEY } = process.env;
export const { REACT_APP_MEASURMENTID: MEASURMENTID } = process.env;
export const { REACT_APP_IMAGE_BASE_URL_LOCAL: IMAGE_BASE_URL_LOCAL } =
  process.env;

export const API_LOGIN = "admin/charity/charity-login";

export const API_LOGOUT = "/v1/common/commonLogOut";

export const API_SUPER_ADMIN_LOGIN = "/admin-login/login";

export const API_REFRESH_TOKEN = "/v1/common/refreshToken";

export const API_META = "/auth/metaData";

export const API_CAUSE = "/admin/cause/getAll";

export const API_CATEGORY = "/admin/charity-category/getAll";

export const API_CHARITY_LISTING = "/admin/charity/getAll";

export const API_CHARITY_LISTING_ACCEPT = "/admin/charity/charityList";

export const API_VERIFY_EMAIL = "admin/charity/sentMailToUser";

export const API_CHARITY_DETAIL = "/admin/charity/get/";

export const API_CHARITY_VERIFICTAION = "/admin/charity/changeVerification";

//images
export const API_IMAGE_UPLOAD = "/local-image/uploadImage";
export const API_IMAGE_DELETE = "/local-image/removeImage";

//videos
export const API_VIDEO_UPLOAD = "/local-image/uploadVideo";
export const API_VIDEO_DELETE = "/local-image/removeVideo";

export const API_CHARITY_REGISTRATION = "/admin/charity/create";

//common
export const API_COUNTRY = "/v1/common/getAllCountry";
export const API_STATE = "/v1/common/getAllState";
export const API_CITY = "/v1/common/getAllCities";

// Admin Cause
export const API_CAUSE_CREATE = "/admin/cause/create";
export const API_CAUSE_GETALL = "/admin/cause/getAll";
export const API_CAUSE_DELETE_ID = "/admin/cause/delete";
export const API_CAUSE_GET_ID = "/admin/cause/get/";
export const API_CAUSE_UPDATE_ID = "/admin/cause/update/";

// Charity Category

export const API_CHARITY_CATEGORY_CREATE = "/admin/charity-category/create";
export const API_CHARITY_CATEGORY_GETALL = "/admin/charity-category/getAll";
export const API_CHARITY_CATEGORY_GET_ID = "/admin/charity-category/get/";
export const API_CHARITY_CATEGORY_DELETE_ID = "/admin/charity-category/delete";
export const API_CHARITY_CATEGORY_UPDATE_ID = "/admin/charity-category/update/";

export const API_HOMEPAGE_UPDATE = "/admin/homepage/updateHomepage";
export const API_HOMEPAGE_DETAIL = "/admin/homepage/getHomepageDetails";

// password changes
export const API_CHANGE_PASSWORD = "/admin/charity/changePassword";
export const API_FORGOT_PASSWORD = "/admin/charity/forgotPassword";
export const API_RESET_PASSWORD = "/admin/charity/resetPassword";

//community
export const API_COMMUNITY_LIST = "/v1/community/getCommunityList/";
export const API_COMMUNITY_CREAT = "/v1/community";
export const API_COMMUNITY_DETAIL = "/v1/community/getCommunityDetail/";
export const API_COMMUNITY_DELETE = "/v1/community/";
export const API_COMMUNITY_EDIT = "/v1/community";
export const API_COMMUNITY_HIDE = "/admin/admin-community/hideCommunity/";

//Edit chairty profile view
export const API_EDIT_CHARITY_VIEW = "/admin/charity/update/";

//Event
export const API_EVENT_LIST = "/admin/event/eventList/";
export const API_EVENT_ADD = "/admin/event/addEvent";
export const API_EVENT_DETAILS = "/admin/event/findEvent/";
export const API_EVENT_UPDATE = "/admin/event/editEvent/";
export const API_EVENT_DELETED = "/admin/event/deleteEvent/";
export const API_EVENT_MEMBER_LIST = "/v1/event/eventDetailsExport/";
export const API_EVENT_MEMEBER_DELETE = "/v1/event/removeMember";
export const API_STRATEGIC_EVENT_LIST = "/admin/event/listStrategicPartner/";

// Post
export const API_POST_ADD = "/v1/posts";
export const API_POST_List = "/v1/posts/getAllCommunityPostList/";
export const API_POST_UPATE = "/v1/posts/";
export const API_POST_DELETE = "/v1/posts/";
export const API_POST_HIDE = "/v1/posts/hidePost";
export const API_POST_LIKE_UNLIKE = "/v1/posts/likeUnlikePost/";
export const API_POST_COMMENT = "/v1/posts/postComment";
export const API_POST_COMMENT_REPLY = "/v1/posts/postCommentRply";
export const API_POST_COMMENT_LIST = "v1/posts/getPostCommnetList/";
export const API_POST_COMMENT_LIKE = "/v1/posts/likeUnlikeComment/";

//Payment
export const API_PAYMENT_DETAIL_ADD = "/admin/payment-method/addPaymentmethod";
export const API_PAYMENT_DETAIL_GET =
  "/admin/payment-method/findPaymentMethodbyCharity/";
export const API_PAYMENT_DETAIL_UPDATE =
  "/admin/payment-method/updatePaymentmethod";

//Transaction
export const API_TRANSCATION_LIST = "/admin/transaction/transactionList";
export const API_TRANSCATION_LIST_EXCEL =
  "/admin/transaction/charityTransactionList-excel/";
export const API_TRANSCATION_DETAIL_PDF =
  "/v1/payment/generateTransactionReciept";
export const API_COMMUNITY_TRANSCTION_LIST =
  "/charityPanel/communityPayment-list";
export const API_COMMUNITY_TRANSCTION_EXCELL_DOWNLOAD =
  "/admin/transaction/communityTransactionList-excel/";

//Content Mangement
export const API_CONTACT_ADD = "/v1/ContactUs/create";
export const API_GET_CMS_LIST = "/admin/cms-pages/cmsPagesList";
export const API_FAQ_CATEGORY_LIST =
  "/admin/admin-faq-category/faqCategoryList";
export const API_FAQ_LIST = "/admin/admin-faq/faqList";

// Dashboard
export const API_CHARITY_DASHBOARD = "/admin/charity/dashboard";
export const API_CHARITY_DASHBOARD_YEARGRAPH = "/admin/charity/yearGraph";
export const API_CHARITY_DASHBOARD_DAYGRAPH = "/admin/charity/dayGraph";
export const API_CHARITY_DASHBOARD_DAILYUPDATE = "/admin/charity/dailyUpdates";
export const API_CHARITY_SIX_MONTH_DASHBOARD_ =
  "/admin/charity/previousSixMonths";

export const API_SUBSCRIBE_DONOR_LIST = "/charityPanel/subscription-list";
export const API_SUBSCRIBE_ONETIMEDONOR_LIST =
  "/charityPanel/oneTimePayment-list";

// Notifiction
export const API_NTIFICTION_DELETE = "/v1/notification/";
export const API_NOTIFICTION_LIST = "/v1/notification/list";
export const API_DISMISS_ALL_NOTIFICTION = "/v1/notification/dismissAll";
//---------------Strategic---------------------

//---------------Strategic Panel---------------------

// Register
export const API_STRATEGIC_CREATE = "/v1/strategic-partner/create";

//Login
export const API_STRATEGIC_PARTNER_LOGIN = "/v1/strategic-partner/login";

//Change and Reset Password
export const API_STRATEGIC_CHANGE_PASSWORD =
  "/v1/strategic-partner/changePassword";
export const API_STRATEGIC_FORGOT_PASSWORD = "/v1/strategic-partner/forgot";
export const API_STRATEGIC_RESET_PASSWORD =
  "/v1/strategic-partner/resetPassword";

//Employee
export const API_STRATEGIC_CREATE_EMPLOYEE =
  "/admin/employee/sentMailToReqEmployee";
export const API_STRATEGIC_EMPLOYEE_VERIFICATION =
  "/admin/employee/EmployeeVerification";
export const API_STRATEGIC_PENDING_EMPLOYEE =
  "/admin/employee/pendingEmployeeList";
export const API_STRATEGIC_APPROVE_EMPLOYEE =
  "/admin/employee/approveEmployeeList";
export const API_STRATEGIC_DELETE_EMPLOYEE = "/admin/employee/deleteEmployee/";
export const API_EMPLOYEE = "/v1/users/userDetails/";
//Strategic Partner Profile
export const API_STRATEGIC_USER_VIEW = "/v1/strategic-partner/get/";
export const API_STRATEGIC_USER_UPDATE = "/v1/strategic-partner/update/";

//Strategic Partner Post List
export const API_STRATEGIC_POST_LIST = "/v1/posts/employeePost/";
export const API_EMPLOYEE_POST_LIST = "/v1/posts/getAllUserPostList/";

//Strategic Dashboard
export const API_STRATEGIC_DASHBOARD = "/admin/stategic-Partner/dashboard";
export const API_STRATEGIC_DAYGRAPH_DASHBOARD =
  "/admin/stategic-Partner/dayGraphDashboard";
export const API_STRATEGIC_YEARGRAPH_DASHBOARD =
  "/admin/stategic-Partner/yearGraphDashboard";
export const API_STRATEGIC_SIX_MONTH_DASHBORD =
  "/admin/stategic-Partner/previousSixMonths";
//Strategic Transction
export const API_STRATEGIC_TRANSCATION_LIST =
  "/admin/transaction/transactionList";
export const API_TRANSCATION_DETAIL = "/admin/transaction/get/";
export const API_STRATEGIC_TRANSCATION_LIST_EXCEL =
  "/admin/transaction/strategicExcelTransactionList-excel/";

//chat
export const API_CLEARE_CHAT_HISTORY = "/v1/chat/clearChatHistory/";
export const API_CHAT_CREATE_ROOM = "/v1/chat/createRoom";
