import React from 'react'

const Darrow = () => {
  return (
    <>
      <svg width="8" height="6" viewBox="0 0 8 6" xmlns="http://www.w3.org/2000/svg">
         <path fillRule="evenodd" clipRule="evenodd" d="M0.464768 3.65062C0.0742439 4.04114 0.0742439 4.67431 0.464768 5.06483C0.855293 5.45536 1.48846 5.45536 1.87898 5.06483L4.00028 2.94354L6.1216 5.06486C6.51212 5.45538 7.14529 5.45538 7.53581 5.06486C7.92633 4.67433 7.92633 4.04117 7.53581 3.65064L4.71349 0.828325C4.71147 0.826273 4.70945 0.824228 4.70741 0.82219C4.32299 0.437768 3.70345 0.431761 3.31168 0.80417C3.30546 0.810082 3.2993 0.816088 3.2932 0.82219C3.29319 0.822199 3.29318 0.822207 3.29317 0.822216C3.2931 0.822285 3.29303 0.822354 3.29296 0.822424L0.464768 3.65062Z" fill="black" fillOpacity="0.85"/>
      </svg>
    </>
  )
}

export default Darrow
