import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Charitydetail, EditCharitydetail } from "../../../actions/charity";
import {
  communityDetail,
  EditCommunityDetail,
} from "../../../actions/community";
import { doUploadImage } from "../../../actions/registration";
import {
  strategicUserUpdate,
  strategicUserView,
} from "../../../actions/strategic";
import { ImgUploadCommon } from "../img-upload-common";

const ImageModal = ({
  title = "",
  isModal,
  setisModal,
  AllImages = [],
  setAllImages,
  imageFiles,
  setImageFiles,
  moduleName,
  multiple,
  submitFunction,
  from,
  callUploadAPI,
}) => {
  const Change = useSelector((state) => state.auth?.user?.data);
  const charitydetail = useSelector(
    (state) => state.ChairtyReducer.chairtyDetail?.charityList
  );
  const communitydetail = useSelector(
    (state) => state.CommunityReducer.communityDetails
  );
  const strategicdetail = useSelector(
    (state) => state.StrategicReducer?.strategicUserView
  );
  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const onSave = (e) => {
    e.preventDefault();
    let imgArr = [];
    let len = imageFiles.length;
    if (len === 0) {
      toast.error("Please attach atleast one image");
      return;
    }
    imageFiles.map((image, ind) => {
      const payload = { image: [image], moduleName: moduleName };
      dispatch(doUploadImage(payload)).then((res) => {
        len = len - 1;
        imgArr.push({
          name: res.data.name,
          url: metaData?.mediaURL + moduleName + "/" + res.data.name,
        });
        setAllImages((prev) => [
          ...prev,
          {
            name: res.data.name,
            url: metaData?.mediaURL + moduleName + "/" + res.data.name,
          },
        ]);
        if (len === 0) {
          createPostFun(imgArr);
        }
      });
      return null;
    });
  };

  const createPostFun = (imgArr) => {
    switch (from) {
      case "charity":
        if (
          charitydetail?.charityDetails?.mediaImage?.length ===
          imgArr?.length + AllImages.length
        ) {
          return toast.error("Please attach Image", { autoClose: 100000 });
        }
        const datacharity = {
          charityId: Change?._id,
          relatedCause: [],
          categoryId: charitydetail?.charityDetails?.categoryId,
          mediaImage: [...imgArr, ...AllImages],
        };
        dispatch(EditCharitydetail(Change?._id, datacharity))
          .then((res) => {
            setisModal(false);
            setImageFiles([]);
            dispatch(Charitydetail(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "strategic":
        if (
          strategicdetail?.mediaImage?.length ===
          imgArr?.length + AllImages.length
        ) {
          return toast.error("Please attach Image");
        }
        const dataStrategic = {
          strategicPartnerId: Change?._id,
          mediaImage: [...imgArr, ...AllImages],
        };
        const allStrategicData = { ...strategicdetail, ...dataStrategic };
        dispatch(strategicUserUpdate(allStrategicData, Change?._id))
          .then((res) => {
            setisModal(false);
            setImageFiles([]);
            dispatch(strategicUserView(Change?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;

      case "commmunity":
        if (communitydetail?.mediaImage?.length === imgArr?.length) {
          return toast.error("Please attach Image");
        }

        const datacommunity = {
          _id: communitydetail?._id,
          charityId: Change?._id,
          mediaImage: [...imgArr, ...AllImages],
        };
        dispatch(EditCommunityDetail(datacommunity))
          .then((res) => {
            setisModal(false);
            setImageFiles([]);
            dispatch(communityDetail(communitydetail?._id));
            toast.success(res.message);
          })
          .catch((res) => toast.error(res.message));
        break;
      default:
        break;
    }
  };

  const onClose = (e) => {
    e.preventDefault();
    setisModal(false);
    setImageFiles([]);
    switch (from) {
      case "charity":
        setAllImages(charitydetail?.charityDetails?.mediaImage);
        break;
      case "commmunity":
        setAllImages(communitydetail?.mediaImage);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {/* upload image and video  */}
      <div className={`model-area upload-model ${isModal ? "open-model" : ""}`}>
        <div className="model-box">
          <div className="modele-text-box">
            <h2>{title}</h2>
            <div className="form-area">
              <form>
                <div className="input-list d-flex flex-wrap">
                  <div className="col-12 col-div">
                    <label className="label-text">
                      {" "}
                      <span>*</span> {title}{" "}
                    </label>
                    <ImgUploadCommon
                      isDisplayOldImage={false}
                      AllImages={AllImages}
                      setAllImages={setAllImages}
                      imageFiles={imageFiles}
                      setImageFiles={setImageFiles}
                      // maxNumber={10}
                      multiple={multiple}
                      moduleName={moduleName}
                      callUploadAPI={callUploadAPI}
                    />
                  </div>
                </div>
                <div className="d-flex flex-wrap form-btn-box">
                  <button onClick={onSave} className="link-btn">
                    Save
                  </button>
                  <Link to="/" onClick={onClose} className="link-btn cancel">
                    Cancel
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ImageModal;
