import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../components/layout/Breadcrumb";
import "react-datepicker/dist/react-datepicker.css";
import { formatPhoneNumber, toAbsoluteUrl } from "../../../../utils";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Dmodel from "../../../../components/common/delete";
import { useDispatch, useSelector } from "react-redux";
import { doLogout } from "../../../../actions/auth";
import "./views.scss";
import ProfilePost from "../../../../components/tabs/StrategicPartner/StrategicProfileTab/profilepost";
import { strategicUserView } from "../../../../actions/strategic";
import ImageVideoStrategic from "../../../../components/tabs/StrategicPartner/StrategicProfileTab/imageVideoStrategic";
import { Link } from "react-router-dom";

const list = [
  {
    name: "Giving Partner Profile",
    link: "/strategic_profile",
    isactive: false,
  },
  {
    name: "Giving Partner Profile View",
    link: " ",
    isactive: true,
  },
];

const ProfileView = () => {
  //ALL The Hooks
  const Change = useSelector((state) => state.auth?.user?.data);
  const strategicDetail = useSelector(
    (state) => state.StrategicReducer.strategicUserView
  );
  const metaData = useSelector((state) => state.metaReducer);

  const dispatch = useDispatch();

  const [ismodel, setmodel] = useState(false);

  useEffect(() => {
    dispatch(strategicUserView(Change?._id));
  }, [Change?._id, dispatch]);

  const onLogout = () => {
    dispatch(doLogout());
    window.location.reload();
  };

  return (
    <>
      <div className="strategic-profile-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Giving Partner Profile View</h2>
        </div>
        <div className="section-inner rating-box">
          <div className="d-flex flex-wrap">
            <div className="col-xl-6">
              <div className="view-leftbox">
                <div>
                  <figure className="help-img">
                    <img
                      src={
                        strategicDetail?.logo
                          ? metaData.mediaURL +
                            "strategicLogo/" +
                            strategicDetail?.logo
                          : toAbsoluteUrl("/images/placeholder.png")
                      }
                      alt="Giving Partner profile"
                    />
                  </figure>

                  <div className="info-box d-flex flex-wrap w-100">
                    <div className="pl-0">
                      <div className="doctor-box">
                        <h4>{strategicDetail?.name} </h4>
                        <ul className="doctor-list">
                          <li>{strategicDetail?.userName}</li>
                          <li> {strategicDetail?.ownerName}</li>
                        </ul>
                        {strategicDetail?.website ? (
                          <ul className="website">
                            <li>
                              <p>
                                <a
                                  href={`${
                                    strategicDetail?.website
                                      ?.toString()
                                      ?.indexOf("http") !== -1
                                      ? strategicDetail?.website
                                      : `https://${strategicDetail?.website}`
                                  }`}
                                  target={"_blank"}
                                  rel="noreferrer"
                                >
                                  {strategicDetail?.website}
                                </a>
                              </p>
                            </li>
                          </ul>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="pr-0">
                      <p>{strategicDetail?.description} </p>
                    </div>
                  </div>
                </div>
                <div className="add-box">
                  {strategicDetail?.tagline ? (
                    <ul>
                      <li className="strategic-tag">
                        {strategicDetail?.tagline}
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}
                  {strategicDetail?.address ? (
                    <p>
                      {strategicDetail?.address?.city},{" "}
                      {strategicDetail?.address?.state},{" "}
                      {/* {strategicDetail?.address?.country},{" "} */}
                      {strategicDetail?.address?.zipcode}
                    </p>
                  ) : (
                    ""
                  )}

                  <ul className="add-list">
                    <li className="mail">
                      {" "}
                      <a
                        href={`mailto: ${strategicDetail?.emailAddress}`}
                        target={"_blank"}
                        rel="noreferrer"
                      >
                        {strategicDetail?.emailAddress}
                      </a>
                    </li>

                    {strategicDetail?.phoneNumber ? (
                      <li className="phone">
                        <a href={`tel: ${strategicDetail?.phoneNumber}`}>
                          {formatPhoneNumber(
                            strategicDetail?.phoneNumber.toString()
                          )}
                        </a>
                      </li>
                    ) : (
                      ""
                    )}

                    {/* <li className="coin">$20</li>
                    <li className="tag">3</li> */}
                  </ul>

                  <div className="profile-list-box">
                    <ul className="strategic-person">
                      <li>
                        {strategicDetail?.memberImageList?.length
                          ? strategicDetail?.memberImageList?.map(
                              (image, i) => {
                                return (
                                  <>
                                    <figure className="profile-img" key={image}>
                                      <img
                                        src={
                                          strategicDetail?.memberImageList
                                            ? metaData.mediaURL +
                                              "userProfilePic/" +
                                              image
                                            : toAbsoluteUrl("/images/image.png")
                                        }
                                        alt="logo-img"
                                      />
                                    </figure>
                                  </>
                                );
                              }
                            )
                          : ""}
                      </li>
                    </ul>
                    {strategicDetail?.memberCount ? (
                      <span>
                        {strategicDetail?.memberCount} Associated Members
                      </span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="view-about-box">
                  {strategicDetail?.brifDescription ? (
                    <>
                      <h4>Brief Description</h4>
                      <p>{strategicDetail?.brifDescription} </p>
                    </>
                  ) : (
                    ""
                  )}
                  <div className="list-member">
                    <div className="profile-box">
                      <Link
                        to="/strategic_change_password"
                        className="link-btn community-btn"
                        onClick={() => setmodel(!ismodel)}
                      >
                        Change Password
                      </Link>
                      <Link
                        to="/strategic_profile/edit"
                        className="link-btn community-btn"
                        onClick={() => setmodel(!ismodel)}
                      >
                        Edit Profile
                      </Link>
                      <button
                        type="button"
                        className="link-btn community-btn"
                        onClick={onLogout}
                      >
                        Logout
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6 pl-0">
              <div className="view-rightbox">
                <Tabs>
                  <TabList>
                    <Tab>Post</Tab>
                    <Tab>Image and Video</Tab>
                  </TabList>
                  <TabPanel>
                    <ProfilePost />
                  </TabPanel>
                  <TabPanel>
                    <ImageVideoStrategic />
                  </TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dmodel
        name="Are you sure you want to hide the community"
        setmodel={setmodel}
        ismodel={ismodel}
        className="d-model"
      />
    </>
  );
};

export default ProfileView;
