import React from 'react'

const Upload = () => {
  return (
    <>
    <svg width="30" height="30" fill="red" viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.8125 2.96905H5.96719V8.25499C5.96719 8.32374 6.02344 8.37999 6.09219 8.37999H7.02969C7.09844 8.37999 7.15469 8.32374 7.15469 8.25499V2.96905H8.3125C8.41719 2.96905 8.475 2.84874 8.41094 2.76749L6.66094 0.551867C6.64925 0.536927 6.63431 0.524844 6.61725 0.516534C6.60019 0.508225 6.58147 0.503906 6.5625 0.503906C6.54353 0.503906 6.52481 0.508225 6.50775 0.516534C6.4907 0.524844 6.47576 0.536927 6.46406 0.551867L4.71406 2.76593C4.65 2.84874 4.70781 2.96905 4.8125 2.96905ZM12.2813 7.78624H11.3438C11.275 7.78624 11.2188 7.84249 11.2188 7.91124V10.3175H1.90625V7.91124C1.90625 7.84249 1.85 7.78624 1.78125 7.78624H0.84375C0.775 7.78624 0.71875 7.84249 0.71875 7.91124V11.005C0.71875 11.2816 0.942188 11.505 1.21875 11.505H11.9063C12.1828 11.505 12.4063 11.2816 12.4063 11.005V7.91124C12.4063 7.84249 12.35 7.78624 12.2813 7.78624Z" fill="#42A44CDB"/>
    </svg>

    </>
  )
}

export default Upload
