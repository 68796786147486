import {
  API_CHARITY_DETAIL,
  API_CHARITY_LISTING,
  API_CHARITY_LISTING_ACCEPT,
  API_CHARITY_VERIFICTAION,
  API_EDIT_CHARITY_VIEW,
  API_VERIFY_EMAIL,
} from "../constants/api";
import {
  API,
  CHARITY_DETAIL_F,
  CHARITY_DETAIL_S,
  CHARITY_LISTING_ACCEPT_FAIL,
  CHARITY_LISTING_ACCEPT_SUCCESS,
  CHARITY_LISTING_F,
  CHARITY_LISTING_S,
  CHARITY_VERIFICTAION_F,
  CHARITY_VERIFICTAION_S,
  EDIT_CHARITY_VIEW_F,
  EDIT_CHARITY_VIEW_S,
} from "../constants/types";

export const Charitydetail = (id) => ({
  type: API,
  payload: {
    url: API_CHARITY_DETAIL + `${id}`,
    method: "GET",
    success: (data) => ({
      type: CHARITY_DETAIL_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_DETAIL_F,
      payload: data,
    }),
  },
});

export const EditCharitydetail = (id, data) => ({
  type: API,
  payload: {
    url: API_EDIT_CHARITY_VIEW + `${id}`,
    method: "PATCH",
    data,
    success: (data) => ({
      type: EDIT_CHARITY_VIEW_S,
      payload: data,
    }),
    error: (data) => ({
      type: EDIT_CHARITY_VIEW_F,
      payload: data,
    }),
  },
});
export const Charitylisting = (data) => ({
  type: API,
  payload: {
    url: API_CHARITY_LISTING,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CHARITY_LISTING_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_LISTING_F,
      payload: data,
    }),
  },
});

export const Charityverifictaion = (data) => ({
  type: API,
  payload: {
    url: API_CHARITY_VERIFICTAION,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CHARITY_VERIFICTAION_S,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_VERIFICTAION_F,
      payload: data,
    }),
  },
});

export const CharityListingAccept = (data) => ({
  type: API,
  payload: {
    url: API_CHARITY_LISTING_ACCEPT,
    method: "POST",
    data: data,
    success: (data) => ({
      type: CHARITY_LISTING_ACCEPT_SUCCESS,
      payload: data,
    }),
    error: (data) => ({
      type: CHARITY_LISTING_ACCEPT_FAIL,
      payload: data,
    }),
  },
});

export const doVerifyEmail = (id, showLoader = false) => ({
  type: API,
  payload: {
    url: `${API_VERIFY_EMAIL}/${id}`,
    method: "POST",
    showLoader,
  },
});
