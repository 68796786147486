import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/layout/Breadcrumb";
import DataTable from "react-data-table-component";
import { debounce, toAbsoluteUrl } from "../../utils";
import "./event.scss";
import { Link } from "react-router-dom";
import Delete from "../../components/svg/delete";
import Edit from "../../components/svg/edit";
import View from "../../components/svg/view";
import Plus from "../../components/svg/plus";
import { useDispatch, useSelector } from "react-redux";
import { eventList, StrategiceventList } from "../../actions/event";
import moment from "moment";
import DatePicker from "react-datepicker";
import Dmodel from "../../components/common/delete";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";

const list = [
  {
    name: "Event Management",
    link: "/event",
    isactive: false,
  },
  {
    name: "Event List",
    link: "/event",
    isactive: true,
  },
];

const Event = () => {
  const Change = useSelector((state) => state.auth);
  const eventlist = useSelector(
    (state) => state.EventReducer.eventList.eventFilter
  );

  const dispatch = useDispatch();

  const [ismodel, setmodel] = useState(false);
  const [totalRecords, settotalRecords] = useState(0);
  const [eventDeleteId, setEventDeleteId] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [arg, setArg] = useState({
    page: 1,
    limit: 10,
    search: "",
    column: "",
    order: "",
    from: startDate || null,
    to: endDate || null,
    status: true,
  });
  useEffect(() => {
    if (Change?.isStrategic) {
      dispatch(StrategiceventList(Change?.user?.data?._id, arg)).then((res) => {
        settotalRecords(res?.data?.total_records || 0);
      });
    } else {
      dispatch(eventList(Change?.user?.data?._id, arg)).then((res) => {
        settotalRecords(res?.data?.total_records || 0);
      });
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arg]);

  const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const handleDateChange = async (date) => {
    if (date) {
      setEndDate(
        new Date(moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss"))
      );
      setArg({
        ...arg,
        to: moment(date).endOf("day").format("MM/DD/YYYY HH:mm:ss"),
      });
    } else {
      setEndDate(null);
      setArg({ ...arg, to: null });
    }
  };
  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  const handlerDelete = (id) => {
    setEventDeleteId(id);
    setmodel(true);
  };
  const onSortChange = (column, direction) => {
    setArg({ ...arg, column: column?.sortName, order: direction });
  };
  const customStyles = {
    headCells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Event Name",
      sortName: "eventName",
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: "Community Name",
      sortName: "communityName",
      selector: (row) => row.communityName,
    },
    {
      name: "Date and Time of Event",
      selector: (row) => (
        <>
          {" "}
          {moment(row.startDate).format("D MMM YYYY")},
          {moment(row.eventTime).format("hh:mm a")}
        </>
      ),
    },
    {
      name: "Event Location",
      selector: (row) => (
        <>
          {" "}
          {row.eventType === "offline"
            ? `${row.address?.city},${' '}${row.address?.state}`
            : "Virtual"}
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        moment(row.startDate).isBefore(moment()) === true ? (
          <div className="status-box">
            {" "}
            <span className="Past">Past</span>
          </div>
        ) : (
          <div className="status-box">
            {" "}
            <span className="up">Upcoming</span>
          </div>
        ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-box">
          {arg?.status ? (
            <Link
              to={`/event/event_view/${row._id}`}
              className="btn right"
              title="view"
            >
              {" "}
              <View />{" "}
            </Link>
          ) : (
            ""
          )}
          {arg?.status ? (
            <span
              className="btn wrong"
              title="delete"
              onClick={() => handlerDelete(row._id)}
            >
              {" "}
              <Delete />{" "}
            </span>
          ) : (
            <span title="restore" onClick={() => handlerDelete(row._id)}>
              <img src={toAbsoluteUrl("/images/restore.svg")} alt="world-img" />
            </span>
          )}
          {arg?.status ? (
            <Link
              to={`/event/event_edit/${row._id}`}
              className="btn right"
              title="edit"
            >
              {" "}
              <Edit />{" "}
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];
  const startegiccolumns = [
    {
      name: "Event Name",
      sortName: "eventName",
      selector: (row) => row.eventName,
      sortable: true,
    },
    {
      name: "Date and Time of Event",
      selector: (row) => (
        <>
          {" "}
          {moment(row.startDate).format("D MMM YYYY")},
          {moment(row.eventTime).format("hh:mm a")}
        </>
      ),
    },
    {
      name: "Event Location",
      selector: (row) => (
        <>
          {" "}
          {row.eventType === "offline"
            ? `${row.address?.city},${' '}${row.address?.state}`
            : "Virtual"}
        </>
      ),
    },
    {
      name: "Status",
      selector: (row) =>
        moment(row.startDate).isBefore(moment()) === true ? (
          <div className="status-box">
            {" "}
            <span className="Past">Past</span>
          </div>
        ) : (
          <div className="status-box">
            {" "}
            <span className="up">Upcoming</span>
          </div>
        ),
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "180px",
      cell: (row) => (
        <div className="action-box">
          {arg?.status ? (
            <Link
              to={`/event/event_view/${row._id}`}
              className="btn right"
              title="view"
            >
              {" "}
              <View />{" "}
            </Link>
          ) : (
            ""
          )}
          {arg?.status ? (
            <span
              className="btn wrong"
              title="delete"
              onClick={() => handlerDelete(row._id)}
            >
              {" "}
              <Delete />{" "}
            </span>
          ) : (
            <span title="restore" onClick={() => handlerDelete(row._id)}>
              <img src={toAbsoluteUrl("/images/restore.svg")} alt="world-img" />
            </span>
          )}
          {arg?.status ? (
            <Link
              to={`/event/event_edit/${row._id}`}
              className="btn right"
              title="edit"
            >
              {" "}
              <Edit />{" "}
            </Link>
          ) : (
            ""
          )}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <>
      <div className="event-section">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Event Management </h2>
        </div>
        <div className="section-inner cause-section">
          <div className="d-flex flex-wrap align-items-end justify-content-between">
            <div className="search-box">
              <input
                type="text"
                placeholder="Search By Event Name"
                className="search-input"
                onChange={(e) => optimizedFn(e.target.value)}
              />
            </div>

            <div className="div-btn-box">
              <div className="d-flex flex-wrap align-items-center">
                <div>
                  <label className="date-lable">From:</label>
                  <DatePicker
                    placeholderText="Start Date"
                    selected={startDate}
                    
                    onChange={(date) => {
                      if (date) {
                        setStartDate(
                          new Date(
                            moment(date)
                              .startOf("day")
                              .format("MM/DD/YYYY HH:mm:ss")
                          )
                        );
                        setArg({
                          ...arg,
                          from: moment(date)
                            .startOf("day")
                            .format("MM/DD/YYYY HH:mm:ss"),
                        });
                      } else {
                        setStartDate(null);
                        setArg({ ...arg, from: null });
                      }
                    }}
                    selectsStartGoing
                    className="search-input"
                    maxDate={endDate}
                  />
                </div>
                <span style={{ margin: "0 5px", paddingTop: "15px" }}> </span>

                <div>
                  <label className="date-lable">To:</label>
                  <DatePicker
                    placeholderText="End Date"
                    selected={endDate}
                    onChange={handleDateChange}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    className="search-input eventMargin"
                  />
                </div>
              </div>
              {arg?.status ? (
                <Link to="/event/event_add" className="btn" title="add">
                  {" "}
                  <Plus /> Add New Event{" "}
                </Link>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="image-box">
            <Tabs>
              <TabList>
                <Tab onClick={() => setArg({ ...arg, status: true })}>
                  Event List
                </Tab>
                <Tab onClick={() => setArg({ ...arg, status: false })}>
                  Archive Event List
                </Tab>
              </TabList>
              <TabPanel></TabPanel>
              <TabPanel></TabPanel>
            </Tabs>
          </div>
          <DataTable
            border
            columns={!Change?.isStrategic ?columns : startegiccolumns}
            data={eventlist}
            onSort={onSortChange}
            sortServer
            pagination
            paginationServer
            paginationTotalRows={totalRecords}
            onChangeRowsPerPage={handlePerRowsChange}
            customStyles={customStyles}
            onChangePage={handlePageChange}
            dense
          />
        </div>
      </div>

      <Dmodel
        name={`Are you sure you want to ${arg?.status ? "delete" : "Restore"}`}
        id={eventDeleteId}
        setmodel={setmodel}
        ismodel={ismodel}
        arg={arg}
        from="event"
      />
    </>
  );
};

export default Event;
