/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Field, Form } from "react-final-form";
import { Link, useParams } from "react-router-dom";
import { renderField } from "../../../../components/forms";
import Breadcrumb from "../../../../components/layout/Breadcrumb";
import "./view.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { transactionDetail } from "../../../../actions/transaction";
import { formatPhoneNumber } from "../../../../utils";

const list = [
  {
    name: " Transaction Mangement ",
    link: "/s_transaction",
    isactive: false,
  },
  {
    name: "Transaction View",
    link: "s_transaction/s_transaction_view",
    isactive: true,
  },
];

const TransactionView = () => {
  const transctiondetail = useSelector(
    (state) => state.TransactionReducer.transctiondetail?.[0]
  );

  const dispatch = useDispatch();
  const { id } = useParams();
  const [cause, setCause] = useState([]);
  const [charity, setCharity] = useState([]);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(transactionDetail(id)).then((res) => {
      if (
        res.data[0]?.charityArray?.length >= 0 &&
        res.data[0]?.charityArray?.[0]?.charityName
      ) {
        setCharity(res.data[0].charityArray);
      }
      if (
        res.data[0]?.communityArray?.length >= 0 &&
        res.data[0]?.communityArray?.[0]?.communityName
      ) {
        setCause(res.data[0].communityArray);
      }
      if (cause?.length || charity?.length) {
        setData([...charity, ...cause]);
      }
    });
  }, []);
  useEffect(() => {
    setData([...charity, ...cause]);
  }, [charity, cause]);

  const onSubmit = (value) => {};

  const handleusertype = () => {
    if (transctiondetail?.userType) {
      switch (transctiondetail?.userType) {
        case 1:
          return "Registered User";
        case 2:
          return "Associate User";
        case 3:
          return "Guest User";
        default:
          break;
      }
    }
  };
  const PaymentMethod = () => {
    if (transctiondetail?.paymentMethod) {
      switch (transctiondetail?.paymentMethod) {
        case "card":
          return "Card";
        case "subscription":
          return "Subscription";

        default:
          break;
      }
    }
  };
  return (
    <>
      <div className="transaction-view-box">
        <div className="top-box">
          <Breadcrumb list={list} />
          <h2>Transaction View </h2>
        </div>
        <div className="section-inner padding-area">
          <div className="form-area">
            <Form
              onSubmit={onSubmit}
              initialValues={{
                username: transctiondetail?.userName,
                transactionid: transctiondetail?.transactionId,
                paymentstatus: transctiondetail?.transactionStatus,
                phonenumber: transctiondetail?.phoneNumber
                  ? formatPhoneNumber(transctiondetail?.phoneNumber)
                  : "",
                emailid: transctiondetail?.email,
                date: moment(transctiondetail?.DateOfTransaction).format(
                  " D MMM YYYY"
                ),
                amount: "$ " + transctiondetail?.amount,
                payment: PaymentMethod(),
                type: handleusertype(),
              }}
            >
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                  <div className="input-list d-flex flex-wrap">
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> User Name
                      </label>
                      <Field
                        name="username"
                        type="text"
                        placeholder="User Name"
                        component={renderField}
                        // validate={(value) => required(value, ('User Name'))}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Transaction Id
                      </label>
                      <Field
                        name="transactionid"
                        type="text"
                        placeholder="Transaction Id"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Payment Status
                      </label>
                      <Field
                        name="paymentstatus"
                        type="text"
                        placeholder="Payment Status"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Phone Number
                      </label>
                      <Field
                        name="phonenumber"
                        type="text"
                        placeholder="Phone Number"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Email Id
                      </label>
                      <Field
                        name="emailid"
                        type="text"
                        placeholder="Email Id"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Date of transaction
                      </label>
                      <Field
                        name="date"
                        type="text"
                        placeholder="Date of transaction"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Amount
                      </label>
                      <Field
                        name="amount"
                        type="text"
                        placeholder="Amount"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> Payment Method
                      </label>
                      <Field
                        name="payment"
                        type="text"
                        placeholder="Payment Method"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-md-6 col-div">
                      <label className="label-text">
                        {" "}
                        <span>*</span> User Type
                      </label>
                      <Field
                        name="type"
                        type="text"
                        placeholder="User Type"
                        component={renderField}
                        className="form-grp"
                        inputclass="input-box"
                        readOnly={true}
                      />
                    </div>
                    <div className="col-12 p-0 col-div">
                      <h4 style={{ fontWeight: "bold" }}>Payment Details</h4>
                    </div>
                    <div className="col-12 p-0 col-div tableWrap">
                      <table className="paymentData">
                        <thead>
                          <tr>
                            <th>Charity/Community Name</th>
                            <th>Type</th>
                            <th>Amount</th>
                            <th>Percentage Donated</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((row, i) => (
                            <tr key={row?._id}>
                              <td>
                                {row.charityName
                                  ? row.charityName
                                  : row.communityName}
                              </td>
                              <td>{row.type}</td>
                              <td>
                                <div className="status-box">
                                  {row.percentageWiseAmount ? (
                                    <span className="amount">
                                      ${" "}
                                      {row.percentageWiseAmount
                                        .toString()
                                        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </td>
                              <td>{row.paymentPercent + "%"}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="d-flex flex-wrap form-btn-box position-box">
                    {/* <button type="submit" className="link-btn">
                                            Save
                                        </button> */}
                    <Link to="/s_transaction" className="link-btn cancel">
                      Cancel
                    </Link>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </div>

      {/* <Dmodel name="Are you sure you want to hide the community" setmodel={setmodel} ismodel={ismodel} className="d-model" /> */}
    </>
  );
};

export default TransactionView;
