import React from "react";
import { Field, Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  chairtyResetPassword,
  strategicResetPassword,
} from "../../actions/auth";
import { renderField } from "../../components/forms";
import { toAbsoluteUrl } from "../../utils";
import "./ResetPassword.scss";
import { resetPasswordValidation as validate } from "../../components/validations/signIn";
import { toast } from "react-toastify";

const ChangePassword = (props) => {
  const { token } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = (value) => {
    const data = {
      token: token,
      newPassword: value.Newpassword,
    };
    if (props.isStrategic) {
      dispatch(strategicResetPassword(data))
        .then((res) => {
          navigate("/strategic_signin");
          toast.success(res.message);
        })
        .catch((res) => toast.error(res.message));
    } else {
      dispatch(chairtyResetPassword(data))
        .then((res) => {
          navigate("/signin");
          toast.success(res.message);
        })
        .catch((res) => toast.error(res.message));
    }
  };

  return (
    <>
      <section className="section-div pwd-section">
        <div className="d-flex align-items-center min-h100">
          <div className="col-6 none-mobile">
            <div>
              <figure className="text-center figure-img">
                {props.isStrategic ? (
                  <img
                    // src={toAbsoluteUrl("/images/login-left.svg")}
                    src={toAbsoluteUrl("/images/Nonprofit SignIn.png")}
                    alt="left-img"
                  />
                ) : (
                  <img
                    // src={toAbsoluteUrl("/images/login-left.svg")}
                    src={toAbsoluteUrl("/images/giving partner signIn.png")}
                    alt="left-img"
                  />
                )}
              </figure>
            </div>
          </div>
          <div className="col-md-6 col-12">
            <Form onSubmit={onSubmit} validate={validate}>
              {({ handleSubmit }) => (
                <form onSubmit={handleSubmit} className="form-box">
                  <figure className="mainLogo">
                    <img src={toAbsoluteUrl("/images/logo.svg")} alt="logo" />
                  </figure>
                  <span className="signHeader">Rosi Giving</span>
                  <ul className="input-list">
                    <li>
                      <Field
                        name="Newpassword"
                        type="password"
                        placeholder="New password"
                        component={renderField}
                        className="form-grp password"
                        inputclass="input-box"
                      />
                    </li>
                    <li>
                      <Field
                        name="Confirmpassword"
                        type="password"
                        placeholder="Confirm password"
                        component={renderField}
                        className="form-grp password"
                        inputclass="input-box"
                      />
                    </li>
                  </ul>
                  <div>
                    <button type="submit" className="link-btn">
                      Change Password
                    </button>
                  </div>
                </form>
              )}
            </Form>
          </div>
        </div>
      </section>
    </>
  );
};

export default ChangePassword;
