import React from "react";
import { RenderImage } from ".";
import { dateFormatChat, getImageUrl, toAbsoluteUrl } from "../../utils";
import Linkify from "react-linkify";

export const ChatSender = ({
  mediaURL,
  userDetails,
  sendBy,
  message,
  createdDate,
}) => {
  const handlemodualname = () => {
    if (sendBy?.[0]?.type === "charity") {
      return "charityLogo";
    } else if (sendBy?.[0]?.type === "strategic") {
      return "strategicLogo";
    }
  };
  return (
    <div className="chat-msg sender">
      <RenderImage
        className="border-50"
        filePath={
          sendBy?.[0]?.profileImage
            ? getImageUrl(
                mediaURL,
                handlemodualname(),
                sendBy?.[0]?.profileImage
              )
            : toAbsoluteUrl("/images/placeholder.png")
        }
      />
      <div className="chat-content">
      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}>
          <span>{message}</span>
        </Linkify>
        <small>{dateFormatChat(createdDate)}</small>
      </div>
    </div>
  );
};

export const ChatReciver = ({
  mediaURL,
  userDetails,
  sendBy,
  message,
  createdDate,
}) => {
  return (
    <div className="chat-msg receiver">
      <RenderImage
        className="border-50"
        filePath={
          sendBy?.[0]?.profileImage
            ? getImageUrl(mediaURL, "userProfilePic", sendBy?.[0]?.profileImage)
            : toAbsoluteUrl("/images/userLogo.png")
        }
      />
      <div className="chat-content">
      <Linkify componentDecorator={(decoratedHref, decoratedText, key) => (
          <a target="blank" href={decoratedHref} key={key}>
            {decoratedText}
          </a>
        )}>
          <span>{message}</span>
        </Linkify>
        <small>{dateFormatChat(createdDate)}</small>
      </div>
    </div>
  );
};

const ChatBody = (props) => {
  const { sendId, userId } = props;
  if (userId === sendId) return <ChatSender {...props} />;
  else return <ChatReciver {...props} />;
};

export default ChatBody;
