import {
  CHARITY_DETAIL_F,
  CHARITY_DETAIL_S,
  CHARITY_LISTING_ACCEPT_FAIL,
  CHARITY_LISTING_ACCEPT_SUCCESS,
  CHARITY_LISTING_F,
  CHARITY_LISTING_S,
} from "../constants/types";

const initialState = {
  charityList: {},
  chairtyDetail: {},
  cause: {},
  charityListAccept: {},
};
const ChairtyReducer = (state = initialState, action) => {
  switch (action.type) {
    case CHARITY_LISTING_S:
      return {
        ...state,
        charityList: action.payload.data,
      };
    case CHARITY_LISTING_F:
      return {
        ...state,
        charityList: action.payload.message,
      };
    case CHARITY_DETAIL_S:
      return {
        ...state,
        chairtyDetail: action.payload.data,
      };
    case CHARITY_DETAIL_F:
      return {
        ...state,
        chairtyDetail: action.payload.message,
      };
    case CHARITY_LISTING_ACCEPT_SUCCESS:
      return {
        ...state,
        charityListAccept: action.payload.data,
      };
    case CHARITY_LISTING_ACCEPT_FAIL:
      return {
        ...state,
        charityListAccept: action.payload.message,
      };

    default:
      return state;
  }
};

export default ChairtyReducer;
