import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { eventDetail } from "../../../../actions/event";
import Dmodel from "../../../../components/common/delete";
import Delete from "../../../../components/svg/delete";
import { debounce } from "../../../../utils";
import "../view.scss";

const Interested = () => {
  const EventDetail = useSelector(
    (state) => state.EventReducer.interested?.[0]?.member
  );
  const dispatch = useDispatch();
  const { id } = useParams();
  const [ismodel, setmodel] = useState(false);

  const [memeberid, setMemeberId] = useState();
  const [arg, setArg] = useState({
    _id: id,
    status: "Interested",
    search: "",
    page: 1,
    limit: 10,
  });
  const [totalRecords, settotalRecords] = useState(0);
  useEffect(() => {
    setTimeout(() => {
      dispatch(eventDetail(id, arg, 1))
      .then((res) => {
        settotalRecords(res?.data?.[0].members_total_records || 0);
      });
    }, 300);
  },[arg, dispatch, id])
    const handlePerRowsChange = async (newPerPage, page) => {
    setArg({ ...arg, limit: newPerPage });
  };

  const handlePageChange = (page) => {
    setArg({ ...arg, page: page });
  };

  const handlerDelete = (id) => {
    setMemeberId(id);
    setmodel(true);
  };

  const customStyles = {
    headCells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
    cells: {
      style: {
        paddingTop: "8px",
        paddingBottom: "8px",
      },
    },
  };

  const columns = [
    {
      name: "Event SrNo",
      selector: (row, index) => index + 1,
      minWidth: "100%",
      width: "130px",
    },
    {
      name: "Donor Name",
      sortName: "firstName",
      selector: (row) => row.firstName,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      minWidth: "190px",
      cell: (row) => (
        <div className="action-box">
          <button
            className="btn wrong"
            title="delete"
            onClick={() => handlerDelete(row.memeberId)}
          >
            {" "}
            <Delete />{" "}
          </button>
          {/* <Link to="#" className="btn right" title="message">
            {" "}
            <Chats />{" "}
          </Link> */}
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
    },
  ];

  const onChangeFun = (e) => {
    setArg({ ...arg, search: e });
  };
  const optimizedFn = debounce(onChangeFun);

  return (
    <>
      <div className="community-box">
        <div className="search-box">
          <input
            type="text"
            placeholder="Search"
            className="search-input"
            onChange={(e) => optimizedFn(e.target.value)}
          />
        </div>
        <DataTable
          border
          columns={columns}
          data={EventDetail ? EventDetail : []}
          pagination
          paginationServer
          sortServer
          paginationTotalRows={totalRecords}
          onChangeRowsPerPage={handlePerRowsChange}
          customStyles={customStyles}
          onChangePage={handlePageChange}
          dense
        />
      </div>
      <Dmodel
        name="Are you sure you want to Delete"
        id={memeberid}
        setmodel={setmodel}
        ismodel={ismodel}
        arg={arg}
        from="memeber"
      />
    </>
  );
};

export default Interested;
