import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { RenderImage } from ".";
import { clearechatHistoryAction } from "../../actions/chat";
import Darrow from "../../components/svg/dArrow";
import { getImageUrl, OutSideClick, toAbsoluteUrl } from "../../utils";
import { getChatHistory, getChatUserList } from "../../utils/socket";

const ChatHeader = ({ activeUser, mediaURL, onClickBack }) => {
  let userId = useSelector((state) => state.auth?.user?.data?._id);

  const chatReducer = useSelector((state) => state.chat);
  const dispatch = useDispatch();
  const modelRef = useRef();
  const [opensubmenu, setopensubmenu] = useState(false);
  const hadleclearechat = () => {
    dispatch(clearechatHistoryAction(activeUser?.roomId)).then((res) => {
      toast.success(res.message);
      getChatUserList({
        page: 1,
        limit: chatReducer.userListlimit,
        userId: userId,
      });
      getChatHistory({
        userId: userId,
        roomId: activeUser?.roomId,
        page: 1,
        limit: 20,
      });
    });
  };
  OutSideClick(modelRef, () => {
    setopensubmenu(false);
  });
  return (
    <div className="active-chat-user">
      <span className="chat-back" onClick={onClickBack}>
        <Darrow />
      </span>
      <RenderImage
        className="border-50"
        filePath={
          activeUser?.userDetails?.profileImage
            ? getImageUrl(
                mediaURL,
                "userProfilePic",
                activeUser?.userDetails?.profileImage
              )
            : toAbsoluteUrl("/images/userLogo.png")
        }
      />
      <div className="chat-nm-desc">
        <strong>{activeUser?.userDetails?.name || "-"}</strong>
      </div>
      <div className="menu-box" ref={modelRef}>
        <ul className="dots-li" onClick={() => setopensubmenu((pre) => !pre)}>
          <li></li>
          <li></li>
          <li></li>
        </ul>
        {opensubmenu && (
          <ul className={`submenu-list ${opensubmenu ? " open-menu " : ""}`}>
            <li>
              <div className="chat-li" onClick={hadleclearechat}>
                Clear Chat
              </div>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default ChatHeader;
